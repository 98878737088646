var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-condition",
    {
      attrs: {
        condition: _vm.condition,
        "current-filter": _vm.currentFilter,
        "show-search": false,
      },
    },
    [
      _c(
        "b-form-checkbox-group",
        {
          staticClass: "product-filter__options-wrapper",
          attrs: { name: _vm.condition.fieldName },
          on: { change: _vm.onChange },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        _vm._l(_vm.condition.values, function (item) {
          return item.value
            ? _c(
                "div",
                {
                  key: _vm.condition.fieldName + item.value,
                  staticClass: "product-filter__option-item",
                },
                [
                  _c(
                    "div",
                    { staticClass: "option-item__content" },
                    [
                      _c(
                        "b-form-checkbox",
                        { attrs: { value: parseInt(item.value) } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                `${item.value} ${_vm.getLabel(item.value)}`
                              ) +
                              "\n                    "
                          ),
                          _c(
                            "div",
                            { staticClass: "rating-stars" },
                            [
                              _c("brku-star-rating", {
                                attrs: {
                                  "active-color": "#e7cb5e",
                                  "border-color": "#e7cb5e",
                                  "inactive-color": "#ffffff",
                                  "border-width": 2,
                                  increment: 0.5,
                                  inline: true,
                                  rating: parseFloat(item.value),
                                  "read-only": true,
                                  "rounded-corners": true,
                                  "show-rating": false,
                                  "star-size": 15,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "option-item__count" }, [
                    _vm._v("(" + _vm._s(item.count ? item.count : 0) + ")"),
                  ]),
                ]
              )
            : _vm._e()
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }