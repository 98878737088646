<template>
  <div>
    <div class="wishlist-modal">
      <div class="wishlist-modal__inner position-relative">
        <div @click="close" class="close-wrapper position-absolute">
          <i class="fa-solid fa-xmark close-icon"></i>
        </div>
        <div class="wishlist-title d-flex justify-content-md-center justify-content-start">{{ $t('site.wishlist.headline') }}</div>
        <wishlist-modal-entry
            v-for="product in products"
            :product="product.product"
            :action-remove="actionRemove"
            :key="product.id"
            @set-wishlist="setWishlist"
        ></wishlist-modal-entry>
        <b-alert v-if="products.length == 0" show variant="primary">{{ $t('site.wishlist.empty') }}</b-alert>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import WishlistModalEntry from "./WishlistModalEntry";
export default {
    name: 'WishlistModal',
    components: {WishlistModalEntry},
    props: {
        products: {
            type: Array,
            default: []
        },
        actionRemove: {
            type: String,
            default: null
        },
    },
    methods: {
        setWishlist(payload) {
            this.$emit('set-wishlist', payload)
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>