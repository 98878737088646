import Vue from 'vue';

import AddToCart from './AddToCart';
import AddToCartModal from "./AddToCartModal";
import Cart from "./Cart";
import CartPreview from "./CartPreview";

Vue.component('brku-add-to-cart', AddToCart);
Vue.component('brku-add-to-cart-modal', AddToCartModal);
Vue.component('brku-cart', Cart);
Vue.component('brku-cart-preview', CartPreview);