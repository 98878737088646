export const preDecimal = (value) => {
    return value.split('.')[0];
};

export const afterDecimal = (value) => {
    return value.split('.')[1];
};

export const formatPrice = (value) => {
    return value.toFixed(2);
};