<template>
    <div class="local-pick-up">
        <div class="form-group">
            <div class="process-step__sub-title">{{ $t('site.ui.local_pick_up.branch') }}</div>
            <div class="row">
                <div class="col-md-6 order-last order-md-first mt-4 mt-md-0">
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio v-model="branch" name="coreshop[branch]"
                                      v-for="item in branches" :value="item.id">
                            {{ item.name }}
                        </b-form-radio>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <brku-map :branches="[selectedBranchObject]"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="process-step__sub-title">{{ $t('site.ui.local_pick_up.date') }}</div>
            <div class="datepicker d-flex">
                <button type="button" class="datepicker__arrow is--left d-none d-md-block" @click="prevDay">
                    <i class="fa-solid fa-angle-left"></i>
                </button>
                <b-form-datepicker
                    v-model="date"
                    :min="minDate"
                    :date-format-options="{ weekday: 'short', day: '2-digit', month: 'long', year: 'numeric' }"
                ></b-form-datepicker>
                <button type="button" class="datepicker__arrow is--right d-none d-md-block" @click="nextDay">
                    <i class="fa-solid fa-angle-right"></i>
                </button>
            </div>
        </div>
        <div class=" form-group">
            <div class="process-step__sub-title">{{ $t('site.ui.local_pick_up.time') }}</div>
            <v-time-picker
                v-model="time"
                format="24hr"
                :allowed-minutes="allowedStep"
                :allowed-hours="allowedHours"
            ></v-time-picker>
        </div>
        <div class="d-flex justify-content-center my-2 my-md-4">
            <b-button type="submit" variant="primary" class="btn-custom" :disabled="!valid">
                {{ $t('site.ui.checkout.continue') }}
            </b-button>
        </div>
        <input v-if="date" name="coreshop[pickUpDate]" :value="formattedDate" type="hidden"/>
        <input v-if="time" name="coreshop[pickUpTime]" :value="time" type="hidden"/>
        <input name="coreshop[branch]" :value="branch" type="hidden"/>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: {
        selectedDate: {
            type: String,
            required: true
        },
        selectedBranch: {
            type: String,
            required: true
        },
        selectedTime: {
            type: String,
            required: true
        },
        branches: {
            type: Array,
            required: true
        },
        earliestPickUpDate: {
            type: String,
            required: true
        }
    },
    computed: {
        formattedDate() {
            if (this.date) {
                return moment(this.date).format('YYYY-MM-DD')
            }

            return null;
        },
        valid() {
            return (
                this.time &&
                this.date &&
                this.branch
            )
        },
        currentDate() {
            return moment()
        },
        businessHours() {
            if (!this.branch) {
                return null;
            }

            if (!this.date) {
                return null;
            }

            const branch = this.branches.find(item => item.id == this.branch);
            const date = moment(this.date);
            const dayNameTwoDigit = date.locale('en').format('dd').toUpperCase();

            if (!branch) {
                return null;
            }

            return branch.businessHours.find(entry => entry.weekDay === dayNameTwoDigit);
        },
        selectedBranchObject() {
            return this.branches.find(item => item.id == this.branch);
        }
    },
    data: () => ({
        time: null,
        date: null,
        branch: null,
        minDate: null
    }),
    created() {
        this.minDate = moment(this.earliestPickUpDate).toDate();
        this.branch = this.selectedBranch ? this.selectedBranch : this.branches[0].id;
        this.date = moment(this.selectedDate).toDate();
        this.time = this.selectedTime;
    },
    methods: {
        nextDay() {
            this.date = moment(this.date).add(1, 'day').toDate();
        },
        prevDay() {
            const minDate = moment(this.earliestPickUpDate);
            const currentDate = moment(this.date.toISOString());
            const newDate = currentDate.clone().subtract(1, 'day');

            const diff = newDate.diff(minDate, 'day');

            if (diff < 0) {
                return;
            }

            this.date = newDate.toDate();
        },
        getHour(time) {
            return parseInt(time.split(':')[0], 10);
        },
        allowedStep: m => m % 10 === 0,
        allowedHours(h) {
            let allowed = true;

            if (!this.businessHours) {
                return false;
            }

            if (this.businessHours.morningTimeStart && this.businessHours.morningTimeEnd) {
                let start = this.getHour(this.businessHours.morningTimeStart)
                let end = this.getHour(this.businessHours.morningTimeEnd) - 1


                if (h < start || h > end) {
                    return false;
                }
            }

            if (this.businessHours.afternoonTimeStart && this.businessHours.afternoonTimeEnd) {
                let start = this.getHour(this.businessHours.afternoonTimeStart)
                let end = this.getHour(this.businessHours.afternoonTimeEnd) - 1

                if (h < start || h > end) {
                    return false;
                }
            }

            if (this.businessHours.morningTimeStart && this.businessHours.afternoonTimeEnd && this.businessHours.morningTimeEnd === "" && this.businessHours.afternoonTimeStart === "") {
                let start = this.getHour(this.businessHours.morningTimeStart)
                let end = this.getHour(this.businessHours.afternoonTimeEnd) - 1


                if (h < start || h > end) {
                    return false;
                }
            }

            return allowed;
        }
    }
}
</script>

<style lang="scss">
.local-pick-up {
    .primary {
        background: var(--primary);
    }

    .accent {
        background: var(--primary);
    }
}
</style>