<template>
  <div class="preloader">
      <img src="/build/images/brotkultur-logo.svg">
  </div>
</template>

<script>
export default {
  name: 'BrkuLoaderBrand',
}
</script>