var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-sidebar",
    {
      attrs: {
        "sidebar-class": "offcanvas-cart",
        id: "offcanvas-cart",
        visible: _vm.cartOpen,
        "backdrop-variant": "black",
        backdrop: "",
        "no-header": "",
        right: "",
      },
      on: { change: _vm.toggleOpenState },
    },
    [
      _c(
        "div",
        { staticClass: "offcanvas-cart__content-wrapper" },
        [
          _vm.hasItems
            ? [
                _c(
                  "form",
                  {
                    staticClass: "offcanvas-cart__content",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.updateCart.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "offcanvas-cart__header d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("div", { staticClass: "offcanvas-cart__title" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t("cart.ui.headline", {
                                  count: this.cartItems
                                    ? this.cartItems.length
                                    : 0,
                                })
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isB2B
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-custom btn-outline-primary d-md-none d-block",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.showCartInput = !_vm.showCartInput
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("cart.ui.save_cart")) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "offcanvas-cart__wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "offcanvas-cart__products" },
                        _vm._l(_vm.cartItems, function (item, index) {
                          return _c("cart-item", {
                            key: item.id,
                            attrs: { value: item },
                            on: {
                              "quantity-changed": function ($event) {
                                return _vm.onQuantityChanged(
                                  index,
                                  ...arguments
                                )
                              },
                              remove: _vm.removeCartItem,
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "offcanvas-cart__bottom-wrapper" },
                      [
                        _vm.isB2B
                          ? _c(
                              "div",
                              { staticClass: "offcanvas-cart__save-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "d-md-flex d-none",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showCartInput = !_vm.showCartInput
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa-solid fa-check save-cart",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("cart.ui.save_cart")) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("transition", { attrs: { name: "slide" } }, [
                                  _vm.showCartInput
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "offcanvas-cart__voucher-input mt-2",
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.customIdentifier,
                                                  expression:
                                                    "customIdentifier",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.$t(
                                                  "cart.ui.custom_identifier"
                                                ),
                                              },
                                              domProps: {
                                                value: _vm.customIdentifier,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.customIdentifier =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-custom btn-outline-primary d-flex align-items-center justify-content-center newsletter__button",
                                                attrs: { type: "submit" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.saveCart.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                !_vm.loadingCart
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa-solid fa-arrow-right",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.loadingCart
                                                  ? _c("i", {
                                                      staticClass:
                                                        "bk-spin fa-solid fa-circle-notch",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "offcanvas-cart__summary" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-5 offcanvas-cart__summary-col",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "offcanvas-cart__voucher" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "offcanvas-cart__voucher-header",
                                        class: {
                                          "is--open": _vm.showVoucherInput,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showVoucherInput =
                                              !_vm.showVoucherInput
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t("cart.ui.voucher_headline")
                                            ) +
                                            "\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "fa-solid fa-angle-right text-primary",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "transition",
                                      { attrs: { name: "slide" } },
                                      [
                                        _vm.showVoucherInput
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "offcanvas-cart__voucher-input",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Gutscheincode",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.cartRuleCoupon,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.cartRuleCoupon =
                                                            $$v
                                                        },
                                                        expression:
                                                          "cartRuleCoupon",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-custom btn-outline-primary d-flex align-items-center justify-content-center newsletter__button",
                                                        attrs: {
                                                          type: "submit",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.loadingCoupon = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        !_vm.loadingCoupon
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa-solid fa-arrow-right",
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.loadingCoupon
                                                          ? _c("i", {
                                                              staticClass:
                                                                "bk-spin fa-solid fa-circle-notch",
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-7 offcanvas-cart__summary-col",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "offcanvas-cart__summary-title mb-0",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("cart.ui.summary")) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "offcanvas-cart__summary-item",
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "subtotal-text mb-0" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t("cart.ui.subtotal_incl")
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "subtotal-price mb-0" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(_vm.subTotalFormatted) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.priceRuleItems,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "price-rule-item-" + index,
                                        staticClass:
                                          "offcanvas-cart__summary-item text-danger font-weight-bold",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "discount-text mb-0" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bk-i bk-i-tag-faces",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.cartPriceRule.name
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "discount-price mb-0",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.discountFormatted)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _vm.shipping > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "offcanvas-cart__summary-item",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "discount-text mb-0" },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "cart.ui.shipping_incl"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "discount-price mb-0",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(_vm.shippingFormatted) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.taxItems, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.name,
                                      staticClass:
                                        "offcanvas-cart__summary-item",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "discount-text mb-0" },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "discount-price mb-0" },
                                        [_vm._v(_vm._s(item.price))]
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "offcanvas-cart__summary-item is--total",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "offcanvas-cart__summary-total-label",
                                      },
                                      [
                                        _c("p", { staticClass: "first" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("cart.ui.total"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "second" }, [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.$t("cart.ui.incl_tax")
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "total-price-wrapper d-flex justify-content-md-end",
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "total-price__text d-flex d-md-none align-items-end mr-3",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t("cart.ui.incl_tax")
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          { staticClass: "total-price" },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm._f(
                                                    "brku_price_pre_decimal"
                                                  )(_vm.totalFormatted)
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "d-block" }, [
                                          _c(
                                            "p",
                                            { staticClass: "total-price__sub" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "brku_price_after_decimal"
                                                    )(_vm.totalFormatted)
                                                  ) +
                                                  "\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    class: [
                                      "btn btn-checkout btn-custom btn-primary has--icon-arrow-right w-100",
                                      { "d-none": _vm.isCheckout },
                                    ],
                                    attrs: { href: _vm.checkoutUrl },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("cart.ui.button")) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "hidden" },
                      domProps: { value: _vm.token },
                    }),
                  ]
                ),
              ]
            : [
                _c("div", { staticClass: "offcanvas-cart__content d-block" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "offcanvas-cart__header d-flex align-items-center justify-content-between",
                    },
                    [
                      _c("div", { staticClass: "offcanvas-cart__title" }, [
                        _vm._v(_vm._s(_vm.$t("cart.ui.headline"))),
                      ]),
                      _vm._v(" "),
                      _vm.isB2B
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-custom btn-outline-primary d-md-none d-block",
                              attrs: { type: "button" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("cart.ui.save_cart")) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "offcanvas-cart__message d-block mt-5 text-center",
                    },
                    [
                      _c("i", { staticClass: "fa-solid fa-cart-shopping" }),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-3" }, [
                        _vm._v(_vm._s(_vm.$t("cart.ui.no_items"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-custom btn-primary d-inline-flex has--icon-arrow-right",
                          attrs: { href: _vm.shopAction },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("cart.ui.to_overview")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }