var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-8 m-auto" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "stars-rating" }, [
              _c("div", { staticClass: "stars-rating__title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.product_review.total"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "stars-rating__stars" },
                [
                  _c("brku-star-rating", {
                    attrs: {
                      "active-color": "#e7cb5e",
                      "border-color": "#e7cb5e",
                      "inactive-color": "#ffffff",
                      "border-width": 3,
                      increment: 0.5,
                      inline: true,
                      rating: _vm.totalRating,
                      "read-only": true,
                      "rounded-corners": true,
                      "show-rating": false,
                      "star-size": 41,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "stars-rating" }, [
              _c("div", { staticClass: "stars-rating__title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.product_review.taste"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "stars-rating__stars" },
                [
                  _c("brku-star-rating", {
                    attrs: {
                      "active-color": "#bcbcbc",
                      "border-color": "#bcbcbc",
                      "inactive-color": "#ffffff",
                      "border-width": 3,
                      increment: 0.5,
                      inline: true,
                      "rounded-corners": true,
                      "show-rating": false,
                      "star-size": 28,
                    },
                    model: {
                      value: _vm.taste,
                      callback: function ($$v) {
                        _vm.taste = $$v
                      },
                      expression: "taste",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "stars-rating" }, [
              _c("div", { staticClass: "stars-rating__title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.product_review.freshness"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "stars-rating__stars" },
                [
                  _c("brku-star-rating", {
                    attrs: {
                      "active-color": "#bcbcbc",
                      "border-color": "#bcbcbc",
                      "inactive-color": "#ffffff",
                      "border-width": 3,
                      increment: 0.5,
                      inline: true,
                      "rounded-corners": true,
                      "show-rating": false,
                      "star-size": 28,
                    },
                    model: {
                      value: _vm.freshness,
                      callback: function ($$v) {
                        _vm.freshness = $$v
                      },
                      expression: "freshness",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "stars-rating" }, [
              _c("div", { staticClass: "stars-rating__title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.product_review.tolerability"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "stars-rating__stars" },
                [
                  _c("brku-star-rating", {
                    attrs: {
                      "active-color": "#bcbcbc",
                      "border-color": "#bcbcbc",
                      "inactive-color": "#ffffff",
                      "border-width": 3,
                      increment: 0.5,
                      inline: true,
                      "rounded-corners": true,
                      "show-rating": false,
                      "star-size": 28,
                    },
                    model: {
                      value: _vm.tolerability,
                      callback: function ($$v) {
                        _vm.tolerability = $$v
                      },
                      expression: "tolerability",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-8 mt-3 mx-auto" }, [
        _c(
          "form",
          {
            staticClass: "rating-form",
            attrs: {
              name: "product_review",
              method: "post",
              action: _vm.action,
            },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.addReview.apply(null, arguments)
              },
            },
          },
          [
            !_vm.user
              ? _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name",
                      },
                    ],
                    staticClass: "rating-form__input form-control",
                    attrs: {
                      type: "text",
                      id: "product_review_name",
                      required: "required",
                      placeholder: _vm.$t(
                        "site.ui.product_review.name.placeholder"
                      ),
                    },
                    domProps: { value: _vm.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.name = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title,
                    expression: "title",
                  },
                ],
                staticClass: "rating-form__input form-control",
                attrs: {
                  type: "text",
                  id: "product_review_title",
                  required: "required",
                  placeholder: _vm.$t(
                    "site.ui.product_review.title.placeholder"
                  ),
                },
                domProps: { value: _vm.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.title = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.review,
                    expression: "review",
                  },
                ],
                staticClass: "rating-form__input form-control is--textarea",
                attrs: {
                  id: "product_review_review",
                  rows: "6",
                  placeholder: _vm.$t(
                    "site.ui.product_review.text.placeholder"
                  ),
                },
                domProps: { value: _vm.review },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.review = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "google-recaptcha__text" }, [
              _vm._v(
                _vm._s(_vm.$t("site.ui.form.google_recaptcha.text")) + " "
              ),
              _c(
                "a",
                { attrs: { href: "https://policies.google.com/privacy" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("site.ui.form.google_recaptcha.privacy_policy")
                    )
                  ),
                ]
              ),
              _vm._v(" and "),
              _c(
                "a",
                { attrs: { href: "https://policies.google.com/terms" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("site.ui.form.google_recaptcha.terms_of_service")
                    )
                  ),
                ]
              ),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("site.ui.form.google_recaptcha.apply")) +
                  "."
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-custom btn-primary",
                attrs: { type: "submit" },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("site.ui.product_review.button")) +
                    "\n                "
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }