var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "text-center",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.resetPassword.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "password-reset-form mb-4" }, [
        _c("div", { staticClass: "password-reset-title text-center" }, [
          _vm._v(_vm._s(_vm.$t("coreshop.ui.password_reset"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "password-reset-text" }, [
          _vm._v(_vm._s(_vm.$t("coreshop.ui.password_reset_description"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group d-flex justify-content-center" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "form-control input-card",
            attrs: {
              type: "email",
              required: "",
              placeholder: _vm.$t("coreshop.ui.password_reset_email"),
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "btn-custom has--icon-arrow-right mb-2 mx-auto",
          attrs: { type: "submit", variant: "primary" },
        },
        [
          !_vm.loading
            ? _c("span", [_vm._v(_vm._s(_vm.$t("coreshop.ui.reset")))])
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("span", [_vm._v(_vm._s(_vm.$t("coreshop.ui.loading")))])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }