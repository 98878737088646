export const cart = {
    state: {
        open: false,
        ...window.cart,
        ...{
            previewPosition: {
                top: null,
                left: null,
                width: null,
                height: null
            }
        }
    },
    mutations: {
        toggleOpenState(state) {
            state.open = !state.open;
        },
        changeCart(state, payload) {
            state.content = payload;
        },
        setCartPreviewPosition(state, {top, left}) {
            state.previewPosition.left = left;
            state.previewPosition.top = top;
        },
        setCartPreviewDimensions(state, {width, height}) {
            state.previewPosition.width = width;
            state.previewPosition.height = height;
        }
    },
    getters: {
        cartOpen: state => {
            return state.open
        },
        cart: state => {
            return state.content
        },
        cartItems: state => {
            return state.content.items
        },
        cartCustomer: state => {
            return state.content ? state.content.customer : null
        }
    }
};