<template>
    <div class="process">
        <form
            @submit.prevent="register"
        >
            <div class="registration__content">
                <div class="process-container">
                    <div class="process-header d-flex align-items-center justify-content-between">
                        {{ $t('site.ui.register') }}
                        <a :href="loginUrl" class="process-header__link btn btn-link text-decoration-none">
                            {{ $t('site.ui.login') }}
                        </a>
                    </div>
                    <div class="process-step">
                        <div class="process-step__number"></div>
                        <div class="process-step__sub-title">{{ $t('site.ui.iam') }}</div>
                        <div class="row">
                            <div class="col-md-6">
                                <div :class="{'checkbox-card is--hover': true, 'active': !isB2B}"
                                     @click="onChangeAccountType(false)">
                                    <div class="custom-control custom-checkbox d-flex">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            id="coreshop_customer_registration_customer_isB2B_0"
                                            name="coreshop_customer_registration[customer][isB2B]"
                                            value="0"
                                            :checked="!isB2B"
                                            @change="onChangeAccountType(false)"
                                        />
                                        <label class="custom-control-label"
                                               for="coreshop_customer_registration_customer_isB2B_0">
                                            {{ $t('site.form.customer.private') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div :class="{'checkbox-card is--hover': true, 'active': isB2B}"
                                     @click="onChangeAccountType(true)">
                                    <div class="custom-control custom-checkbox d-flex">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            id="coreshop_customer_registration_customer_isB2B_1"
                                            name="coreshop_customer_registration[customer][isB2B]"
                                            value="1"
                                            :checked="isB2B"
                                            @change="onChangeAccountType(true)"
                                        />
                                        <label class="custom-control-label"
                                               for="coreshop_customer_registration_customer_isB2B_1">
                                            {{ $t('site.form.customer.business') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="process-step">
                        <div class="process-step__number"></div>
                        <div class="process-step__sub-title" v-if="isB2B">{{ $t('site.ui.company_information') }}</div>
                        <div class="process-step__sub-title" v-else>{{ $t('site.ui.personal_data') }}</div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <select
                                        class="form-control input-card"
                                        v-model="salutation"
                                    >
                                        <option value="">{{ $t('site.form.placeholder') }}</option>
                                        <option value="other">{{ $t('site.form.customer.salutation.other') }}</option>
                                        <option value="mr">{{ $t('site.form.customer.salutation.mr') }}</option>
                                        <option value="mrs">{{ $t('site.form.customer.salutation.mrs') }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="isB2B">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="company"
                                        :placeholder="`${$t('site.form.company.name')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="firstname"
                                        :placeholder="`${$t('site.form.customer.firstname')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="lastname"
                                        :placeholder="`${$t('site.form.customer.lastname')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group form-group">
                                    <vue-tel-input
                                        input-classes="form-control input-card"
                                        v-model="phoneNumber"
                                        :enabled-country-code="true"
                                        :only-countries="phoneCountriesOptions"
                                        :placeholder="`${$t('site.form.address.phone_number')}*`"
                                        :required="true"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6" v-if="isB2B">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="vatIdentificationNumber"
                                        :placeholder="`${$t('site.form.company.vat_identification_number')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <input
                                        type="email"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="email"
                                        :placeholder="`${$t('site.form.customer.email')}*`"
                                    />
                                    <input
                                        id="emailConfirmation"
                                        type="hidden"
                                        v-model="email"
                                    />
                                </div>
                            </div>
                            <div class="col-12"></div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="password"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="password"
                                        :placeholder="`${$t('site.form.customer.password')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="password"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="passwordConfirmation"
                                        :placeholder="`${$t('site.form.customer.password_confirmation')}*`"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="process-step">
                        <div class="process-step__number"></div>
                        <div class="process-step__sub-title">{{ $t('site.ui.invoice_address') }}</div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="invoiceAddressStreet"
                                        :placeholder="`${$t('site.form.address.street')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="invoiceAddressNumber"
                                        :placeholder="`${$t('site.form.address.number')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="invoiceAddressPostcode"
                                        :placeholder="`${$t('site.form.address.postcode')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="invoiceAddressCity"
                                        :placeholder="`${$t('site.form.address.city')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <multiselect
                                        v-model="invoiceAddressCountry"
                                        track-by="id"
                                        label="name"
                                        :placeholder="`${$t('site.form.address.country')}*`"
                                        :options="countries"
                                        :allow-empty="false"
                                    >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <span class="option__desc">
                                                <span
                                                    :class="`flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`"></span>
                                                <span class="option__title">{{ option.name }}</span>
                                            </span>
                                        </template>
                                        <template slot="option" slot-scope="{ option }">
                                            <div class="option__desc">
                                                <span
                                                    :class="`flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`"></span>
                                                <span class="option__title">{{ option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="process-step">
                        <div class="process-step__number"></div>
                        <div class="process-step__sub-title">{{ $t('site.ui.shipping_address') }}</div>
                        <div class="row">
                            <div class="custom-control custom-checkbox d-flex">
                                <input
                                    id="invoice_address_is_shipping_address"
                                    type="checkbox"
                                    class="custom-control-input"
                                    @change="isInvoiceAddress = !isInvoiceAddress"
                                    :checked="isInvoiceAddress"
                                />
                                <label class="custom-control-label" for="invoice_address_is_shipping_address">
                                    {{ $t('site.form.invoice_address_is_shipping_address') }}
                                </label>
                            </div>
                        </div>
                        <div class="row mt-3" v-if="!isInvoiceAddress">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="shippingAddressStreet"
                                        :placeholder="`${$t('site.form.address.street')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="shippingAddressNumber"
                                        :placeholder="`${$t('site.form.address.number')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="shippingAddressPostcode"
                                        :placeholder="`${$t('site.form.address.postcode')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control input-card"
                                        required="true"
                                        v-model="shippingAddressCity"
                                        :placeholder="`${$t('site.form.address.city')}*`"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <multiselect
                                        v-model="shippingAddressCountry"
                                        track-by="id"
                                        label="name"
                                        class="multiselect-card"
                                        :placeholder="`${$t('site.form.address.country')}*`"
                                        :options="countries"
                                        :allow-empty="false"
                                    >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <span class="option__desc">
                                                <span
                                                    :class="`flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`"></span>
                                                <span class="option__title">{{ option.name }}</span>
                                            </span>
                                        </template>
                                        <template slot="option" slot-scope="{ option }">
                                            <div class="option__desc">
                                                <span
                                                    :class="`flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`"></span>
                                                <span class="option__title">{{ option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="custom-control custom-checkbox d-flex mb-3 mt-3">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="coreshop_customer_registration_customer_newsletterActive"
                                    value="1"
                                    v-model="newsletterActive"
                                />
                                <label class="custom-control-label"
                                       for="coreshop_customer_registration_customer_newsletterActive">
                                    {{ $t('site.form.customer.newsletter_active') }}
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="custom-control custom-checkbox d-flex mb-3">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="coreshop_customer_registration_termsAccepted"
                                    required="true"
                                    v-model="termsAccepted"
                                    value="1"
                                />
                                <label class="custom-control-label" for="coreshop_customer_registration_termsAccepted">
                                    {{ $t('site.form.terms_accepted') }}
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-12 mt-3">
                                <button class="btn btn-custom btn-primary has--icon-arrow-right w-100">
                                    {{ $t('site.form.register.button') }}
                                </button>
                            </div>
                            <input
                                type="hidden"
                                name="coreshop_customer_registration[_token]"
                                :value="token"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },
    props: {
        action: String,
        token: String,
        redirectUrl: String,
        loginUrl: String,
        countries: Array
    },
    data: () => ({
        salutation: '',
        company: null,
        firstname: null,
        lastname: null,
        phoneNumber: '',
        vatIdentificationNumber: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        invoiceAddressStreet: null,
        invoiceAddressNumber: null,
        invoiceAddressPostcode: null,
        invoiceAddressCity: null,
        invoiceAddressCountry: null,
        isB2B: false,
        isInvoiceAddress: false,
        shippingAddressStreet: null,
        shippingAddressNumber: null,
        shippingAddressPostcode: null,
        shippingAddressCity: null,
        shippingAddressCountry: null,
        newsletterActive: null,
        termsAccepted: null,
        phoneCountriesOptions: [],
    }),
    created() {
        this.invoiceAddressCountry = this.countries[0];
        this.shippingAddressCountry = this.countries[0];

        if (this.countries) {
            this.countries.forEach((item) => {
                this.phoneCountriesOptions.push(item.isoCode);
            })
        }
    },
    computed: {
        gender() {
            if (this.salutation === 'mr') {
                return 'male';
            } else if (this.salutation === 'mrs') {
                return 'female';
            }

            return '';
        }
    },
    methods: {
        register() {
            axios.post(this.action, {
                coreshop_customer_registration: {
                    customer: {
                        gender: this.gender,
                        salutation: this.salutation,
                        firstname: this.firstname,
                        lastname: this.lastname,
                        email: {
                            first: this.email,
                            second: this.email
                        },
                        password: {
                            first: this.password,
                            second: this.passwordConfirmation
                        },
                        newsletterActive: this.newsletterActive
                    },
                    company: {
                        name: this.company,
                        vatIdentificationNumber: this.vatIdentificationNumber
                    },
                    address: {
                        street: this.invoiceAddressStreet,
                        number: this.invoiceAddressNumber,
                        postcode: this.invoiceAddressPostcode,
                        city: this.invoiceAddressCity,
                        country: this.invoiceAddressCountry.id,
                        phoneNumber: this.phoneNumber
                    },
                    shippingAddress: {
                        street: this.isInvoiceAddress ? this.invoiceAddressStreet : this.shippingAddressStreet,
                        number: this.isInvoiceAddress ? this.invoiceAddressNumber : this.shippingAddressNumber,
                        postcode: this.isInvoiceAddress ? this.invoiceAddressPostcode : this.shippingAddressPostcode,
                        city: this.isInvoiceAddress ? this.invoiceAddressCity : this.shippingAddressCity,
                        country: this.isInvoiceAddress ? this.invoiceAddressCountry.id : this.shippingAddressCountry.id,
                        phoneNumber: this.phoneNumber
                    },
                    termsAccepted: this.termsAccepted,
                    _token: this.token
                }
            })
                .then(res => res.data)
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open(res.message);

                    this.reset();

                    setTimeout(() => {
                        window.location.href = this.redirectUrl;
                    }, 1000);
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.open({
                        message: err.response.data.message,
                        type: 'error'
                    });
                });
        },
        reset() {
            this.salutation = '';
            this.company = null;
            this.firstname = null;
            this.lastname = null;
            this.phoneNumber = '';
            this.vatIdentificationNumber = null;
            this.email = null;
            this.password = null;
            this.passwordConfirmation = null;
            this.invoiceAddressStreet = null;
            this.invoiceAddressNumber = null;
            this.invoiceAddressPostcode = null;
            this.invoiceAddressCity = null;
            this.invoiceAddressCountry = this.countries[0];
            this.isB2B = false;
            this.isInvoiceAddress = false;
            this.shippingAddressStreet = null;
            this.shippingAddressNumber = null;
            this.shippingAddressPostcode = null;
            this.shippingAddressCity = null;
            this.shippingAddressCountry = this.countries[0];
            this.newsletterActive = null;
            this.termsAccepted = null;
        },
        onChangeAccountType(isB2B) {
            this.isB2B = isB2B;
        },
        showLoginModal(value) {
            this.$store.commit('showLogin', value);
        }
    }
}
</script>