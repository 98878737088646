<template>
  <div class="inventory-item">
    <div class="row">
      <div v-if="!defaultImage" class="col-1 d-flex align-items-center" v-html="image"></div>
      <div v-if="defaultImage" class="col-1 d-flex align-items-center">
        <img :src="defaultImage" class="item-image">
      </div>
      <div class="col d-flex align-items-center">
        <div class="item-name">
          {{ name }}
        </div>
      </div>
      <div class="col d-flex align-items-center">
        <div class="item-number">
          Art. Nr.: {{ sku }}
        </div>
      </div>
      <div class="col d-flex align-items-center justify-content-end">
        <div class="quantity-input input-group">
          <div class="input-group-prepend">
            <button @click="scopeStock--" class="btn btn-outline-secondary" type="button">
              <i class="fa-solid fa-minus"></i>
            </button>
            <button @click="scopeStock++" class="btn btn-outline-secondary ml-3" type="button">
              <i class="fa-solid fa-plus"></i>
            </button>
            <input type="number" v-model="scopeStock" class="form-control">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProductStock',
  props: {
    id: {
      type: String,
      default: null,
    },
    updateStockAction: {
      type: String,
      default: null,
    },
    defaultImage: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    warehouse: {
      type: Object,
      default: null,
    },
    stockItems: {
      type: Array,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    sku: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      initialStock: 0,
      scopeStock: 0,
      loading: false
    }
  },
  mounted() {
    this.scopeStock = this.currentWarehouse ? this.currentWarehouse.quantity : 0;
    this.initialStock = this.currentWarehouse ? this.currentWarehouse.quantity : 0;
  },
  watch: {
    scopeStock: function () {
      this.saveStock()
    },
  },
  methods: {
    saveStock: _.debounce(function() {
      this.loading = true;

      axios.post(this.updateStockAction, {
        productId: this.id,
        stockId: this.currentWarehouse.id,
        stock: (this.scopeStock - this.initialStock)
      }).then(() => {
        this.initialStock = this.scopeStock;

        this.$toast.clear();
        this.$toast.open(this.$t('site.toast.product_stock_saved'));

        this.loading = false;
      })
    }, 1000)
  },
  computed: {
    currentWarehouse() {
      return this.stockItems.find(stock => stock.warehouseLocation.id === this.warehouse.id);
    }
  }
}
</script>