<template>
    <div class="row">
        <div class="col-4">
            <div class="incoming-order__card">
                <div class="card-header d-flex align-items-center is--red">
                    <p class="card-header__title">Offene Bestellung</p>
                    <p class="card-header__count">({{ scopeNewOrders.length }})</p>
                </div>
                <div class="card-body">
                    <brku-order-item-card
                        v-for="order in scopeNewOrders"
                        :key="order.id"
                        @set-data="setData"
                        :show-order-number="true"
                        :show-pickup-date="true"
                        :order="order"
                        :order-state-action="orderStateAction"
                    ></brku-order-item-card>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="incoming-order__card">
                <div class="card-header d-flex align-items-center is--orange">
                    <p class="card-header__title">Zur Abholung bereit</p>
                    <p class="card-header__count">({{ scopeReadyOrders.length }})</p>
                </div>
                <div class="card-body">
                    <brku-order-item-card
                        v-for="order in scopeReadyOrders"
                        :key="order.id"
                        @set-data="setData"
                        :show-order-number="true"
                        :show-pickup-date="true"
                        :order="order"
                        :order-state-action="orderStateAction"
                    ></brku-order-item-card>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="incoming-order__card">
                <div class="card-header d-flex align-items-center is--green">
                    <p class="card-header__title">Abgeschlossen</p>
                    <p class="card-header__count">({{ scopeReadyOrders.length }})</p>
                    <i class="fa-solid fa-check card-header__icon"></i>
                </div>
                <div class="card-body">
                    <brku-order-item-card
                        v-for="order in scopeCompletedOrders"
                        :key="order.id"
                        @set-data="setData"
                        :show-product-amount="true"
                        :order="order"
                        :order-state-action="orderStateAction"
                    ></brku-order-item-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderBoard',
    props: {
        archivedOrders: {
            type: Array,
            default: () => []
        },
        completedOrders: {
            type: Array,
            default: () => []
        },
        readyOrders: {
            type: Array,
            default: () => []
        },
        newOrders: {
            type: Array,
            default: () => []
        },
        orderStateAction: {
            type: String,
            default: null
        }
    },
    methods: {
        setData(data) {
            this.scopeArchivedOrders = data.archivedOrders;
            this.scopeCompletedOrders = data.completedOrders;
            this.scopeReadyOrders = data.readyOrders;
            this.scopeNewOrders = data.newOrders;
        }
    },
    data() {
        return {
            scopeArchivedOrders: this.archivedOrders,
            scopeCompletedOrders: this.completedOrders,
            scopeReadyOrders: this.readyOrders,
            scopeNewOrders: this.newOrders,
        }
    }
}
</script>