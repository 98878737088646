import Vue from 'vue';
import Vuex from 'vuex';
import {cart} from './modules/cart';
import {checkout} from './modules/checkout';
import {login} from './modules/login';
import {wishlist} from './modules/wishlist';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        cart,
        checkout,
        login,
        wishlist
    }
});