<template>
    <b-sidebar
        sidebar-class="offcanvas-cart"
        id="offcanvas-cart"
        :visible="cartOpen"
        backdrop-variant="black"
        @change="toggleOpenState"
        backdrop
        no-header
        right>
        <div class="offcanvas-cart__content-wrapper">
            <template v-if="hasItems">
                <form class="offcanvas-cart__content" @submit.prevent="updateCart">
                    <div class="offcanvas-cart__header d-flex align-items-center justify-content-between">
                        <div class="offcanvas-cart__title">
                            {{
                                $t('cart.ui.headline', {count: this.cartItems ? this.cartItems.length : 0})
                            }}
                        </div>
                        <button type="button" class="btn btn-custom btn-outline-primary d-md-none d-block" v-if="isB2B"
                                @click.prevent="showCartInput = !showCartInput">
                            {{ $t('cart.ui.save_cart') }}
                        </button>
                    </div>
                    <div class="offcanvas-cart__wrapper">
                        <div class="offcanvas-cart__products">
                            <cart-item v-for="(item, index) in cartItems"
                                       :value="item"
                                       @quantity-changed="onQuantityChanged(index, ...arguments)"
                                       @remove="removeCartItem"
                                       :key="item.id">
                            </cart-item>
                        </div>
                    </div>
                    <div class="offcanvas-cart__bottom-wrapper">
                        <div class="offcanvas-cart__save-item" v-if="isB2B">
                            <a href="#" @click="showCartInput = !showCartInput" class="d-md-flex d-none">
                                <i class="fa-solid fa-check save-cart"></i> {{ $t('cart.ui.save_cart') }}
                            </a>
                            <transition name="slide">
                                <div v-if="showCartInput" class="offcanvas-cart__voucher-input mt-2">
                                    <div class="d-flex">
                                        <input
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('cart.ui.custom_identifier')"
                                            v-model="customIdentifier"
                                        />
                                        <button
                                            type="submit"
                                            @click.prevent="saveCart"
                                            class="btn btn-custom btn-outline-primary d-flex align-items-center justify-content-center newsletter__button"
                                        >
                                            <i v-if="!loadingCart" class="fa-solid fa-arrow-right"></i>
                                            <i v-if="loadingCart" class="bk-spin fa-solid fa-circle-notch"></i>
                                        </button>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div class="offcanvas-cart__summary">
                            <div class="row">
                                <div class="col-md-5 offcanvas-cart__summary-col">
                                    <div class="offcanvas-cart__voucher">
                                        <div @click="showVoucherInput = !showVoucherInput"
                                             class="offcanvas-cart__voucher-header"
                                             :class="{'is--open' : showVoucherInput}">
                                            {{ $t('cart.ui.voucher_headline') }}
                                            <i class="fa-solid fa-angle-right text-primary"></i>
                                        </div>
                                        <transition name="slide">
                                            <div v-if="showVoucherInput" class="offcanvas-cart__voucher-input">
                                                <div class="d-flex">
                                                    <b-form-input
                                                        type="text"
                                                        placeholder="Gutscheincode"
                                                        v-model="cartRuleCoupon"
                                                    />
                                                    <button
                                                        type="submit"
                                                        @click="loadingCoupon = true"
                                                        class="btn btn-custom btn-outline-primary d-flex align-items-center justify-content-center newsletter__button"
                                                    >
                                                        <i v-if="!loadingCoupon" class="fa-solid fa-arrow-right"></i>
                                                        <i v-if="loadingCoupon"
                                                           class="bk-spin fa-solid fa-circle-notch"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <div class="col-md-7 offcanvas-cart__summary-col">
                                    <p class="offcanvas-cart__summary-title mb-0">
                                        {{ $t('cart.ui.summary') }}
                                    </p>
                                    <div class="offcanvas-cart__summary-item">
                                        <p class="subtotal-text mb-0">
                                            {{ $t('cart.ui.subtotal_incl') }}
                                        </p>
                                        <p class="subtotal-price mb-0">
                                            {{ subTotalFormatted }}
                                        </p>
                                    </div>
                                    <div class="offcanvas-cart__summary-item text-danger font-weight-bold"
                                         v-for="(item, index) in priceRuleItems"
                                         :key="'price-rule-item-' + index"
                                    >
                                        <p class="discount-text mb-0">
                                            <i class="bk-i bk-i-tag-faces"></i> {{ item.cartPriceRule.name }}
                                        </p>
                                        <p class="discount-price mb-0">{{ item.discountFormatted }}</p>
                                    </div>
                                    <div class="offcanvas-cart__summary-item"
                                         v-if="shipping > 0"
                                    >
                                        <p class="discount-text mb-0">
                                            {{ $t('cart.ui.shipping_incl') }}
                                        </p>
                                        <p class="discount-price mb-0">
                                            {{ shippingFormatted }}
                                        </p>
                                    </div>
                                    <div class="offcanvas-cart__summary-item"
                                         v-for="item in taxItems"
                                         :key="item.name"
                                    >
                                        <p class="discount-text mb-0">{{ item.name }}</p>
                                        <p class="discount-price mb-0">{{ item.price }}</p>
                                    </div>
                                    <div class="offcanvas-cart__summary-item is--total">
                                        <div class="offcanvas-cart__summary-total-label">
                                            <p class="first"> {{ $t('cart.ui.total') }}</p>
                                            <p class="second">
                                                {{ $t('cart.ui.incl_tax') }}
                                            </p>
                                        </div>
                                        <div class="total-price-wrapper d-flex justify-content-md-end">
                                            <p class="total-price__text d-flex d-md-none align-items-end mr-3">
                                                {{ $t('cart.ui.incl_tax') }}
                                            </p>
                                            <p class="total-price ">
                                                {{ totalFormatted|brku_price_pre_decimal }}
                                            </p>
                                            <div class="d-block">
                                                <p class="total-price__sub">
                                                    {{ totalFormatted|brku_price_after_decimal }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <a :href="checkoutUrl"
                                       :class="['btn btn-checkout btn-custom btn-primary has--icon-arrow-right w-100', {'d-none': isCheckout}]">
                                        {{ $t('cart.ui.button') }}
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <input type="hidden" :value="token"/>
                </form>
            </template>
            <template v-else>
                <div class="offcanvas-cart__content d-block">
                    <div class="offcanvas-cart__header d-flex align-items-center justify-content-between">
                        <div class="offcanvas-cart__title">{{ $t('cart.ui.headline') }}</div>
                        <button type="button" class="btn btn-custom btn-outline-primary d-md-none d-block" v-if="isB2B">
                            {{ $t('cart.ui.save_cart') }}
                        </button>
                    </div>
                    <div class="offcanvas-cart__message d-block mt-5 text-center">
                        <i class="fa-solid fa-cart-shopping"></i>
                        <p class="mt-3">{{ $t('cart.ui.no_items') }}</p>
                        <a class="btn btn-custom btn-primary d-inline-flex has--icon-arrow-right" :href="shopAction">
                            {{ $t('cart.ui.to_overview') }}
                        </a>
                    </div>
                </div>
            </template>
        </div>
    </b-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import CartItem from "./CartItem";

export default {
    components: {
        CartItem
    },
    props: {
        action: {
            type: String,
            default: ''
        },
        removeAction: {
            type: String,
            default: ''
        },
        shopAction: {
            type: String,
            default: ''
        },
        saveAction: {
            type: String,
            default: ''
        },
        checkoutUrl: {
            type: String,
            required: true
        },
        token: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        loadingCart: false,
        loadingCoupon: false,
        showCartInput: false,
        showVoucherInput: false,
        cartRuleCoupon: null,
        customIdentifier: null,
        loading: false,
    }),
    mounted() {
        if (document.body.classList.contains('page-checkout') && !window.matchMedia('(max-width: 768px)').matches) {
            this.visible = true;
        }
    },
    computed: {
        ...mapGetters([
            'cartOpen',
            'cart',
            'cartItems'
        ]),
        subTotalFormatted() {
            return this.cart.subtotalGrossFormatted
        },
        totalFormatted() {
            return this.cart.totalGrossFormatted
        },
        taxItems() {
            return this.cart.taxes;
        },
        priceRuleItems() {
            return this.cart.priceRuleItems;
        },
        shipping() {
            return this.cart.shippingGross
        },
        shippingFormatted() {
            return this.cart.shippingGrossFormatted
        },
        hasItems() {
            return this.cartItems.length > 0
        },
        hasCarrierShipping() {
            return this.$store.state.cart.content?.customer?.hasCarrierShipping;
        },
        isB2B() {
            return this.$store.state.cart.content?.customer?.company ? true : false;
        },
        isCheckout() {
            return document.body.classList.contains('page-checkout');
        }
    },
    methods: {
        updateCart() {
            this.loading = true;

            const items = this.cartItems.map(item => {
                return {
                    quantity: item.quantity
                }
            })

            axios.post(this.action, {
                coreshop_cart: {
                    cartRuleCoupon: this.cartRuleCoupon,
                    items: items,
                    _token: this.token
                }
            })
                .then(res => res.data)
                .then(res => {
                    this.$store.commit('changeBranches', res.branches);
                    this.$store.commit('changeCarriers', res.carriers);
                    this.$store.commit('changeCart', res.cart);

                    this.$toast.clear();
                    this.$toast.open({
                        message: this.$t(res.message),
                        type: res.state
                    });

                    this.loading = false;
                    this.loadingCoupon = false;

                    this.reset();
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        saveCart() {
            this.loadingCart = true

            axios.post(this.saveAction, {
                customIdentifier: this.customIdentifier
            })
                .then(res => res.data)
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open(this.$t('cart.ui.saved_successfully'));

                    this.loadingCart = false;
                })
                .catch(err => {
                    this.loadingCart = false;
                });
        },
        removeCartItem(item) {
            this.loading = true;

            axios.post(this.removeAction, {
                cartItem: item.id
            })
                .then(res => res.data)
                .then(res => {
                    this.$store.commit('changeBranches', res.branches);
                    this.$store.commit('changeCart', res.cart);

                    this.$toast.clear();
                    this.$toast.open(res.message);

                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        onQuantityChanged(index, {item, quantity}) {
            console.log('updated item quantity', index, item, quantity);

            this.cartItems[index].quantity = quantity;

            if (!this.loading) {
                this.updateCart();
            }
        },
        reset() {
            this.cartRuleCoupon = null;
        },
        toggleOpenState(state) {
            if (!state) {
                this.$store.commit('toggleOpenState')
            }
        }
    }
}
</script>