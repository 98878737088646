<template>
    <div class="product-filter__item current-filters" v-if="selectedFilter.length > 0">
        <div class="product-filter__header">
            <p class="product-filter__title">{{ $t('site.ui.active_filters') }}</p>
            <button
                class="product-filter__clear"
                @click="onRemoveAll"
            >
                {{ $t('site.ui.remove_all') }}
            </button>
        </div>
        <div class="product-filter__options-wrapper no-collapse d-flex flex-wrap">
            <p class="product-filter__current-item"
               v-for="item in selectedFilter"
               :key="item.id"
               @click="onRemove(item)"
            >
                {{ item.filter_name }}
                <i class="fa-solid fa-trash"></i>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        conditions: {
            type: Object | Array,
            default: null
        },
        currentFilter: {
            type: Object | Array,
            default: null
        }
    },
    data() {
        return {
            selectedFilter: []
        }
    },
    created() {
        this.selectedFilter = this.getSelectedFilterItems();
    },
    methods: {
        getSelectedFilterItems() {
            let filterItems = [];
            let conditions = Object.values(this.conditions);

            Object.entries(this.currentFilter).forEach(entry => {
                let [key, value] = entry;
                let condition = conditions.filter(item => item.fieldName === key);

                if (value === null || value === '') {
                    return;
                }

                if (condition[0].type !== 'select') {
                    if (Array.isArray(value)) {
                        value.forEach(item => {
                            filterItems.push(this.getObjectForId(condition[0], item));
                        });
                    } else {
                        filterItems.push(this.getObjectForId(condition[0], value));
                    }
                }
            });

            return filterItems;
        },
        getObjectForId(condition, id) {
            let objects = condition.objects.filter(object => {
                object.fieldName = condition.fieldName;

                return parseInt(object.id) === parseInt(id);
            });

            if (objects.length > 0) {
                return objects[0];
            }

            return {
                name: null
            }
        },
        onRemove(item) {
            this.selectedFilter = this.selectedFilter.filter(filterItem => {
                return filterItem.id !== item.id;
            });

            for (let condition of Object.values(this.conditions)) {
                if (condition.fieldName !== item.fieldName) {
                    continue;
                }

                if (typeof condition.currentValues === 'undefined') {
                    return;
                }

                if (Array.isArray(condition.currentValues)) {
                    condition.currentValues = condition.currentValues.filter(value => {
                        return parseInt(item.id) !== parseInt(value);
                    });
                } else {
                    condition.currentValues = null;
                }

                this.$emit('filter-update', {
                    fieldName: condition.fieldName,
                    values: condition.currentValues
                });
            }
        },
        onRemoveAll() {
            this.selectedFilter = [];

            this.$emit('remove-all');
        }
    },
    watch: {
        currentFilter: {
            handler() {
                this.selectedFilter = this.getSelectedFilterItems();
            },
            deep: true
        }
    }
}
</script>