<template>
    <div class="local-delivery">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="process-step__sub-title">
                        {{ $t('site.ui.checkout.scheduled_local_delivery.start_date') }}
                    </label>
                    <div class="datepicker d-flex">
                        <button type="button" class="datepicker__arrow is--left d-none d-md-block" @click="prevDay">
                            <i class="fa-solid fa-angle-left"></i>
                        </button>
                        <b-form-datepicker
                            v-model="startDate"
                            :min="minDate.toDate()"
                            :date-format-options="{ weekday: 'short', day: '2-digit', month: 'long', year: 'numeric' }"
                        ></b-form-datepicker>
                        <button type="button" class="datepicker__arrow is--right d-none d-md-block" @click="nextDay">
                            <i class="fa-solid fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="process-step__sub-title">
                        {{ $t('site.ui.checkout.scheduled_local_delivery.end_date') }}
                    </label>
                    <div class="datepicker d-flex">
                        <button type="button" class="datepicker__arrow is--left d-none d-md-block" @click="prevDay">
                            <i class="fa-solid fa-angle-left"></i>
                        </button>
                        <b-form-datepicker
                            v-model="endDate"
                            :min="minDate.toDate()"
                            :date-format-options="{ weekday: 'short', day: '2-digit', month: 'long', year: 'numeric' }"
                        ></b-form-datepicker>
                        <button type="button" class="datepicker__arrow is--right d-none d-md-block" @click="nextDay">
                            <i class="fa-solid fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label class="process-step__sub-title">
                        {{ $t('site.ui.checkout.local_delivery.shipping_address') }}
                    </label>
                    <b-form-select v-model="selectedShippingAddress" :options="shippingAddressOptions"></b-form-select>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label class="process-step__sub-title">
                        {{ $t('site.ui.checkout.local_delivery.weekdays') }}
                    </label>
                    <b-form-checkbox-group
                        v-model="weekday"
                        name="weekdays"
                    >
                        <b-form-checkbox v-for="item in weekdays" :value="item.value" :key="item.value">
                            {{ item.label }}
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center my-2 my-md-4">
            <b-button type="submit" variant="primary" class="btn-custom">
                {{ $t('site.ui.checkout.continue') }}
            </b-button>
        </div>
        <input name="coreshop[recurringOrderStartDate]" :value="formattedDate(startDate)" type="hidden"/>
        <input name="coreshop[recurringOrderEndDate]" :value="formattedDate(endDate)" type="hidden"/>
        <input name="coreshop[shippingAddress]" :value="shippingAddress" type="hidden"/>
        <input v-for="selected in weekday" name="coreshop[weekdays][]" :value="selected" type="hidden"/>
        <input name="coreshop[interval]" :value="interval" type="hidden"/>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        earliestDeliveryDate: {
            type: String,
            required: true
        },
        shippingAddresses: {
            type: Array,
            required: true
        },
        weekdays: {
            type: Array,
            required: true
        },
        intervals: {
            type: Array,
            required: true
        },
        selectedStartDate: {
            type: String,
            required: false
        },
        selectedEndDate: {
            type: String,
            required: false
        },
        selectedShippingAddress: {
            type: String,
            required: false
        },
        selectedWeekdays: {
            type: Array,
            required: false
        },
        selectedInterval: {
            type: String,
            required: false
        },
    },
    data: () => ({
        startDate: null,
        endDate: null,
        shippingAddress: null,
        interval: null,
        weekday: []
    }),
    created() {
        this.startDate = this.selectedStartDate ? moment(this.selectedStartDate).toDate() : this.minDate.toDate()
        this.endDate = this.selectedEndDate ? moment(this.selectedEndDate).toDate() : this.minDate.add(1, 'month').toDate()
        this.shippingAddress = this.selectedShippingAddress
        this.weekday = this.selectedWeekdays ? this.selectedWeekdays : []
        this.interval = this.selectedInterval
    },
    computed: {
        valid() {
            return this.selectedDate != null && this.selectedShippingAddress != null;
        },
        currentDate() {
            return moment()
        },
        minDate() {
            return moment(this.earliestDeliveryDate)
        },
        shippingAddressOptions() {
            let options = this.shippingAddresses.map(item => {
                return {
                    value: item.value,
                    text: item.label
                }
            })

            options.unshift({
                value: null,
                text: this.$t('site.ui.checkout.choose_address')
            })

            return options;
        },
        intervalOptions() {
            let options = this.intervals.map(item => {
                return {
                    value: item.value,
                    text: item.label
                }
            })

            options.unshift({
                value: null,
                text: this.$t('site.ui.checkout.choose_interval')
            })

            return options;
        }
    },
    methods: {
        formattedDate(date) {
            if (date) {
                return moment(date).format('YYYY-MM-DD')
            }

            return null;
        },
        nextDay() {
            this.selectedDate = moment(this.selectedDate).add(1, 'day').toDate();
        },
        prevDay() {
            this.selectedDate = moment(this.selectedDate).subtract(1, 'day').toDate();
        }
    }
}
</script>

<style scoped>

</style>