if(document.getElementById("navbar-burger")) {
    document.getElementById('navbar-burger').addEventListener('click', function () {
        const navbar = document.getElementById('navbar');
        navbar.classList.toggle('is--open');
    });
}

if(document.getElementById("navbar-account")) {
    document.getElementById('navbar-account').addEventListener('click', function () {
        const navbar = document.getElementById('account-sidebar');
        const navbarBackdrop = document.getElementById('account-sidebar-backdrop');
        navbar.classList.toggle('is--open');
        navbarBackdrop.classList.toggle('is--open');
    });
}

if(document.getElementById("account-sidebar-backdrop")) {
    document.getElementById('account-sidebar-backdrop').addEventListener('click', function () {
        const navbar = document.getElementById('account-sidebar');
        const navbarBackdrop = document.getElementById('account-sidebar-backdrop');
        navbar.classList.remove('is--open');
        navbarBackdrop.classList.remove('is--open');
    });
}
