<template>
    <div class="accordion" role="tablist">
        <b-card no-body class="mb-1" v-for="carrier in carriers">
            <b-card-header role="tab" @click="toggleCard(carrier.identifier)"
                           :header-class="[isActive(carrier.identifier) ? 'active' : '', isAvailable(carrier.identifier) ? '' : 'is--not-available']">
                <div class="left d-flex">
                    <i :class="['bk-i ', getIconName(carrier.identifier) , 'order-type-icon']"></i>
                    <p class="order-type-label">
                        {{ $t(getOrderTypeLabel(carrier.identifier)) }}
                    </p>
                </div>
                <p class="m-0">
                    {{
                        isAvailable(carrier.identifier) ? $t('site.ui.shared.choose') : $t('site.ui.carrier.not_available')
                    }}
                </p>
            </b-card-header>
            <b-collapse :id="carrier.identifier" :visible="isActive(carrier.identifier)" accordion="my-accordion"
                        role="tabpanel">
                <b-card-body>
                    <b-card-text v-if="isAvailable(carrier.identifier)"
                                 v-html="$t('site.ui.order_type.' + carrier.identifier + '.description')"></b-card-text>
                    <b-card-text v-else v-html="$t('site.ui.checkout.contact_carrier_not_available')"></b-card-text>
                    <div v-if="isAvailable(carrier.identifier)" class="d-flex justify-content-center my-2 my-md-4">
                        <b-button type="submit" variant="primary" class="btn-custom">
                            {{ $t('site.ui.checkout.continue') }}
                        </b-button>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <input name="coreshop[orderType]" :value="selectedOrderType" type="hidden"/>
        <input name="coreshop[carrier]" :value="selectedCarrier" type="hidden"/>
    </div>
</template>
<script>
export default {
    props: {
        selected: {
            type: String,
        },
        orderTypes: {
            type: Array,
            default: []
        },
        carriers: {
            type: Array,
            default: []
        }
    },
    data: () => ({
        selectedOrderType: null,
    }),
    created() {
        this.selectedOrderType = this.selected ? this.selected : this.orderTypes[0].value;
    },
    mounted() {
        this.$nextTick(() => {
            this.toggleCard(this.selectedOrderType);
        });
    },
    computed: {
        availableOrderTypes() {
            return this.orderTypes.filter(type => {
                let carrier = this.carriers.find(carrier => carrier.identifier === type.value);
                return !!carrier;
            })
        },
        selectedCarrier() {
            if (this.selectedOrderType) {
                const carrier = this.carriers.find(item => item.identifier === this.selectedOrderType);

                return carrier ? carrier.id : null;
            }

            return null;
        }
    },
    methods: {
        isAvailable(identifier) {
            let orderType = this.orderTypes.find(type => type.value === identifier);
            return !!orderType;
        },
        getOrderTypeLabel(identifier) {
            let orderType = this.orderTypes.find(type => type.value === identifier);
            return orderType ? orderType.label : 'site.ui.order_type.' + identifier
        },
        isActive(id) {
            return this.selectedOrderType === id;
        },
        toggleCard(id) {
            this.selectedOrderType = id;
        },
        getIconName(identifier) {
            return 'bk-i-order-type-' + identifier.replace('_', '-')
        }
    }
}
</script>

<style lang="scss" scoped>
.accordion {
    .card-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;

        .order-type-icon {
            margin-right: 15px;
            font-size: 20px;
            line-height: 20px;
        }

        .order-type-label {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
            margin: 0;
            font-size: 16px;
            line-height: 20px;
        }


        &:not(.is--not-available) {
            &:hover, &.active {
                color: white;
                background: var(--primary);
                transition: ease-in-out 200ms all;
            }
        }

        &.is--not-available {
            color: #ccc;
        }
    }

    .card-text {
        font-size: 18px;
    }
}
</style>