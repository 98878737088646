<template>
    <div class="hero-slider__inner">
        <div class="hero-slider__overlay"></div>
        <b-carousel
            class="hero-slider__slides"
            v-model="slide"
            :interval="0"
            :no-hover-pause="true"
            ref="slider"
            @sliding-end="setActiveSlideIndex"
        >
            <slot name="slides"></slot>
        </b-carousel>
        <div class="hero-slider__site">
            <div class="hero-slider__site-wrapper">
                <span class="hero-slider__site-current-index" v-text="formatLeadingZero(activeSliderIndex + 1)"></span>
                <span class="hero-slider__site-amount" v-text="formatLeadingZero(count)"></span>
            </div>
        </div>
        <div class="slider-navigation-arrow arrow-xl is--left" @click="$refs.slider.prev()">
            <i class="fa-solid fa-arrow-left slider-navigation-arrow__icon"></i>
        </div>
        <div class="slider-navigation-arrow arrow-xl is--right" @click="$refs.slider.next()">
            <i class="fa-solid fa-arrow-right slider-navigation-arrow__icon"></i>
        </div>
        <div class="progress-bar-wrapper d-none d-xxl-flex">
            <div class="progress-bar"></div>
        </div>
        <button class="hero-slider__scroll-down btn btn-link" @click.prevent="scrollToContent">
            <i class="fa-solid fa-angles-down"></i>
        </button>
    </div>
</template>

<script>
import vidbg from "../../dom/vidbg"

export default {
    props: {
        image: {
            type: String,
            default: null
        },
        itemsCount: {
            type: String,
            default: null
        },
        video: {
            type: String,
            default: null
        }
    },
    data: () => ({
        activeSliderIndex: 0,
        count: 0,
        slide: 0
    }),
    created() {
        this.count = parseInt(this.itemsCount);
    },
    mounted() {
        this.mountVideoBg();
    },
    methods: {
        mountVideoBg() {
            let bgVideo = new vidbg('.hero-slider__inner', {
                mp4: this.video,
                poster: this.image,
                overlay: false
            });

            bgVideo.resize();
        },
        setActiveSlideIndex(val) {
            this.activeSliderIndex = val;
        },
        formatLeadingZero(val) {
            let string = val.toString();

            return string.padStart(2, '0');
        },
        scrollToContent() {
            let element = document.getElementById("hero-slider").nextElementSibling;

            if (typeof element === 'undefined') {
                return;
            }

            this.$scrollTo(element);
        }
    }
}
</script>