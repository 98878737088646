<template>
    <div class="modal d-block" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content incoming-order">
                <div class="modal-header incoming-order-header position-relative has--divider-bottom">
                    <div class="row w-100">
                        <div class="col-6">
                            <div class="order-header__title">Bestellnummer</div>
                            <div class="order-header__sub-title">{{ order.orderNumber }}</div>
                        </div>
                        <div class="col-6">
                            <div class="order-header__title">Abholung</div>
                            <div class="order-header__sub-title">
                                {{ order.pickUpDate|formatDate }} | {{ order.pickUpTime }} Uhr
                            </div>
                        </div>
                    </div>
                    <span @click="close" class="modal-close-wrapper d-flex position-absolute align-items-center">
                        <div class="close-text">Schliessen</div>
                        <i class="fa-solid fa-xmark close-icon"></i>
                    </span>
                </div>
                <div class="modal-body incoming-order-body">
                    <p class="order-body__title">
                        Kontaktdaten
                    </p>
                    <div class="card-address">
                        <p>
                            {{ order.customer.defaultAddress.firstname }} {{ order.customer.defaultAddress.lastname }}
                        </p>
                        <p>{{ order.customer.defaultAddress.street }} {{ order.customer.defaultAddress.number }}</p>
                        <p>{{ order.customer.defaultAddress.postcode }} {{ order.customer.defaultAddress.city }}</p>
                        <p>{{ order.customer.defaultAddress.countryName }}</p>
                        <p v-if="order.customer.defaultAddress.phoneNumber" class="mt-2">Tel.: {{ order.customer.defaultAddress.phoneNumber }}</p>
                    </div>
                    <p class="order-body__title">Bestellung</p>
                    <div class="order-body__wrapper">
                        <div v-for="item in order.items" :key="item.id" class="row order-card-item no-gutters">
                            <div class="col-md-1 col-2 pr-2">
                                <img
                                    :src="item.product.image ? item.product.image : '/build/images/product-default-image.png'"
                                    class="w-100">
                            </div>
                            <div class="col-md-5 col-6">
                                <div class="item-description">{{ item.product.name }}</div>
                            </div>
                            <div class="col-2 d-none d-md-flex justify-content-end">
                                <div class="item-price">{{ item.itemPriceGross|formatPrice }}</div>
                            </div>
                            <div class="col-2 d-flex justify-content-end">
                                <div class="item-quantity">{{ item.quantity }} Stk.</div>
                            </div>
                            <div class="col-2 d-flex justify-content-end">
                                <div class="item-total-price">{{ item.totalGross|formatPrice }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="order.orderState != 'complete'" class="modal-footer incoming-order-footer d-block">
                    <p class="order-footer__title">Status ändern</p>
                    <div class="row no-gutters">
                        <div v-if="order.shippingState === 'ready'" class="col-12">
                            <button
                                @click="setStatus('ready')"
                                type="button"
                                class="btn btn-secondary is--ready-for-delivery"
                                data-dismiss="modal"
                            >
                                Zur Abholung bereit
                            </button>
                        </div>
                        <div v-if="order.shippingState === 'partially_shipped'" class="col-12">
                            <button
                                @click="setStatus('completed')"
                                type="button"
                                class="btn btn-secondary is--order-close"
                                data-dismiss="modal"
                            >
                                Abgeschlossen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'BrkuOrderModal',
    props: {
        order: {
            type: Object,
            default: null
        },
        orderStateAction: {
            type: String,
            default: null
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        setStatus(status) {
            axios.post(this.orderStateAction, {
                orderId: this.order.id,
                status: status
            }).then((res) => {
                this.$emit('set-data', res.data);
            })
        }
    }
}
</script>