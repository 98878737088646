<template>
    <div class="search-overlay d-flex flex-column">
        <div class="search-overlay__backdrop animate__animated animate__slideInDown animate__faster" @click="close"></div>
        <div class="search-overlay__input-container position-relative animate__animated animate__slideInDown animate__fast">
            <span @click="close" class="close-wrapper position-absolute d-md-flex align-items-center d-none">
                <p class="close-text pr-2">{{ $t('site.ui.close') }}</p>
                <i class="fa-solid fa-xmark close-icon"></i>
            </span>
            <div class="search-overlay__container">
                <div class="search-field d-flex align-items-center position-relative">
                    <i class="fa-solid fa-magnifying-glass search-icon position-absolute"></i>
                    <input
                        v-model="searchQuery"
                        class="form-control form-control-lg search-input"
                        type="text"
                        placeholder="Suchbegriff eingeben"
                        @input="search"
                    >
                    <i v-if="loading" class="bk-spin fa-solid fa-circle-notch search-loading-icon"></i>
                </div>
            </div>
        </div>
        <div v-if="results.length == 0 && searchQuery.length > 0 && loading == false" class="search-overlay__results-container flex-grow-1">
            <div class="search-overlay__container">
                {{ $t('site.ui.no_search_result_for') }} "{{searchQuery}}"
            </div>
        </div>
        <div v-if="results.length > 0" class="search-overlay__results-container flex-grow-1">
            <div class="search-overlay__container">
                <div class="result-text">
                    {{ resultsCount }} Ergebnisse für "{{ searchQuery }}"
                </div>
                <a :href="result.url" v-for="result in results" :key="result.id" class="product-item animate__animated animate__fadeIn animate__fast">
                    <div class="row">
                        <div class="col-2">
                            <img class="item-image" :src="result.image ? result.image : '/build/images/product-default-image.png'" />
                        </div>
                        <div class="col-10 d-flex align-items-center">
                            <div class="item-description">
                                {{ result.name }}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchQuery: '',
            loading: false,
            results: [],
            resultsCount: 0
        }
    },
    props: {
        searchAction: {
            type: String,
            default: null
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        debounceSearch: _.debounce(function () {
            this.loading = true;
            axios.get(this.searchAction + '?query=' + this.searchQuery)
                .then((response) => {
                    this.loading = false;
                    this.resultsCount = response.data.total;
                    this.results = response.data.products;
                })
                .catch((error) => {
                    console.log(error);
                })
        }, 500),
        search() {
            this.loading = true;
            this.debounceSearch();
        }
    }
}
</script>