var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass:
        "newsletter-wrapper d-block d-md-flex w-100 align-items-center",
      attrs: { method: "post" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _vm.isFooter
        ? _c("label", { staticClass: "newsletter__label is--bg-dark" }, [
            _vm._v(_vm._s(_vm.$t("newsletter.label"))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-grow-0 flex-md-grow-1" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email",
            },
          ],
          class: [
            "form-control has--box-shadow mb-0",
            { "is--bg-dark": _vm.isFooter },
          ],
          attrs: {
            type: "email",
            placeholder: _vm.$t("newsletter.email"),
            required: "",
          },
          domProps: { value: _vm.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.email = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "btn btn-custom btn-outline-primary d-flex align-items-center justify-content-center newsletter__button",
        attrs: { type: "submit" },
      },
      [_c("i", { staticClass: "fa-solid fa-arrow-right" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }