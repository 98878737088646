<template>
    <div class="image-slider-section position-relative">
        <div class="image-slider">
            <div class="image-slider__slides-wrapper">
                <b-carousel
                    v-model="slide"
                    :interval="0"
                    fade
                    :no-hover-pause="true"
                    ref="imageSlider"
                >
                    <slot name="slides"></slot>
                </b-carousel>
            </div>
        </div>
        <div class="slider-navigation-arrow is--white arrow-xl is--left" @click="$refs.imageSlider.prev()">
            <i class="fa-solid fa-arrow-left slider-navigation-arrow__icon"></i>
        </div>
        <div class="slider-navigation-arrow is--white arrow-xl is--right" @click="$refs.imageSlider.next()">
            <i class="fa-solid fa-arrow-right slider-navigation-arrow__icon"></i>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        slide: 0
    })
}
</script>