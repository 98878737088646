var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-overlay d-flex flex-column" }, [
    _c("div", {
      staticClass:
        "search-overlay__backdrop animate__animated animate__slideInDown animate__faster",
      on: { click: _vm.close },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "search-overlay__input-container position-relative animate__animated animate__slideInDown animate__fast",
      },
      [
        _c(
          "span",
          {
            staticClass:
              "close-wrapper position-absolute d-md-flex align-items-center d-none",
            on: { click: _vm.close },
          },
          [
            _c("p", { staticClass: "close-text pr-2" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.close"))),
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fa-solid fa-xmark close-icon" }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "search-overlay__container" }, [
          _c(
            "div",
            {
              staticClass:
                "search-field d-flex align-items-center position-relative",
            },
            [
              _c("i", {
                staticClass:
                  "fa-solid fa-magnifying-glass search-icon position-absolute",
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery",
                  },
                ],
                staticClass: "form-control form-control-lg search-input",
                attrs: { type: "text", placeholder: "Suchbegriff eingeben" },
                domProps: { value: _vm.searchQuery },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.searchQuery = $event.target.value
                    },
                    _vm.search,
                  ],
                },
              }),
              _vm._v(" "),
              _vm.loading
                ? _c("i", {
                    staticClass:
                      "bk-spin fa-solid fa-circle-notch search-loading-icon",
                  })
                : _vm._e(),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.results.length == 0 &&
    _vm.searchQuery.length > 0 &&
    _vm.loading == false
      ? _c(
          "div",
          { staticClass: "search-overlay__results-container flex-grow-1" },
          [
            _c("div", { staticClass: "search-overlay__container" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("site.ui.no_search_result_for")) +
                  ' "' +
                  _vm._s(_vm.searchQuery) +
                  '"\n        '
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.results.length > 0
      ? _c(
          "div",
          { staticClass: "search-overlay__results-container flex-grow-1" },
          [
            _c(
              "div",
              { staticClass: "search-overlay__container" },
              [
                _c("div", { staticClass: "result-text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.resultsCount) +
                      ' Ergebnisse für "' +
                      _vm._s(_vm.searchQuery) +
                      '"\n            '
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.results, function (result) {
                  return _c(
                    "a",
                    {
                      key: result.id,
                      staticClass:
                        "product-item animate__animated animate__fadeIn animate__fast",
                      attrs: { href: result.url },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _c("img", {
                            staticClass: "item-image",
                            attrs: {
                              src: result.image
                                ? result.image
                                : "/build/images/product-default-image.png",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-10 d-flex align-items-center" },
                          [
                            _c("div", { staticClass: "item-description" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(result.name) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }