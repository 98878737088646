<template>
    <div>
        <div class="login-modal">
            <div class="login-modal__inner position-relative">
                <div class="triangle-up position-absolute"></div>
            </div>
            <form
                method="post"
                @submit.prevent="login"
            >
                <label class="login-title d-md-none d-flex">{{ $t('site.form.login.title') }}</label>
                <div class="login-form">
                    <div class="form-group">
                        <input v-model="email" type="text" name="email" required="required" class="input-card"
                               :placeholder="$t('site.form.login.email')"/>
                    </div>
                    <div class="form-group">
                        <input v-model="password" type="password" name="_password" required="required"
                               class="input-card" :placeholder="$t('site.form.login.password')"/>
                    </div>
                </div>
                <b-form-checkbox v-model="rememberMe">{{ $t('site.form.login.remember_me') }}</b-form-checkbox>
                <b-button type="submit" variant="primary" class="btn-custom w-100 my-3">{{
                        $t('site.form.login.button')
                    }}
                </b-button>
                <div class="login-item d-flex justify-content-between">
                    <a class="item-pw-reset" :href="resetPasswordUrl">{{ $t('site.ui.reset_password') }}</a>
                    <a class="item-register-reset" :href="registerUrl">{{ $t('site.ui.register') }}</a>
                </div>
            </form>
        </div>
        <div class="login-modal__overlay" @click="close"></div>
    </div>
</template>

<script>
export default {
    props: {
        action: {
            type: String,
            default: null
        },
        resetPasswordUrl: {
            type: String,
            default: null
        },
        registerUrl: {
            type: String,
            default: null
        },
    },
    data: () => ({
        email: null,
        password: null,
        rememberMe: null
    }),
    methods: {
        close() {
            this.$emit('close')
        },
        login() {
            axios.post(this.action, {
                username: this.email,
                password: this.password,
                remember_me: this.rememberMe
            })
                .then(res => res.data)
                .then(res => {
                    this.$emit('show-login-modal', false);
                    this.$emit('set-user', res.user);

                    this.$toast.clear();
                    this.$toast.open(this.$t('site.ui.login.success'));

                    this.$store.state.checkout.url = res.checkoutUrl;
                })
                .catch(err => {
                    this.$emit('show-login-modal', true);

                    let message = this.$t('site.ui.login.error');

                    if (err.response.status === 401) {
                        message = this.$t('site.ui.login.error.invalid_credentials');
                    }

                    this.$toast.clear();
                    this.$toast.open({
                        message: message,
                        type: 'error'
                    });
                });
        }
    }
}
</script>