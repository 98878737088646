var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hero-slider__inner" },
    [
      _c("div", { staticClass: "hero-slider__overlay" }),
      _vm._v(" "),
      _c(
        "b-carousel",
        {
          ref: "slider",
          staticClass: "hero-slider__slides",
          attrs: { interval: 0, "no-hover-pause": true },
          on: { "sliding-end": _vm.setActiveSlideIndex },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        [_vm._t("slides")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hero-slider__site" }, [
        _c("div", { staticClass: "hero-slider__site-wrapper" }, [
          _c("span", {
            staticClass: "hero-slider__site-current-index",
            domProps: {
              textContent: _vm._s(
                _vm.formatLeadingZero(_vm.activeSliderIndex + 1)
              ),
            },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "hero-slider__site-amount",
            domProps: { textContent: _vm._s(_vm.formatLeadingZero(_vm.count)) },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "slider-navigation-arrow arrow-xl is--left",
          on: {
            click: function ($event) {
              return _vm.$refs.slider.prev()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa-solid fa-arrow-left slider-navigation-arrow__icon",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "slider-navigation-arrow arrow-xl is--right",
          on: {
            click: function ($event) {
              return _vm.$refs.slider.next()
            },
          },
        },
        [
          _c("i", {
            staticClass:
              "fa-solid fa-arrow-right slider-navigation-arrow__icon",
          }),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "hero-slider__scroll-down btn btn-link",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.scrollToContent.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "fa-solid fa-angles-down" })]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "progress-bar-wrapper d-none d-xxl-flex" },
      [_c("div", { staticClass: "progress-bar" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }