<template>
    <div>
        <div @click="toggleModal" class="card-body__item mb-3">
            <p v-if="showPickupDate" class="item-time">{{ order.pickUpDate|formatDate }} | {{ order.pickUpTime }} Uhr</p>
            <div class="item-name-wrapper d-flex justify-content-between">
                <p class="item-name">{{ order.customer.firstname }} {{ order.customer.lastname }}</p>
                <p class="item-price">{{ order.baseTotalGross|formatPrice }}</p>
            </div>
            <p v-if="showOrderNumber" class="item-order-number">
                Best.-Nr: {{ order.orderNumber }}
            </p>
            <p v-if="showProductAmount" class="item-order-product">
                {{ order.items.length }} Produkte
            </p>
        </div>
        <brku-order-modal
            @set-data="setData"
            @close="showOrderDetailModal = false"
            :order="order"
            :order-state-action="orderStateAction"
            v-if="showOrderDetailModal"
        ></brku-order-modal>
        <div v-if="showOrderDetailModal" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
    import BrkuOrderModal from "./OrderModal";
    export default {
        name: 'OrderItemCard',
        components: {BrkuOrderModal},
        props: {
            order: {
                type: Object,
                default: () => {}
            },
            showPickupDate: {
                type: Boolean,
                default: false
            },
            showProductAmount: {
                type: Boolean,
                default: false
            },
            showOrderNumber: {
                type: Boolean,
                default: false
            },
            orderStateAction: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                showOrderDetailModal: false
            }
        },
        methods: {
            setData(data) {
                this.$emit('set-data', data);
            },
            toggleModal() {
                this.showOrderDetailModal = !this.showOrderDetailModal;
            }
        }
    }
</script>