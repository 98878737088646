<template>
    <div>
        <button @click="addToWishlist" class="notice-wrapper ml-md-3 ml-0">
            <i v-if="!loading" class="fa-solid fa-clipboard-list notice-heart"></i>
            <i v-if="loading" class="bk-spin fa-solid fa-circle-notch"></i>
            {{ $t('site.ui.notice') }}
        </button>
        <brku-wishlist-modal
            v-if="showModal"
            :products="products"
            :action-remove="actionRemove"
            @close="showModal = false"
            @set-wishlist="setWishlist"
        ></brku-wishlist-modal>
    </div>
</template>

<script>
export default {
    name: 'Wishlist',
    props: {
        actionAdd: {
            type: String,
            default: null
        },
        actionRemove: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            showModal: false,
            loading: false,
            products: []
        }
    },
    methods: {
        setWishlist(payload) {
            this.products = payload;
        },
        addToWishlist() {
            this.loading = true;
            axios.post(this.actionAdd)
                .then((response) => {
                    this.loading = false;
                    this.showModal = true;
                    this.products = response.data.products;

                    this.$store.commit('incrementWishlist', response.data.products.length);
                })
                .catch(() => {
                    this.showModal = false;
                    this.loading = false;
                    this.products = [];
                });
        }
    }
}
</script>