<template>
    <div class="sale-list-item mb-2">
        <div class="sale-list-item__title">
            <div class="row align-items-center no-gutters">
                <div class="col-lg-9 col-7 d-lg-flex d-block">
                    <div class="col-lg-2 col-12">
                        <div class="item-date pl-lg-3">
                            {{ cart.creationDate|moment('DD.MM.Y') }}
                        </div>
                    </div>
                    <div class="col-lg-7 col-12">
                        <div class="item-number">
                            {{ cart.customIdentifier }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-3">
                    <div class="item-price">
                        {{ cart.totalNetFormatted }}
                    </div>
                </div>
                <div class="col-lg-1 col-2">
                    <button
                        :class="['border-0 ml-auto item-btn', {'has--angle-down': !visible, 'has--angle-up': visible}]"
                        v-b-toggle="`breadbasket-${cart.id}`">
                        <i class="fa-solid fa-chevron-down" v-if="visible"></i>
                        <i class="fa-solid fa-chevron-up" v-else></i>
                    </button>
                </div>
            </div>
            <b-collapse class="sale-list-body" :id="`breadbasket-${cart.id}`" v-model="visible">
                <div class="row">
                    <div class="col-12">
                        <div class="body-title">Produkte</div>
                        <div class="row order-card-item no-gutters" v-for="(item, index) in cart.items">
                            <div class="col-8 d-flex align-items-center justify-content-start">
                                <b-img-lazy v-if="item.product.image" class="item-image"
                                            :src="item.product.image"/>
                                <b-img-lazy v-if="!item.product.image" class="item-image"
                                            src="/build/images/product-default-image.png"/>
                                <div class="item-info">
                                    <div class="item-description">
                                        {{ item.product.name }}
                                    </div>
                                    <div class="item-number">
                                        <span v-if="!updateMode">Art. Nr: {{ item.product.sku }}</span>
                                        <span class="product-delete" v-else
                                              @click="removeCartItem(item.id)">{{ $t('site.ui.remove') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 d-none d-md-flex">
                                <div class="item-price">
                                    {{ item.itemNetFormatted }}
                                </div>
                            </div>
                            <div class="col-2 d-flex justify-content-center">
                                <div class="item-quantity" v-if="!updateMode">{{ item.quantity }} Stk.</div>
                                <div v-else>
                                    <brku-quantity-input
                                        @quantity-changed="onQuantityChanged($event, index, item.id)"
                                        :quantity="item.quantity"
                                    ></brku-quantity-input>
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <div class="item-total-price">
                                    {{ item.totalItemNetFormatted }}
                                </div>
                            </div>
                        </div>
                        <div class="row order-card-item" v-if="updateMode && !quickOrderMode">
                            <div class="col-8 d-flex align-items-center ">
                                <p class="mb-0 mr-2 font-weight-bold">Produkt hinzufügen</p>
                                <vue-select :options="products"
                                          label="name"
                                          class="flex-grow-1"
                                          v-model="selectedProduct"
                                          @search="fetchProductOptions">
                                    <template v-slot:option="option">
                                        <b-img-lazy :src="option.cartImage"
                                                    style="height: 30px; width: 30px; object-fit: cover"/>
                                        {{ option.name }} ({{ option.sku }})
                                    </template>
                                </vue-select>
                            </div>
                            <div class="col-4">
                                <button class="btn btn-outline-dark btn-sm" @click="addProduct"
                                        :disabled="!selectedProduct">
                                    Hinzufügen
                                </button>
                            </div>
                        </div>
                        <div class="row no-gutters order-card-result">
                            <div class="col-md-8 col-3"></div>
                            <div class="col-md-4 col-9">
                                <div class="total-price d-flex justify-content-between align-items-center">
                                    <p class="total-price__text">Gesamtsumme (ohne Versand)</p>
                                    <p class="total-price__sum">{{ cart.subtotalNetFormatted }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrapper d-lg-flex d-block align-items-center mt-4">
                            <button class="btn btn-custom btn-outline-primary mr-auto" v-if="quickOrderMode || updateMode"
                                    @click="updateMode = false; quickOrderMode = false">
                                Zurück
                            </button>
                            <button class="btn btn-link delete-order-btn pl-3 text-decoration-none"
                                    v-if="!quickOrderMode" @click="deleteBreadbasket">
                                <i class="fa-solid fa-trash btn-delete__icon"></i>
                                Brotkorb löschen
                            </button>
                            <button class="btn btn-custom btn-primary has--icon-arrow-right ml-auto" v-if="!updateMode && !quickOrderMode"
                                    @click="updateMode = !updateMode">
                                Brotkorb bearbeiten
                            </button>
                            <button class="btn btn-custom btn-primary has--icon-arrow-right ml-auto" v-if="!quickOrderMode"
                                    @click="updateMode = true; quickOrderMode = true">
                                Schnelle Bestellung
                            </button>
                            <button class="btn btn-custom btn-primary has--icon-arrow-right ml-auto" v-if="quickOrderMode"
                                    @click="quickOrderCheckout">
                                Schnellbestellung zur Kasse
                            </button>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VueSelect from 'vue-select'
import _ from 'lodash'
import 'vue-select/dist/vue-select.css';

export default {
    components: {
      VueSelect
    },
    props: {
        cartInput: {
            type: Object,
            default: {}
        },
        productsAction: {
            type: String,
            default: ''
        },
        addItemAction: {
            type: String,
            default: ''
        },
        deleteAction: {
            type: String,
            default: ''
        },
        removeAction: {
            type: String,
            default: ''
        },
        updateAction: {
            type: String,
            default: ''
        },
        quickOrderAction: {
            type: String,
            default: ''
        },
        checkoutAction: {
            type: String,
            default: ''
        },
        token: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        cart: null,
        loading: false,
        updateMode: false,
        quickOrderMode: false,
        visible: false,
        products: [],
        selectedProduct: null
    }),
    created() {
        this.cart = this.cartInput
    },
    computed: {
        items() {
            let items = [];

            this.cart.items.map((item) => {
                items.push({
                    'quantity': item.quantity
                });
            });

            return items;
        }
    },
    methods: {
        quickOrderCheckout() {
            this.loading = true;

            axios.post(this.quickOrderAction, {
                coreshop_cart: {
                    items: this.items,
                    _token: this.token
                },
                cart: this.cart.id
            })
                .then(res => res.data)
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open({
                        message: this.$t(res.message),
                        type: res.state
                    });

                    window.location.href = this.checkoutAction;
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast.clear();
                    this.$toast.error(this.$t('site.ui.breadbasket.error'));
                });
        },
        fetchProductOptions(search, loading) {
            if (search.length) {
                loading(true);
                this.doProductSearch(loading, search, this);
            }
        },
        doProductSearch: _.debounce((loading, search, vm) => {
            axios.get(`${vm.productsAction}?q=${escape(search)}`)
                .then(res => {
                    vm.products = res.data.products;
                    loading(false);
                });
        }, 350),
        deleteBreadbasket() {
            axios.delete(this.deleteAction).then(res => {
                location.reload();
            }).catch(() => {
                this.$toast.clear();
                this.$toast.error(this.$t('site.ui.breadbasket.error'));
            });
        },
        addProduct() {
            axios.post(this.addItemAction, {
                cart: this.cart.id,
                product: this.selectedProduct.id,
                coreshop_add_to_cart: {
                    cartItem: {
                        quantity: this.selectedProduct.minimumQuantityToOrder ? this.selectedProduct.minimumQuantityToOrder : 1
                    }
                }
            })
                .then(res => {
                    this.selectedProduct = null;
                    this.cart = res.data.cart;
                })
                .catch(() => {
                    this.$toast.clear();
                    this.$toast.error(this.$t('site.ui.breadbasket.error'));
                });
        },
        updateBreadbasket() {
            this.loading = true;

            axios.post(this.updateAction, {
                coreshop_cart: {
                    items: this.items,
                    _token: this.token
                },
                cart: this.cart.id
            })
                .then(res => res.data)
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open({
                        message: this.$t(res.message),
                        type: res.state
                    });

                    this.cart = res.cart;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        removeCartItem(cartItemId) {
            this.loading = true;

            axios.post(this.removeAction, {
                cartItem: cartItemId,
                cart: this.cart.id
            })
                .then(res => res.data)
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open(res.message);

                    this.cart = res.cart;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        onQuantityChanged(value, index) {
            if (!this.loading) {
                this.items[index].quantity = value;
                this.updateBreadbasket()
            }
        }
    }
}
</script>