import Vue from 'vue';

import CustomerLogin from './CustomerLogin';
import LoginTrigger from './LoginTrigger';
import ResetPassword from './ResetPassword';
import SetNewPassword from './SetNewPassword';

Vue.component('brku-customer-login', CustomerLogin);
Vue.component('brku-login-trigger', LoginTrigger);
Vue.component('brku-reset-password', ResetPassword);
Vue.component('brku-new-password', SetNewPassword);