var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "local-delivery" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "process-step__sub-title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "site.ui.checkout.scheduled_local_delivery.start_date"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "datepicker d-flex" },
              [
                _c(
                  "button",
                  {
                    staticClass: "datepicker__arrow is--left d-none d-md-block",
                    attrs: { type: "button" },
                    on: { click: _vm.prevDay },
                  },
                  [_c("i", { staticClass: "fa-solid fa-angle-left" })]
                ),
                _vm._v(" "),
                _c("b-form-datepicker", {
                  attrs: {
                    min: _vm.minDate.toDate(),
                    "date-format-options": {
                      weekday: "short",
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    },
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function ($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate",
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "datepicker__arrow is--right d-none d-md-block",
                    attrs: { type: "button" },
                    on: { click: _vm.nextDay },
                  },
                  [_c("i", { staticClass: "fa-solid fa-angle-right" })]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "process-step__sub-title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("site.ui.checkout.scheduled_local_delivery.end_date")
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "datepicker d-flex" },
              [
                _c(
                  "button",
                  {
                    staticClass: "datepicker__arrow is--left d-none d-md-block",
                    attrs: { type: "button" },
                    on: { click: _vm.prevDay },
                  },
                  [_c("i", { staticClass: "fa-solid fa-angle-left" })]
                ),
                _vm._v(" "),
                _c("b-form-datepicker", {
                  attrs: {
                    min: _vm.minDate.toDate(),
                    "date-format-options": {
                      weekday: "short",
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    },
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function ($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate",
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "datepicker__arrow is--right d-none d-md-block",
                    attrs: { type: "button" },
                    on: { click: _vm.nextDay },
                  },
                  [_c("i", { staticClass: "fa-solid fa-angle-right" })]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "process-step__sub-title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t("site.ui.checkout.local_delivery.shipping_address")
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("b-form-select", {
                attrs: { options: _vm.shippingAddressOptions },
                model: {
                  value: _vm.selectedShippingAddress,
                  callback: function ($$v) {
                    _vm.selectedShippingAddress = $$v
                  },
                  expression: "selectedShippingAddress",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "process-step__sub-title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("site.ui.checkout.local_delivery.weekdays")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-form-checkbox-group",
                {
                  attrs: { name: "weekdays" },
                  model: {
                    value: _vm.weekday,
                    callback: function ($$v) {
                      _vm.weekday = $$v
                    },
                    expression: "weekday",
                  },
                },
                _vm._l(_vm.weekdays, function (item) {
                  return _c(
                    "b-form-checkbox",
                    { key: item.value, attrs: { value: item.value } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.label) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center my-2 my-md-4" },
        [
          _c(
            "b-button",
            {
              staticClass: "btn-custom",
              attrs: { type: "submit", variant: "primary" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("site.ui.checkout.continue")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        attrs: { name: "coreshop[recurringOrderStartDate]", type: "hidden" },
        domProps: { value: _vm.formattedDate(_vm.startDate) },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: "coreshop[recurringOrderEndDate]", type: "hidden" },
        domProps: { value: _vm.formattedDate(_vm.endDate) },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: "coreshop[shippingAddress]", type: "hidden" },
        domProps: { value: _vm.shippingAddress },
      }),
      _vm._v(" "),
      _vm._l(_vm.weekday, function (selected) {
        return _c("input", {
          attrs: { name: "coreshop[weekdays][]", type: "hidden" },
          domProps: { value: selected },
        })
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: "coreshop[interval]", type: "hidden" },
        domProps: { value: _vm.interval },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }