<template>
    <div class="container mb-5">
        <form @submit.prevent="save">
            <p class="form-title pt-3">
                Persönliche Daten
            </p>
            <div class="row mb-3 no-md-gutters">
                <brku-account-input required v-model="scopeCustomer.firstname" :label="$t('site.form.firstname')"/>
                <brku-account-input required v-model="scopeCustomer.lastname" :label="$t('site.form.lastname')"/>
                <brku-account-input required type="email" v-model="scopeCustomer.email" :label="$t('site.form.email')"/>
            </div>

            <p class="form-title pt-3">
                Lieferadresse
            </p>
            <div class="d-flex mb-3">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input v-model="hideInvoiceAddress" id="showInvoiceAddress" type="checkbox"
                               class="custom-control-input">
                        <label for="showInvoiceAddress" class="custom-control-label">Rechnungsadresse ist
                            Lieferadresse</label>
                    </div>
                </div>
            </div>
            <div class="row mb-3 no-md-gutters">
                <brku-account-input required v-model="scopeShippingAddress.firstname"
                                    :label="$t('site.form.firstname')"/>
                <brku-account-input required v-model="scopeShippingAddress.lastname" :label="$t('site.form.lastname')"/>
                <brku-account-input required v-model="scopeShippingAddress.street" :label="$t('site.form.street')"/>
                <brku-account-input required v-model="scopeShippingAddress.number" :label="$t('site.form.number')"/>
                <brku-account-input required wrapper-class="col-md-2 col-12" v-model="scopeShippingAddress.postcode"
                                    :label="$t('site.form.postcode')"/>
                <brku-account-input required wrapper-class="col-md-4 col-12" v-model="scopeShippingAddress.city"
                                    :label="$t('site.form.city')"/>
                <brku-account-select required :options="countries" v-model="scopeShippingAddress.country"
                                     :label="$t('site.form.country')"/>
            </div>

            <p v-if="!hideInvoiceAddress" class="form-title pt-3">
                Rechnungsadresse
            </p>
            <div v-if="!hideInvoiceAddress" class="row mb-3 no-md-gutters">
                <brku-account-input required v-model="scopeInvoiceAddress.firstname"
                                    :label="$t('site.form.firstname')"/>
                <brku-account-input required v-model="scopeInvoiceAddress.lastname" :label="$t('site.form.lastname')"/>
                <brku-account-input required v-model="scopeInvoiceAddress.street" :label="$t('site.form.street')"/>
                <brku-account-input required v-model="scopeInvoiceAddress.number" :label="$t('site.form.number')"/>
                <brku-account-input required wrapper-class="col-md-2 col-12" v-model="scopeInvoiceAddress.postcode"
                                    :label="$t('site.form.postcode')"/>
                <brku-account-input required wrapper-class="col-md-4 col-12" v-model="scopeInvoiceAddress.city"
                                    :label="$t('site.form.city')"/>
                <brku-account-select :options="countries" v-model="scopeInvoiceAddress.country"
                                     :label="$t('site.form.country')"/>
            </div>

            <div v-if="scopeAddresses.length > 0" class="d-flex justify-content-between align-items-center">
                <p class="form-title pt-3">
                    Adressen
                </p>
                <!--
                <button class="btn btn-custom btn-link">+ Adresse erstellen</button>
                -->
            </div>

            <div v-if="scopeAddresses.length > 0" class="row mb-3 no-md-gutters">
                <address-item
                    v-for="address in scopeAddresses"
                    :address="address"
                    :key="address.id"
                    :delete-address-action="deleteAddressAction"
                    :update-invoice-address-action="updateInvoiceAddressAction"
                    :update-shipping-address-action="updateShippingAddressAction"
                    :shipping-address="scopeShippingAddress"
                    :invoice-address="scopeInvoiceAddress"
                    @set-addresses="setAddresses"
                    @set-address-data="setAddressData"
                ></address-item>
            </div>

            <div class="mt-3 row">
                <div class="col-md-6 col-12 account-information__btn d-md-flex d-block align-items-center">
                    <button type="submit" class="btn btn-custom btn-primary has--icon-arrow-right w-100 mb-3 mb-md-0">
                        änderung
                        speichern
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import AddressItem from "./AddressItem";

export default {
    name: 'CustomerPersonalData',
    components: {AddressItem},
    props: {
        customer: {
            type: Object,
            default: () => {
            }
        },
        updateInvoiceAddressAction: {
            type: String,
            default: null
        },
        updateShippingAddressAction: {
            type: String,
            default: null
        },
        deleteAddressAction: {
            type: String,
            default: null
        },
        action: {
            type: String,
            default: null
        },
        csrfToken: {
            type: String,
            default: null
        },
        countries: {
            type: Array,
            default: () => []
        },
        addresses: {
            type: Array,
            default: () => []
        },
        shippingAddress: {
            type: Object,
            default: () => {
            }
        },
        invoiceAddress: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            hideInvoiceAddress: false,
            scopeCustomer: this.customer,
            scopeAddresses: this.addresses,
            scopeShippingAddress: this.shippingAddress,
            scopeInvoiceAddress: this.invoiceAddress,
        }
    },
    mounted() {
        this.hideInvoiceAddress = this.invoiceAddress.id == 0;
    },
    methods: {
        setAddresses(payload) {
            this.scopeAddresses = payload;
        },
        setAddressData(payload) {
            if (payload.shippingAddress != null) {
                this.scopeShippingAddress = payload.shippingAddress;
            }
            if (payload.invoiceAddress != null) {
                this.scopeInvoiceAddress = payload.invoiceAddress;
            }

            this.hideInvoiceAddress = this.scopeInvoiceAddress.id == this.scopeShippingAddress.id;
        },
        save() {
            let customer = this.scopeCustomer;

            const email = this.scopeCustomer.email;

            customer.email = {
                first: email,
                second: email
            }

            axios.post(this.action, {
                _token: this.csrfToken,
                createInvoiceAddress: !this.hideInvoiceAddress,
                customer: customer,
                shippingAddress: this.scopeShippingAddress,
                invoiceAddress: this.scopeInvoiceAddress
            })
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open(this.$t('site.toast.saved_successfully'));

                    this.scopeAddresses = res.data.addresses;
                    this.scopeCustomer.email = email;
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(this.$t('site.toast.error'));

                    this.scopeCustomer.email = email;
                });
        }
    }
}
</script>