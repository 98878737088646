<template>
    <div class="local-delivery">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="process-step__sub-title">{{
                            $t('site.ui.checkout.local_delivery.delivery_date')
                        }}</label>
                    <div class="datepicker d-flex">
                        <button type="button" class="datepicker__arrow is--left d-none d-md-block" @click="prevDay">
                            <i class="fa-solid fa-angle-left"></i>
                        </button>
                        <b-form-datepicker
                            v-model="selectedDate"
                            :min="minDate"
                            :date-format-options="{ weekday: 'short', day: '2-digit', month: 'long', year: 'numeric' }"
                        ></b-form-datepicker>
                        <button type="button" class="datepicker__arrow is--right d-none d-md-block" @click="nextDay">
                            <i class="fa-solid fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="process-step__sub-title">{{
                            $t('site.ui.checkout.local_delivery.shipping_address')
                        }}</label>
                    <div class="address" v-html="shippingAddress"></div>
                </div>
                <div class="form-group">
                    <label class="process-step__sub-title">
                        {{ $t('site.ui.checkout.local_delivery.shipping_costs') }}
                    </label>
                    <p v-if="cart.shippingGross > 0">{{ cart.shippingGrossFormatted }}</p>
                    <p v-else>{{ $t('site.ui.shared.free_shipping') }}</p>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center my-2 my-md-4">
            <b-button type="submit" variant="primary" class="btn-custom" :disabled="!valid">
                {{ $t('site.ui.checkout.continue') }}
            </b-button>
        </div>
        <input name="coreshop[deliveryDate]" :value="formattedDate" type="hidden"/>
    </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from 'vuex';

export default {
    props: {
        earliestDeliveryDate: {
            type: String,
            required: true
        },
        shippingAddress: {
            type: String,
            required: true
        }
    },
    data: () => ({
        selectedDate: null,
        minDate: null
    }),
    created() {
        this.minDate = moment(this.earliestDeliveryDate).toDate();
        this.selectedDate = this.minDate
    },
    computed: {
        ...mapGetters([
            'cart'
        ]),
        formattedDate() {
            if (this.selectedDate) {
                return moment(this.selectedDate).format('YYYY-MM-DD')
            }

            return null;
        },
        valid() {
            return this.selectedDate != null;
        },
        currentDate() {
            return moment()
        },
        options() {
            let options = this.shippingAddresses.map(item => {
                return {
                    value: item.value,
                    text: item.label
                }
            })

            options.unshift({
                value: null,
                text: this.$t('site.ui.checkout.choose_address')
            })

            return options;
        }
    },
    methods: {
        nextDay() {
            this.selectedDate = moment(this.selectedDate).add(1, 'day').toDate();
        },
        prevDay() {
            const minDate = moment(this.earliestDeliveryDate);
            const currentDate = moment(this.selectedDate.toISOString());
            const newDate = currentDate.clone().subtract(1, 'day');

            const diff = newDate.diff(minDate, 'day');

            if (diff < 0) {
                return;
            }

            this.selectedDate = newDate.toDate();
        }
    }
}
</script>

<style scoped>

</style>