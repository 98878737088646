<template>
    <div class="navbar-cart d-flex justify-content-center align-items-center" @click="toggleState" ref="cartPreview">
        <p class="position-relative">
            <i class="fa-solid fa-cart-shopping navbar-cart__icon"></i>
            <span v-if="$store.state.cart.content"
                  class="navbar-cart__icon-badge d-flex align-items-center justify-content-center">
                {{ $store.state.cart.content.items.length }}
            </span>
        </p>
        <p class="navbar-cart__text d-none d-lg-block">{{ $t('site.ui.cart.preview.label') }}</p>
    </div>
</template>

<script>
export default {
    mounted() {
        const {cartPreview} = this.$refs;

        const viewportOffset = cartPreview.getBoundingClientRect();

        this.$store.commit('setCartPreviewPosition', {
            left: viewportOffset.left,
            top: viewportOffset.bottom
        })

        this.$store.commit('setCartPreviewDimensions', {
            width: cartPreview.clientWidth,
            height: cartPreview.clientHeight
        })
    },
    methods: {
        toggleState() {
            this.$store.commit('toggleOpenState');
        }
    }
}
</script>