<template>
    <div class="product-filter__item"
         v-if="condition.values.length > 0"
    >
        <div class="product-filter__options">
            <div class="product-filter__options-wrapper no-collapse">
                <div class="product-filter__option-item">
                    <div class="option-item__content">
                        <b-form-checkbox v-model="selected" :name="filterCondition.fieldName" value="1">{{ filterCondition.label }}</b-form-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseCondition from './BaseCondition';
import {BFormCheckbox} from 'bootstrap-vue';

export default {
    components: {
        BFormCheckbox
    },
    props: {
        condition: {
            type: Object,
            default: null
        },
        currentFilter: {
            type: Object | Array,
            default: null
        }
    },
    data: () => ({
        filterCondition: null,
        selected: null
    }),
    created() {
        if (this.condition.currentValue) {
            this.selected = this.condition.currentValue;
        }

        this.setDefaultFilterCondition();
    },
    methods: {
        setDefaultFilterCondition() {
            this.filterCondition = {
                ...this.condition
            }
        }
    },
    watch: {
        condition: {
            deep: true,
            handler() {
                this.setDefaultFilterCondition();
            }
        },
        currentFilter: {
            deep: true,
            handler(newValue) {
                this.selected = newValue;
            }
        },
        selected(newValue) {
            this.$emit('filter-update', {
                fieldName: this.condition.fieldName,
                values: newValue
            });
        }
    }
}
</script>