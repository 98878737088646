<template>
    <form class="text-center" @submit.prevent="resetPassword">
        <div class="password-reset-form mb-4">
            <div class="password-reset-title text-center">{{ $t('coreshop.ui.password_reset') }}</div>
            <p class="password-reset-text">{{ $t('coreshop.ui.password_reset_description') }}</p>
            <div class="form-group d-flex justify-content-center">
                <input
                    class="form-control input-card"
                    type="email"
                    required
                    v-model="email"
                    :placeholder="$t('coreshop.ui.password_reset_email')"
                />
            </div>
        </div>
        <b-button type="submit" variant="primary" class="btn-custom has--icon-arrow-right mb-2 mx-auto">
            <span v-if="!loading">{{ $t('coreshop.ui.reset') }}</span>
            <span v-if="loading">{{ $t('coreshop.ui.loading') }}</span>
        </b-button>
    </form>
</template>

<script>
export default {
    props: {
        action: {
            type: String,
            default: null
        },
        token: {
            type: String,
            default: null
        }
    },
    data: () => ({
        email: null,
        loading: false
    }),
    methods: {
        resetPassword() {
            this.loading = true;
            axios.post(this.action, {
                email: this.email,
                _token: this.token
            })
                .then(res => res.data)
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open(this.$t('site.ui.password_reset_success'));

                    this.email = null;
                    this.loading = false;

                    setTimeout(() => {
                        window.location.href = res.redirectUrl;
                    }, 1000);
                })
                .catch(err => {
                    this.loading = false;

                    this.$toast.clear();
                    this.$toast.open({
                        message: this.$t('site.ui.password_reset_error'),
                        type: 'error'
                    });
                });
        }
    }
}
</script>