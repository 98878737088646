export const checkout = {
    state: window.checkout,
    mutations: {
        changeBranches(state, payload) {
            state.branches = payload;
        },
        changeCarriers(state, payload) {
            state.carriers = payload;
        },
        changeCheckout(state, payload) {
            state.content = payload;
        }
    }
};