var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "process" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.register.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "registration__content" }, [
          _c("div", { staticClass: "process-container" }, [
            _c(
              "div",
              {
                staticClass:
                  "process-header d-flex align-items-center justify-content-between",
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("site.ui.register")) +
                    "\n                    "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "process-header__link btn btn-link text-decoration-none",
                    attrs: { href: _vm.loginUrl },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("site.ui.login")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "process-step" }, [
              _c("div", { staticClass: "process-step__number" }),
              _vm._v(" "),
              _c("div", { staticClass: "process-step__sub-title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.iam"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "checkbox-card is--hover": true,
                        active: !_vm.isB2B,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onChangeAccountType(false)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "custom-control custom-checkbox d-flex",
                        },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "coreshop_customer_registration_customer_isB2B_0",
                              name: "coreshop_customer_registration[customer][isB2B]",
                              value: "0",
                            },
                            domProps: { checked: !_vm.isB2B },
                            on: {
                              change: function ($event) {
                                return _vm.onChangeAccountType(false)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: {
                                for: "coreshop_customer_registration_customer_isB2B_0",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.$t("site.form.customer.private")) +
                                  "\n                                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "checkbox-card is--hover": true,
                        active: _vm.isB2B,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onChangeAccountType(true)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "custom-control custom-checkbox d-flex",
                        },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "coreshop_customer_registration_customer_isB2B_1",
                              name: "coreshop_customer_registration[customer][isB2B]",
                              value: "1",
                            },
                            domProps: { checked: _vm.isB2B },
                            on: {
                              change: function ($event) {
                                return _vm.onChangeAccountType(true)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: {
                                for: "coreshop_customer_registration_customer_isB2B_1",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.$t("site.form.customer.business")
                                  ) +
                                  "\n                                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "process-step" }, [
              _c("div", { staticClass: "process-step__number" }),
              _vm._v(" "),
              _vm.isB2B
                ? _c("div", { staticClass: "process-step__sub-title" }, [
                    _vm._v(_vm._s(_vm.$t("site.ui.company_information"))),
                  ])
                : _c("div", { staticClass: "process-step__sub-title" }, [
                    _vm._v(_vm._s(_vm.$t("site.ui.personal_data"))),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.salutation,
                            expression: "salutation",
                          },
                        ],
                        staticClass: "form-control input-card",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.salutation = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("site.form.placeholder"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "other" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("site.form.customer.salutation.other")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "mr" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("site.form.customer.salutation.mr"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "mrs" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("site.form.customer.salutation.mrs"))
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isB2B
                  ? _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.company,
                              expression: "company",
                            },
                          ],
                          staticClass: "form-control input-card",
                          attrs: {
                            type: "text",
                            required: "true",
                            placeholder: `${_vm.$t("site.form.company.name")}*`,
                          },
                          domProps: { value: _vm.company },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.company = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.firstname,
                          expression: "firstname",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "text",
                        required: "true",
                        placeholder: `${_vm.$t(
                          "site.form.customer.firstname"
                        )}*`,
                      },
                      domProps: { value: _vm.firstname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.firstname = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lastname,
                          expression: "lastname",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "text",
                        required: "true",
                        placeholder: `${_vm.$t(
                          "site.form.customer.lastname"
                        )}*`,
                      },
                      domProps: { value: _vm.lastname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.lastname = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "input-group form-group" },
                    [
                      _c("vue-tel-input", {
                        attrs: {
                          "input-classes": "form-control input-card",
                          "enabled-country-code": true,
                          "only-countries": _vm.phoneCountriesOptions,
                          placeholder: `${_vm.$t(
                            "site.form.address.phone_number"
                          )}*`,
                          required: true,
                        },
                        model: {
                          value: _vm.phoneNumber,
                          callback: function ($$v) {
                            _vm.phoneNumber = $$v
                          },
                          expression: "phoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.isB2B
                  ? _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.vatIdentificationNumber,
                              expression: "vatIdentificationNumber",
                            },
                          ],
                          staticClass: "form-control input-card",
                          attrs: {
                            type: "text",
                            required: "true",
                            placeholder: `${_vm.$t(
                              "site.form.company.vat_identification_number"
                            )}*`,
                          },
                          domProps: { value: _vm.vatIdentificationNumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.vatIdentificationNumber = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "email",
                        required: "true",
                        placeholder: `${_vm.$t("site.form.customer.email")}*`,
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      attrs: { id: "emailConfirmation", type: "hidden" },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "password",
                        required: "true",
                        placeholder: `${_vm.$t(
                          "site.form.customer.password"
                        )}*`,
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.passwordConfirmation,
                          expression: "passwordConfirmation",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "password",
                        required: "true",
                        placeholder: `${_vm.$t(
                          "site.form.customer.password_confirmation"
                        )}*`,
                      },
                      domProps: { value: _vm.passwordConfirmation },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.passwordConfirmation = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "process-step" }, [
              _c("div", { staticClass: "process-step__number" }),
              _vm._v(" "),
              _c("div", { staticClass: "process-step__sub-title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.invoice_address"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.invoiceAddressStreet,
                          expression: "invoiceAddressStreet",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "text",
                        required: "true",
                        placeholder: `${_vm.$t("site.form.address.street")}*`,
                      },
                      domProps: { value: _vm.invoiceAddressStreet },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.invoiceAddressStreet = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.invoiceAddressNumber,
                          expression: "invoiceAddressNumber",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "text",
                        required: "true",
                        placeholder: `${_vm.$t("site.form.address.number")}*`,
                      },
                      domProps: { value: _vm.invoiceAddressNumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.invoiceAddressNumber = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.invoiceAddressPostcode,
                          expression: "invoiceAddressPostcode",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "text",
                        required: "true",
                        placeholder: `${_vm.$t("site.form.address.postcode")}*`,
                      },
                      domProps: { value: _vm.invoiceAddressPostcode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.invoiceAddressPostcode = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.invoiceAddressCity,
                          expression: "invoiceAddressCity",
                        },
                      ],
                      staticClass: "form-control input-card",
                      attrs: {
                        type: "text",
                        required: "true",
                        placeholder: `${_vm.$t("site.form.address.city")}*`,
                      },
                      domProps: { value: _vm.invoiceAddressCity },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.invoiceAddressCity = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("multiselect", {
                        attrs: {
                          "track-by": "id",
                          label: "name",
                          placeholder: `${_vm.$t(
                            "site.form.address.country"
                          )}*`,
                          options: _vm.countries,
                          "allow-empty": false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "singleLabel",
                            fn: function ({ option }) {
                              return [
                                _c("span", { staticClass: "option__desc" }, [
                                  _c("span", {
                                    class: `flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`,
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "option__title" }, [
                                    _vm._v(_vm._s(option.name)),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "option",
                            fn: function ({ option }) {
                              return [
                                _c("div", { staticClass: "option__desc" }, [
                                  _c("span", {
                                    class: `flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`,
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "option__title" }, [
                                    _vm._v(_vm._s(option.name)),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.invoiceAddressCountry,
                          callback: function ($$v) {
                            _vm.invoiceAddressCountry = $$v
                          },
                          expression: "invoiceAddressCountry",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "process-step" }, [
              _c("div", { staticClass: "process-step__number" }),
              _vm._v(" "),
              _c("div", { staticClass: "process-step__sub-title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.shipping_address"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "custom-control custom-checkbox d-flex" },
                  [
                    _c("input", {
                      staticClass: "custom-control-input",
                      attrs: {
                        id: "invoice_address_is_shipping_address",
                        type: "checkbox",
                      },
                      domProps: { checked: _vm.isInvoiceAddress },
                      on: {
                        change: function ($event) {
                          _vm.isInvoiceAddress = !_vm.isInvoiceAddress
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "invoice_address_is_shipping_address" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "site.form.invoice_address_is_shipping_address"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              !_vm.isInvoiceAddress
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingAddressStreet,
                              expression: "shippingAddressStreet",
                            },
                          ],
                          staticClass: "form-control input-card",
                          attrs: {
                            type: "text",
                            required: "true",
                            placeholder: `${_vm.$t(
                              "site.form.address.street"
                            )}*`,
                          },
                          domProps: { value: _vm.shippingAddressStreet },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.shippingAddressStreet = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingAddressNumber,
                              expression: "shippingAddressNumber",
                            },
                          ],
                          staticClass: "form-control input-card",
                          attrs: {
                            type: "text",
                            required: "true",
                            placeholder: `${_vm.$t(
                              "site.form.address.number"
                            )}*`,
                          },
                          domProps: { value: _vm.shippingAddressNumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.shippingAddressNumber = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingAddressPostcode,
                              expression: "shippingAddressPostcode",
                            },
                          ],
                          staticClass: "form-control input-card",
                          attrs: {
                            type: "text",
                            required: "true",
                            placeholder: `${_vm.$t(
                              "site.form.address.postcode"
                            )}*`,
                          },
                          domProps: { value: _vm.shippingAddressPostcode },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.shippingAddressPostcode = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingAddressCity,
                              expression: "shippingAddressCity",
                            },
                          ],
                          staticClass: "form-control input-card",
                          attrs: {
                            type: "text",
                            required: "true",
                            placeholder: `${_vm.$t("site.form.address.city")}*`,
                          },
                          domProps: { value: _vm.shippingAddressCity },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.shippingAddressCity = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("multiselect", {
                            staticClass: "multiselect-card",
                            attrs: {
                              "track-by": "id",
                              label: "name",
                              placeholder: `${_vm.$t(
                                "site.form.address.country"
                              )}*`,
                              options: _vm.countries,
                              "allow-empty": false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleLabel",
                                  fn: function ({ option }) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "option__desc" },
                                        [
                                          _c("span", {
                                            class: `flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`,
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "option__title" },
                                            [_vm._v(_vm._s(option.name))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "option",
                                  fn: function ({ option }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "option__desc" },
                                        [
                                          _c("span", {
                                            class: `flag-icon flag-icon-squared flag-icon-${option.isoCode.toLowerCase()}`,
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "option__title" },
                                            [_vm._v(_vm._s(option.name))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2858485025
                            ),
                            model: {
                              value: _vm.shippingAddressCountry,
                              callback: function ($$v) {
                                _vm.shippingAddressCountry = $$v
                              },
                              expression: "shippingAddressCountry",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-checkbox d-flex mb-3 mt-3",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newsletterActive,
                          expression: "newsletterActive",
                        },
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "checkbox",
                        id: "coreshop_customer_registration_customer_newsletterActive",
                        value: "1",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.newsletterActive)
                          ? _vm._i(_vm.newsletterActive, "1") > -1
                          : _vm.newsletterActive,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.newsletterActive,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "1",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.newsletterActive = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.newsletterActive = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.newsletterActive = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: {
                          for: "coreshop_customer_registration_customer_newsletterActive",
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t("site.form.customer.newsletter_active")
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "custom-control custom-checkbox d-flex mb-3" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.termsAccepted,
                          expression: "termsAccepted",
                        },
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "checkbox",
                        id: "coreshop_customer_registration_termsAccepted",
                        required: "true",
                        value: "1",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.termsAccepted)
                          ? _vm._i(_vm.termsAccepted, "1") > -1
                          : _vm.termsAccepted,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.termsAccepted,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "1",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.termsAccepted = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.termsAccepted = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.termsAccepted = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: {
                          for: "coreshop_customer_registration_termsAccepted",
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("site.form.terms_accepted")) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6 col-12 mt-3" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-custom btn-primary has--icon-arrow-right w-100",
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("site.form.register.button")) +
                          "\n                            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "coreshop_customer_registration[_token]",
                  },
                  domProps: { value: _vm.token },
                }),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }