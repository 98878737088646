<template>
  <div>
    <gmap-info-window :position="coordinates" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
      <div class="card map-badge__card d-md-flex d-none">
        <a :href="branch.url">
          <img class="card-img-top" :src="branch.image" :alt="branch.name" :title="branch.name">
        </a>
        <div class="card-body position-relative">
          <span class="map-card__title">{{ branch.name }}</span>
          <p class="map-card__sub-title">{{ branch.street }} {{ branch.number }}, {{ branch.postalCode }}
            {{ branch.city }}</p>
          <div v-if="branch.openingTimes.isOpen" class="map-card__opening is--open">
            {{ $t('site.ui.openingTimes.open') }}
          </div>
          <div v-if="!branch.openingTimes.isOpen" class="map-card__opening is--closed">
            {{ $t('site.ui.openingTimes.closed') }}
          </div>
          <div v-if="branch.openingTimes.today !== undefined" class="map-card__open-time">
            <span>{{ $t('site.ui.weekday.' + branch.openingTimes.today.weekDay) }}:</span>
            <span
                v-if="branch.openingTimes.today.afternoonTimeStart && branch.openingTimes.today.morningTimeEnd"
            >
                            {{
                branch.openingTimes.today.morningTimeStart
              }} - {{ branch.openingTimes.today.morningTimeEnd }}<br/>
                            {{
                branch.openingTimes.today.afternoonTimeStart
              }} - {{ branch.openingTimes.today.afternoonTimeEnd }}
                        </span>
            <span
                v-else-if="!branch.openingTimes.today.afternoonTimeStart && !branch.openingTimes.today.afternoonTimeEnd">
                            {{
                branch.openingTimes.today.morningTimeStart
              }} - {{ branch.openingTimes.today.morningTimeEnd }}
                        </span>
            <span v-else>
                            {{
                branch.openingTimes.today.morningTimeStart
              }} - {{ branch.openingTimes.today.afternoonTimeEnd }}
                        </span>
          </div>
        </div>
      </div>
    </gmap-info-window>
    <gmap-marker :position="coordinates" :clickable="true" @click="infoWinOpen = !infoWinOpen"></gmap-marker>
    <!--    <l-icon
            :icon-size="branch.isBakery && branch.isCafe ? iconSizeDouble : iconSize"
            :icon-anchor="branch.isBakery && branch.isCafe ? iconAnchorDouble : iconAnchor"
        >
          <div v-if="branch.isBakery" class="map-badge is&#45;&#45;bakery" :class="{'is&#45;&#45;disabled' : branch.enabled === false}"
               :id="'map-badge-' + branch.id">
            <div class="map-badge__inner position-relative d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-bread-slice"></i>
            </div>
          </div>
          <div v-if="branch.isCafe" class="map-badge is&#45;&#45;cafe" :class="{'is&#45;&#45;disabled' : branch.enabled === false}"
               :id="'map-badge-' + branch.id">
            <div class="map-badge__inner position-relative d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-mug-hot"></i>
            </div>
          </div>
        </l-icon>-->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    branch: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      iconSize: [60, 60],
      iconAnchor: [30, 30],
      iconSizeDouble: [110, 60],
      iconAnchorDouble: [55, 30],
      showDetailCard: false,
      coordinates: {
        lat: parseFloat(this.branch.latitude),
        lng: parseFloat(this.branch.longitude)
      }
    };
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker;

      //check if its the same marker that was selected if yes toggle
      this.infoWinOpen = !this.infoWinOpen;

    }
  }
}
</script>