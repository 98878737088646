var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.condition.values.length > 0
    ? _c("div", { staticClass: "product-filter__item" }, [
        _c("div", { staticClass: "product-filter__options" }, [
          _c(
            "div",
            { staticClass: "product-filter__options-wrapper no-collapse" },
            [
              _c("div", { staticClass: "product-filter__option-item" }, [
                _c(
                  "div",
                  { staticClass: "option-item__content" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          name: _vm.filterCondition.fieldName,
                          value: "1",
                        },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      },
                      [_vm._v(_vm._s(_vm.filterCondition.label))]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }