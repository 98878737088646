<template>
  <div class="wishlist-product">
    <div class="row">
      <div class="col-md-7 col-8 d-flex align-items-center">
        <img class="product-image" :src=" product.image">
        <div class="product-title">
          {{ product.name }}
        </div>
      </div>
      <div class="col-md-5 col-4 d-flex align-items-center justify-content-end">
        <div class="row">
          <div class="col-md-6 col-12 d-flex align-items-center justify-content-end">
            <div class="product-price">
              {{ product.price }}
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex align-items-center justify-content-end">
            <span @click="removeFromWishlist" class="product-delete d-flex">
              {{ $t('site.ui.remove') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'WishlistModalEntry',
    props: {
        actionRemove: {
            type: String,
            default: null
        },
        product: {
            type: Object,
            default: {}
        }
    },
    methods: {
        removeFromWishlist() {
            const action = this.actionRemove.replace("%product", this.product.id)

            axios.post(action)
                .then((response) => {
                    this.$emit('set-wishlist', response.data.products)

                    this.$store.commit('decrementWishlist', response.data.products.length);
                })
                .catch(err => {
                    this.loading = false;
                });
        }
    },
}
</script>