var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-provider-select" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6 offset-md-3" },
        [
          _c("b-form-group", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ ariaDescribedby }) {
                  return _vm._l(_vm.paymentProviders, function (provider) {
                    return _c(
                      "b-form-radio",
                      {
                        staticClass: "provider-item",
                        attrs: {
                          name: "coreshop[paymentProvider]",
                          value: provider.id,
                        },
                        model: {
                          value: _vm.paymentProvider,
                          callback: function ($$v) {
                            _vm.paymentProvider = $$v
                          },
                          expression: "paymentProvider",
                        },
                      },
                      [
                        _c("b-img-lazy", {
                          staticClass: "provider-logo",
                          attrs: { src: provider.logoSrc },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "provider-name" }, [
                          _vm._v(_vm._s(provider.translations[_vm.lang].title)),
                        ]),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center my-2 my-md-4" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-custom",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    disabled: _vm.paymentProvider == null,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("site.ui.checkout.continue")) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }