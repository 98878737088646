var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-item" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-7 col-12 d-flex align-items-center" },
        [
          _vm.item.product.cartImage
            ? _c("b-img-lazy", {
                staticClass: "item-image",
                attrs: { src: _vm.item.product.cartImage },
              })
            : _c("b-img-lazy", {
                staticClass: "item-image",
                attrs: { src: _vm.defaultImage },
              }),
          _vm._v(" "),
          _c("div", { staticClass: "item-info" }, [
            _c("p", { staticClass: "item-meta" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("site.ui.sku_short")) +
                  " " +
                  _vm._s(_vm.product.sku) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "item-title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.product.name) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "item-availability" }, [
              _c("span", { staticClass: "circle circle-green" }),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("site.ui.cart.available")) +
                  "\n                "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-md-5 col-12 d-flex align-items-center justify-content-between",
        },
        [
          _c(
            "div",
            { staticClass: "item-quantity-select" },
            [
              _c("brku-quantity-input", {
                attrs: { quantity: _vm.item.quantity },
                on: {
                  "quantity-changed": function ($event) {
                    return _vm.onQuantityChanged(_vm.item, $event)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "item-remove",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onRemove(_vm.item)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-xmark mr-1" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("site.ui.remove")) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-price-info" }, [
            _c("div", { staticClass: "item-price" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.item.itemGrossFormatted) +
                  " x " +
                  _vm._s(_vm.item.quantity) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-total-price" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.item.totalItemGrossFormatted) +
                  "\n                "
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }