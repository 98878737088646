var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-pagination-nav",
    {
      staticClass: "my-md-5 mt-0 mb-4",
      attrs: {
        align: "center",
        "base-url": "#",
        "hide-goto-end-buttons": true,
        "number-of-pages": _vm.numberOfPages,
      },
      on: { change: _vm.onPaginate },
      model: {
        value: _vm.page,
        callback: function ($$v) {
          _vm.page = $$v
        },
        expression: "page",
      },
    },
    [
      _c("template", { slot: "prev-text" }, [
        _c("span", { staticClass: "page-link pagination-nav left mr-3" }, [
          _c("i", { staticClass: "fa-solid fa-arrow-left" }),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "next-text" }, [
        _c("span", { staticClass: "page-link pagination-nav right ml-3" }, [
          _c("i", { staticClass: "fa-solid fa-arrow-right" }),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }