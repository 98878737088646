import { render, staticRenderFns } from "./CustomerLogin.vue?vue&type=template&id=afb26958"
import script from "./CustomerLogin.vue?vue&type=script&lang=js"
export * from "./CustomerLogin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@7.1.2_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.92.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports