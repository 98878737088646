var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass:
        "login-wrapper d-flex flex-column justify-content-center align-items-center",
      attrs: { href: _vm.url },
    },
    [
      _c("i", { staticClass: "bk-i bk-i-login-mobile login-icon" }),
      _vm._v(" "),
      _c("p", { staticClass: "login-text" }, [
        _vm._v(_vm._s(_vm.$t("site.ui.mobile_nav.login"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }