var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "wishlist-modal" }, [
      _c(
        "div",
        { staticClass: "wishlist-modal__inner position-relative" },
        [
          _c(
            "div",
            {
              staticClass: "close-wrapper position-absolute",
              on: { click: _vm.close },
            },
            [_c("i", { staticClass: "fa-solid fa-xmark close-icon" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "wishlist-title d-flex justify-content-md-center justify-content-start",
            },
            [_vm._v(_vm._s(_vm.$t("site.wishlist.headline")))]
          ),
          _vm._v(" "),
          _vm._l(_vm.products, function (product) {
            return _c("wishlist-modal-entry", {
              key: product.id,
              attrs: {
                product: product.product,
                "action-remove": _vm.removeAction,
              },
              on: { "set-wishlist": _vm.setWishlist },
            })
          }),
          _vm._v(" "),
          _vm.products.length == 0 && _vm.loading == false
            ? _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
                _vm._v(_vm._s(_vm.$t("site.wishlist.empty"))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "d-flex justify-content-center p-3" }, [
                _c("i", { staticClass: "bk-spin fa-solid fa-circle-notch" }),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop fade show",
      on: { click: _vm.close },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }