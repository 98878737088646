var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "product-category-tabs nav-tabs-wrapper position-relative pt-5",
    },
    [
      _c(
        "div",
        { staticClass: "container position-relative" },
        [
          _c(
            "ul",
            {
              staticClass: "nav nav-tabs d-flex justify-content-center",
              attrs: { id: "myTab", role: "tablist" },
            },
            _vm._l(_vm.categories, function (item) {
              return _c("li", { key: item.id, staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    class: { "nav-link": true, active: item.id === _vm.active },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.active = item.id
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.name) +
                        "\n                "
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.totalCount > 0
            ? _c(_vm.compiledHtml, {
                tag: "div",
                staticClass: "keen-slider mt-5",
                attrs: { id: "keen-slider" },
              })
            : _c(
                "b-alert",
                { staticClass: "mt-5", attrs: { show: "", variant: "danger" } },
                [_vm._v(_vm._s(_vm.$t("site.ui.no_items")))]
              ),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "inline-loader has--space-tabs" }, [
                _c("i", { staticClass: "bk-spin fa-solid fa-circle-notch" }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalCount > 0
        ? [
            _c(
              "div",
              {
                class: {
                  arrow: true,
                  "slider-navigation-arrow arrow-xl is--left": true,
                  "is--disabled": _vm.current === 0,
                },
                on: {
                  click: function ($event) {
                    return _vm.slider.prev()
                  },
                },
              },
              [
                _c("i", {
                  staticClass:
                    "fa-solid fa-arrow-left slider-navigation-arrow__icon",
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: {
                  arrow: true,
                  "slider-navigation-arrow arrow-xl is--right": true,
                  "is--disabled": _vm.current === _vm.totalCount - 1,
                },
                on: {
                  click: function ($event) {
                    return _vm.slider.next()
                  },
                },
              },
              [
                _c("i", {
                  staticClass:
                    "fa-solid fa-arrow-right slider-navigation-arrow__icon",
                }),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }