export const wishlist = {
    state: window.wishlist,
    mutations: {
        incrementWishlist(state, payload) {
            state.counter = payload;
        },
        decrementWishlist(state, payload) {
            state.counter = payload;
        }
    }
};