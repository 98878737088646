var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "wishlist-modal" }, [
      _c(
        "div",
        { staticClass: "wishlist-modal__inner position-relative" },
        [
          _c(
            "div",
            {
              staticClass: "close-wrapper position-absolute",
              on: { click: _vm.close },
            },
            [_c("i", { staticClass: "fa-solid fa-xmark close-icon" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "wishlist-title d-flex justify-content-md-center justify-content-start",
            },
            [_vm._v(_vm._s("Willst du noch was mitnehmen"))]
          ),
          _vm._v(" "),
          _vm._l(_vm.products, function (product) {
            return _c("div", [
              _c("img", { attrs: { src: product.image } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(product.name))]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(product.price))]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#" } }, [_vm._v("In den Brotkorb")]),
            ])
          }),
          _vm._v(" "),
          _vm.products.length == 0
            ? _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
                _vm._v(_vm._s(_vm.$t("site.wishlist.empty"))),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-backdrop fade show" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }