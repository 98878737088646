var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "local-pick-up" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "process-step__sub-title" }, [
        _vm._v(_vm._s(_vm.$t("site.ui.local_pick_up.branch"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6 order-last order-md-first mt-4 mt-md-0" },
          [
            _c("b-form-group", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ ariaDescribedby }) {
                    return _vm._l(_vm.branches, function (item) {
                      return _c(
                        "b-form-radio",
                        {
                          attrs: { name: "coreshop[branch]", value: item.id },
                          model: {
                            value: _vm.branch,
                            callback: function ($$v) {
                              _vm.branch = $$v
                            },
                            expression: "branch",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.name) +
                              "\n                    "
                          ),
                        ]
                      )
                    })
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [_c("brku-map", { attrs: { branches: [_vm.selectedBranchObject] } })],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "process-step__sub-title" }, [
        _vm._v(_vm._s(_vm.$t("site.ui.local_pick_up.date"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "datepicker d-flex" },
        [
          _c(
            "button",
            {
              staticClass: "datepicker__arrow is--left d-none d-md-block",
              attrs: { type: "button" },
              on: { click: _vm.prevDay },
            },
            [_c("i", { staticClass: "fa-solid fa-angle-left" })]
          ),
          _vm._v(" "),
          _c("b-form-datepicker", {
            attrs: {
              min: _vm.minDate,
              "date-format-options": {
                weekday: "short",
                day: "2-digit",
                month: "long",
                year: "numeric",
              },
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "datepicker__arrow is--right d-none d-md-block",
              attrs: { type: "button" },
              on: { click: _vm.nextDay },
            },
            [_c("i", { staticClass: "fa-solid fa-angle-right" })]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("div", { staticClass: "process-step__sub-title" }, [
          _vm._v(_vm._s(_vm.$t("site.ui.local_pick_up.time"))),
        ]),
        _vm._v(" "),
        _c("v-time-picker", {
          attrs: {
            format: "24hr",
            "allowed-minutes": _vm.allowedStep,
            "allowed-hours": _vm.allowedHours,
          },
          model: {
            value: _vm.time,
            callback: function ($$v) {
              _vm.time = $$v
            },
            expression: "time",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center my-2 my-md-4" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-custom",
            attrs: { type: "submit", variant: "primary", disabled: !_vm.valid },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("site.ui.checkout.continue")) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.date
      ? _c("input", {
          attrs: { name: "coreshop[pickUpDate]", type: "hidden" },
          domProps: { value: _vm.formattedDate },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.time
      ? _c("input", {
          attrs: { name: "coreshop[pickUpTime]", type: "hidden" },
          domProps: { value: _vm.time },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      attrs: { name: "coreshop[branch]", type: "hidden" },
      domProps: { value: _vm.branch },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }