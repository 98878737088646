var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal d-block", attrs: { tabindex: "-1" } },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content incoming-order" }, [
          _c(
            "div",
            {
              staticClass:
                "modal-header incoming-order-header position-relative has--divider-bottom",
            },
            [
              _c("div", { staticClass: "row w-100" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "order-header__title" }, [
                    _vm._v("Bestellnummer"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "order-header__sub-title" }, [
                    _vm._v(_vm._s(_vm.order.orderNumber)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "order-header__title" }, [
                    _vm._v("Abholung"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "order-header__sub-title" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm._f("formatDate")(_vm.order.pickUpDate)) +
                        " | " +
                        _vm._s(_vm.order.pickUpTime) +
                        " Uhr\n                        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "modal-close-wrapper d-flex position-absolute align-items-center",
                  on: { click: _vm.close },
                },
                [
                  _c("div", { staticClass: "close-text" }, [
                    _vm._v("Schliessen"),
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa-solid fa-xmark close-icon" }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body incoming-order-body" }, [
            _c("p", { staticClass: "order-body__title" }, [
              _vm._v("\n                    Kontaktdaten\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-address" }, [
              _c("p", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.order.customer.defaultAddress.firstname) +
                    " " +
                    _vm._s(_vm.order.customer.defaultAddress.lastname) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.order.customer.defaultAddress.street) +
                    " " +
                    _vm._s(_vm.order.customer.defaultAddress.number)
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.order.customer.defaultAddress.postcode) +
                    " " +
                    _vm._s(_vm.order.customer.defaultAddress.city)
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.order.customer.defaultAddress.countryName)),
              ]),
              _vm._v(" "),
              _vm.order.customer.defaultAddress.phoneNumber
                ? _c("p", { staticClass: "mt-2" }, [
                    _vm._v(
                      "Tel.: " +
                        _vm._s(_vm.order.customer.defaultAddress.phoneNumber)
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "order-body__title" }, [
              _vm._v("Bestellung"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-body__wrapper" },
              _vm._l(_vm.order.items, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "row order-card-item no-gutters",
                  },
                  [
                    _c("div", { staticClass: "col-md-1 col-2 pr-2" }, [
                      _c("img", {
                        staticClass: "w-100",
                        attrs: {
                          src: item.product.image
                            ? item.product.image
                            : "/build/images/product-default-image.png",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 col-6" }, [
                      _c("div", { staticClass: "item-description" }, [
                        _vm._v(_vm._s(item.product.name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-2 d-none d-md-flex justify-content-end",
                      },
                      [
                        _c("div", { staticClass: "item-price" }, [
                          _vm._v(
                            _vm._s(_vm._f("formatPrice")(item.itemPriceGross))
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-2 d-flex justify-content-end" },
                      [
                        _c("div", { staticClass: "item-quantity" }, [
                          _vm._v(_vm._s(item.quantity) + " Stk."),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-2 d-flex justify-content-end" },
                      [
                        _c("div", { staticClass: "item-total-price" }, [
                          _vm._v(
                            _vm._s(_vm._f("formatPrice")(item.totalGross))
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _vm.order.orderState != "complete"
            ? _c(
                "div",
                { staticClass: "modal-footer incoming-order-footer d-block" },
                [
                  _c("p", { staticClass: "order-footer__title" }, [
                    _vm._v("Status ändern"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row no-gutters" }, [
                    _vm.order.shippingState === "ready"
                      ? _c("div", { staticClass: "col-12" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-secondary is--ready-for-delivery",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setStatus("ready")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Zur Abholung bereit\n                        "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.order.shippingState === "partially_shipped"
                      ? _c("div", { staticClass: "col-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary is--order-close",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setStatus("completed")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Abgeschlossen\n                        "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }