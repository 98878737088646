<template>
    <b-pagination-nav
        v-model="page"
        align="center"
        base-url="#"
        class="my-md-5 mt-0 mb-4"
        :hide-goto-end-buttons="true"
        :number-of-pages="numberOfPages"
        @change="onPaginate"
    >
        <template slot="prev-text">
            <span class="page-link pagination-nav left mr-3">
                <i class="fa-solid fa-arrow-left"></i>
            </span>
        </template>
        <template slot="next-text">
            <span class="page-link pagination-nav right ml-3">
                <i class="fa-solid fa-arrow-right"></i>
            </span>
        </template>
    </b-pagination-nav>
</template>

<script>
    import {BPaginationNav} from 'bootstrap-vue';

    export default {
        components: {
            BPaginationNav
        },
        name: 'Pagination',
        props: {
            page: Number,
            numberOfPages: Number
        },
        methods: {
            onPaginate(page) {
                this.$emit('paginate', page);
            }
        }
    }
</script>