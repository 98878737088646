<template>
  <div class="d-flex align-items-center justify-content-center flex-column">
    <div class="login-form mb-4 d-flex align-items-center justify-content-center flex-column">
      <div class="login-title text-center">{{$t('site.form.login.choose_branch')}}</div>
      <div class="company-select" :class="{'is--active' : branch.id == selectedBranch}" v-for="branch in branches" :key="branch.id" @click="selectBranch(branch)">
        {{ branch.name }}
      </div>
    </div>
    <button class="btn btn-custom btn-primary has--icon-arrow-right mb-2">{{$t('site.btn.next')}}</button>
  </div>
</template>

<script>
  export default {
    name: 'BranchSelect',
    props: {
      selectedBranch: {
        type: Number|String,
        default: null
      },
      branches: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      selectBranch(branch) {
        this.$emit('select-branch', branch.id)
        window.location.replace("/de/intern");

      }
    }
  }
</script>