var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("brku-product-review-tab-bar", {
        attrs: { mode: _vm.mode },
        on: { "set-mode": _vm.setMode },
      }),
      _vm._v(" "),
      _c("brku-product-review-tab-content", {
        attrs: {
          mode: _vm.mode,
          reviews: _vm.reviews,
          action: _vm.action,
          user: _vm.user,
        },
        on: { "set-mode": _vm.setMode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }