<template>
    <div class="navbar-icon-wrapper">
        <span class="navbar-icon" @click="toggleSearch">
            <i class="fa-solid fa-magnifying-glass"></i>
        </span>
        <brku-search
            v-if="showSearch"
            @close="toggleSearch"
            :search-action="searchAction"
        ></brku-search>
        <span class="navbar-icon" @click="showWishlist = !showWishlist">
            <i class="fa-solid fa-clipboard-list"></i>
            <span class="navbar-icon__badge d-flex align-items-center justify-content-center"
                  v-if="$store.state.wishlist.counter > 0">{{ $store.state.wishlist.counter }}</span>
        </span>
        <brku-wishlist-modal-overview
            v-if="showWishlist"
            @close="showWishlist = false"
            :summary-action="wishlistSummaryAction"
            :remove-action="wishlistRemoveAction"
        ></brku-wishlist-modal-overview>
        <div v-if="isMobile">
            <a :href="accountAction" v-if="isLoggedIn" class="navbar-avatar">
                {{ shortName }}
            </a>
            <span v-if="!isLoggedIn" class="navbar-icon" @click="showLoginModal(!$store.state.login.showLogin)">
                <i class="fa-solid fa-user"></i>
            </span>
        </div>
        <div v-else>
            <a :href="isLoggedIn ? accountAction : loginCheckAction" class="navbar-icon">
                <span v-if="isLoggedIn">{{ fullName }}</span>
                <span v-else>{{ $t('coreshop.ui.login') }}</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        searchAction: {
            type: String,
            default: null
        },
        wishlistSummaryAction: {
            type: String,
            default: null
        },
        wishlistRemoveAction: {
            type: String,
            default: null
        },
        accountAction: {
            type: String,
            default: null
        },
        loginCheckAction: {
            type: String,
            default: null
        },
        resetPasswordUrl: {
            type: String,
            default: null
        },
        registerUrl: {
            type: String,
            default: null
        },
        user: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            showWishlist: false,
            showSearch: false,
            scopeUser: this.user,
            isLoggedIn: false
        }
    },
    mounted() {
        this.isLoggedIn = this.scopeUser != null;
    },
    methods: {
        toggleSearch() {
            document.getElementsByTagName("html")[0].classList.toggle('overflow-hidden');
            document.body.classList.toggle('overflow-hidden');

            this.showSearch = !this.showSearch

            if (document.querySelector('.main-navbar').getAttribute('data-transparent') === '1') {
                let scrollPosition = window.scrollY;

                if (scrollPosition >= 150) {
                    return;
                }

                document.querySelector('.main-navbar').classList.toggle('is--transparent');
            }
        },
        setUser(user) {
            this.scopeUser = user;
            this.isLoggedIn = true;
        },
        showLoginModal(value) {
            this.$store.commit('showLogin', value);
        },
        closeLogin() {
            this.$store.commit('showLogin', false);
        },
        closeSearch() {
            this.showSearch = false;
        }
    },
    computed: {
        shortName() {
            if (this.scopeUser) {
                return this.scopeUser.firstname.charAt(0) + this.scopeUser.lastname.charAt(0);
            }

            return null;
        },
        fullName() {
            if (this.scopeUser) {
                return `${this.scopeUser.firstname.charAt(0)}. ${this.scopeUser.lastname}`;
            }

            return null;
        },
        isMobile() {
            return window.innerWidth < 768;
        }
    }
}
</script>