var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "image-slider-section position-relative" }, [
    _c("div", { staticClass: "image-slider" }, [
      _c(
        "div",
        { staticClass: "image-slider__slides-wrapper" },
        [
          _c(
            "b-carousel",
            {
              ref: "imageSlider",
              attrs: { interval: 0, fade: "", "no-hover-pause": true },
              model: {
                value: _vm.slide,
                callback: function ($$v) {
                  _vm.slide = $$v
                },
                expression: "slide",
              },
            },
            [_vm._t("slides")],
            2
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "slider-navigation-arrow is--white arrow-xl is--left",
        on: {
          click: function ($event) {
            return _vm.$refs.imageSlider.prev()
          },
        },
      },
      [
        _c("i", {
          staticClass: "fa-solid fa-arrow-left slider-navigation-arrow__icon",
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "slider-navigation-arrow is--white arrow-xl is--right",
        on: {
          click: function ($event) {
            return _vm.$refs.imageSlider.next()
          },
        },
      },
      [
        _c("i", {
          staticClass: "fa-solid fa-arrow-right slider-navigation-arrow__icon",
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }