<script>
    import VueWithCompiler from 'vue/dist/vue.esm';

    export default {
        name: 'RenderHtml',
        props: {
            html: String
        },
        data() {
            return {
                templateRender: null
            };
        },
        watch: {
            html(to) {
                this.updateRender();
            }
        },
        created() {
            this.updateRender();
        },
        methods: {
            updateRender() {
                let compiled = VueWithCompiler.compile(this.html);

                this.templateRender = compiled.render;
                this.$options.staticRenderFns = [];

                for (let staticRenderFunction of compiled.staticRenderFns) {
                    this.$options.staticRenderFns.push(staticRenderFunction);
                }
            }
        },
        render() {
            return this.templateRender();
        }
    }
</script>