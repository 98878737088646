var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inventory-item" }, [
    _c("div", { staticClass: "row" }, [
      !_vm.defaultImage
        ? _c("div", {
            staticClass: "col-1 d-flex align-items-center",
            domProps: { innerHTML: _vm._s(_vm.image) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.defaultImage
        ? _c("div", { staticClass: "col-1 d-flex align-items-center" }, [
            _c("img", {
              staticClass: "item-image",
              attrs: { src: _vm.defaultImage },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col d-flex align-items-center" }, [
        _c("div", { staticClass: "item-name" }, [
          _vm._v("\n        " + _vm._s(_vm.name) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col d-flex align-items-center" }, [
        _c("div", { staticClass: "item-number" }, [
          _vm._v("\n        Art. Nr.: " + _vm._s(_vm.sku) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col d-flex align-items-center justify-content-end" },
        [
          _c("div", { staticClass: "quantity-input input-group" }, [
            _c("div", { staticClass: "input-group-prepend" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.scopeStock--
                    },
                  },
                },
                [_c("i", { staticClass: "fa-solid fa-minus" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary ml-3",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.scopeStock++
                    },
                  },
                },
                [_c("i", { staticClass: "fa-solid fa-plus" })]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scopeStock,
                    expression: "scopeStock",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number" },
                domProps: { value: _vm.scopeStock },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.scopeStock = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }