var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.totalCount >= 4
    ? _c("div", { staticClass: "related-products-slider position-relative" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "special-heading mb-5" }, [
            _c("h4", { staticClass: "special-heading__title" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.product_detail.related_products"))),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "special-heading__sub-title" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "site.ui.product_detail.freshly_made_with_regional_ingredients"
                  )
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "slider", staticClass: "keen-slider xl:mt-5" },
            [_vm._t("default")],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              arrow: true,
              "slider-navigation-arrow arrow-xl is--left": true,
              "is--disabled": _vm.current === 0,
            },
            on: {
              click: function ($event) {
                return _vm.slider.prev()
              },
            },
          },
          [
            _c("i", {
              staticClass:
                "fa-solid fa-arrow-left slider-navigation-arrow__icon",
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              arrow: true,
              "slider-navigation-arrow arrow-xl is--right": true,
              "is--disabled": _vm.current === _vm.totalCount - 1,
            },
            on: {
              click: function ($event) {
                return _vm.slider.next()
              },
            },
          },
          [
            _c("i", {
              staticClass:
                "fa-solid fa-arrow-right slider-navigation-arrow__icon",
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }