var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.reviews.length > 0
      ? _c("div", [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { ref: "slider", staticClass: "keen-slider" },
              _vm._l(_vm.reviews, function (review) {
                return _c(
                  "div",
                  { key: review.id, staticClass: "keen-slider__slide" },
                  [
                    _c("div", { staticClass: "post-rating" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "post-rating__header d-flex justify-content-between",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "post-rating__header-wrapper" },
                            [
                              _c("div", { staticClass: "rating-time" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        review.creationDate,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "rating-name" }, [
                                _vm._v(_vm._s(_vm.getName(review))),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "rating" },
                            [
                              _c("brku-star-rating", {
                                attrs: {
                                  "active-color": "#e7cb5e",
                                  "border-color": "#e7cb5e",
                                  "inactive-color": "#ffffff",
                                  "border-width": 2,
                                  increment: 0.5,
                                  inline: true,
                                  rating: parseFloat(review.rating),
                                  "read-only": true,
                                  "rounded-corners": true,
                                  "show-rating": false,
                                  "star-size": 19,
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "rating-average" }, [
                                _vm._v(_vm._s(review.rating)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "post-rating__title" }, [
                        _vm._v(_vm._s(review.title)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "post-rating__text" }, [
                        _vm._v(_vm._s(review.text)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _vm.totalCount > 3
            ? _c(
                "div",
                {
                  class: {
                    arrow: true,
                    "slider-navigation-arrow arrow-xl is--left": true,
                    "is--disabled": _vm.current === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.slider.prev()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass:
                      "fa-solid fa-arrow-left slider-navigation-arrow__icon",
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.totalCount > 3
            ? _c(
                "div",
                {
                  class: {
                    arrow: true,
                    "slider-navigation-arrow arrow-xl is--right": true,
                    "is--disabled": _vm.current === _vm.totalCount - 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.slider.next()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass:
                      "fa-solid fa-arrow-right slider-navigation-arrow__icon",
                  }),
                ]
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          { staticClass: "container" },
          [
            _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
              _vm._v(_vm._s(_vm.$t("site.ui.product_review.no_entries"))),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }