var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop d-md-flex d-block" },
    [
      _vm.loading ? _c("brku-loader-brand") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "product-filter",
          class: { "is--open": _vm.showFilterModal },
        },
        [
          _c("brku-grid-filter", {
            attrs: {
              conditions: _vm.filter.conditions,
              "current-filter": _vm.filter.currentFilter,
            },
            on: {
              close: function ($event) {
                _vm.showFilterModal = false
              },
              change: _vm.onUpdateFilter,
              "remove-all": _vm.onRemoveAll,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "product-grid flex-grow-1" },
        [
          _vm.filter.conditions && _vm.filter.currentFilter
            ? _c("brku-current-filter", {
                staticClass: "has--background",
                attrs: {
                  conditions: _vm.filter.conditions,
                  "current-filter": _vm.filter.currentFilter,
                },
                on: {
                  "filter-update": _vm.onUpdateFilter,
                  "remove-all": _vm.onRemoveAll,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.renderedBreadcrumbHtml) },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column flex-xl-row justify-content-between align-items-xl-center align-items-start mb-0 mb-xl-5",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "shop-result order-last order-xl-first mt-3 mt-xl-0",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("site.ui.results_label", {
                              range: _vm.itemsRange,
                              count: _vm.totalCount,
                            })
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "product-filter__outer d-flex order-first order-xl-last",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "product-filter__toggle d-xl-none mr-3",
                          on: {
                            click: function ($event) {
                              _vm.showFilterModal = !_vm.showFilterModal
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "bk-i bk-i-filter" }),
                          _vm._v(" Filter\n                    "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("brku-sort-type-select", {
                        attrs: { "valid-sort-elements": _vm.validSortElements },
                        on: { input: _vm.sortList },
                        model: {
                          value: _vm.controls.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.controls, "sort", $$v)
                          },
                          expression: "controls.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("brku-extended-render-html", {
                key: _vm.gridHtml,
                attrs: { html: _vm.gridHtml },
              }),
              _vm._v(" "),
              _c("brku-pagination", {
                attrs: {
                  "number-of-pages": _vm.controls.numberOfPages,
                  page: _vm.controls.page,
                },
                on: { paginate: _vm.onPaginate },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }