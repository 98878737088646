var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "job-slider" }, [
    _c(
      "div",
      { ref: "slider", staticClass: "job-slider__wrapper keen-slider" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }