<template>
    <div :class="wrapperClass">
        <div class="form-group" :class="{'has--input' : value != null}">
            <label class="label-card">{{label}}</label>
            <input :required="required" :type="type" class="form-control input-card" :placeholder="label" v-model="scopeValue"  @input="$emit('input', $event.target.value)">
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountInput',
    props: {
        wrapperClass: {
            type: String,
            default: "col-md-6 col-12"
        },
        label: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            scopeValue: this.value
        }
    },
    watch: {
        'value': function (val) {
            this.scopeValue = val;
        }
    }
}
</script>