<template>
    <div class="quantity-input input-group">
        <div class="input-group-prepend">
            <button
                class="btn btn-custom btn-outline-secondary"
                type="button"
                :disabled="decrementDisabled"
                @click="onDecrement"
            >
                <i class="fa-solid fa-minus"></i>
            </button>
            <input
                class="form-control"
                type="number"
                required="required"
                min="0"
                step="1"
                autocomplete="off"
                :value="value"
                @input="onInput($event)"
            />
            <button
                class="btn btn-custom btn-outline-secondary"
                type="button"
                :disabled="incrementDisabled"
                @click="onIncrement"
            >
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        quantity: {
            type: Number,
            default: 1
        }
    },
    data: () => ({
        decrementDisabled: false,
        incrementDisabled: false,
        value: 1
    }),
    created() {
        this.value = this.quantity;

        if (this.value <= 1) {
            this.decrementDisabled = true;
        }
    },
    methods: {
        onDecrement() {
            if (this.value > 1) {
                this.value -= 1;
            }

            this.doChange(this);
        },
        onIncrement() {
            this.value += 1;

            this.doChange(this);
        },
        onInput(event) {
            this.value = event.target.value;

            this.doChange(this);
        },
        doChange: _.debounce((vm) => {
            if (vm.value > 1) {
                vm.decrementDisabled = false;
            } else {
                vm.decrementDisabled = true;
            }

            vm.$emit('quantity-changed', vm.value);
        }, 200),
    },
    watch: {
        quantity(newValue) {
            this.value = newValue;
        }
    }
}
</script>