<template>
    <div class="product-category-tabs nav-tabs-wrapper position-relative pt-5">
        <div class="container position-relative">
            <ul class="nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
                <li class="nav-item" v-for="item in categories" :key="item.id">
                    <a
                        href="#"
                        :class="{'nav-link': true, 'active': item.id === active }"
                        @click.prevent="active = item.id">
                        {{ item.name }}
                    </a>
                </li>
            </ul>
            <div class="keen-slider mt-5" id="keen-slider" :is="compiledHtml" v-if="totalCount > 0"></div>
            <b-alert class="mt-5" show variant="danger" v-else>{{ $t('site.ui.no_items') }}</b-alert>
            <div v-if="loading" class="inline-loader has--space-tabs">
                <i class="bk-spin fa-solid fa-circle-notch"></i>
            </div>
        </div>
        <template v-if="totalCount > 0">
            <div
                :class="{ arrow: true, 'slider-navigation-arrow arrow-xl is--left': true, 'is--disabled': current === 0 }"
                @click="slider.prev()">
                <i class="fa-solid fa-arrow-left slider-navigation-arrow__icon"></i>
            </div>
            <div
                :class="{ arrow: true, 'slider-navigation-arrow arrow-xl is--right': true, 'is--disabled': current === totalCount - 1 }"
                @click="slider.next()">
                <i class="fa-solid fa-arrow-right slider-navigation-arrow__icon"></i>
            </div>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';
import KeenSlider from 'keen-slider';

export default {
    props: {
        action: {
            type: String,
            default: null
        },
        categories: {
            type: Array,
            default: null
        }
    },
    data: () => ({
        active: 0,
        count: 0,
        current: 0,
        firstLoad: true,
        html: null,
        loading: false,
        slider: null,
        pause: false,
        interval: null
    }),
    created() {
        this.active = this.categories[0].id;
    },
    computed: {
        compiledHtml() {
            return this.html ? Vue.compile(this.html) : '';
        },
        totalCount() {
            return this.count ? this.count : 0;
        }
    },
    methods: {
        loadProducts() {
            this.loading = true;

            axios.get(this.action + '?categoryId=' + this.active)
                .then(res => res.data)
                .then(res => {
                    this.destroySlider();

                    this.html = res.html;
                    this.count = res.totalCount;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        initSlider() {
            this.slider = new KeenSlider(document.getElementById('keen-slider'), {
                initial: this.current,
                slidesPerView: 1,
                spacing: 60,
                duration: 1000,
                dragStart: () => {
                    this.setPause(true)
                },
                dragEnd: () => {
                    this.setPause(false)
                },
                breakpoints: {
                    "(min-width: 768px)": {
                        slidesPerView: 1
                    },
                    "(min-width: 992px)": {
                        slidesPerView: 2
                    },
                    "(min-width: 1200px)": {
                        slidesPerView: 3
                    },
                },
                slideChanged: (s) => {
                    this.current = s.details().relativeSlide;
                }
            });

            this.setInterval();
        },
        setPause(active) {
            this.pause = active
            this.setInterval()
        },
        resetInterval() {
            clearInterval(this.interval);
        },
        setInterval() {
            this.resetInterval()
            this.interval = setInterval(() => {
                if (!this.pause) {
                    this.slider.next();
                }
            }, 5000)
        },
        destroySlider() {
            if (this.slider) this.slider.destroy();
        }
    },
    beforeDestroy() {
        this.destroySlider();
    },
    watch: {
        active() {
            this.loadProducts();
        },
        html() {
            this.$nextTick(() => {
                this.initSlider();
            });

        }
    }
}
</script>