<template>
    <div class="sale-list-item mb-2">
        <div class="sale-list-item__title">
            <div class="row align-items-center no-gutters">
                <div class="col-lg-9 col-7 d-lg-flex d-block">
                    <div class="col-lg-5 col-12">
                        <div class="item-order pl-lg-3">
                            {{ order.cart.customIdentifier }} <span class="item-order-number">({{ order.id }})</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-12">
                        <div class="item-period item-period-time">
                            {{ order.startDate|moment('DD.MM.Y') }} - {{ order.endDate|moment('DD.MM.Y') }}
                        </div>
                    </div>
                    <div class="col-lg-3 col-12">
                        <div class="item-customer-number item-user">
                            <span class="d-lg-none">
                                {{ this.$t('site.ui.account.table_header.customer_number') + ':' }}
                            </span>
                            {{ order.cart.customer.customerNumber }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-3">
                    <div class="item-price">
                        {{ order.cart.totalNetFormatted }}
                    </div>
                </div>
                <div class="col-lg-1 col-2">
                    <button
                        :class="['border-0 ml-auto item-btn', {'has--angle-down': !visible, 'has--angle-up': visible}]"
                        v-b-toggle="`recurring_order-${order.id}`">
                        <i class="fa-solid fa-chevron-down" v-if="visible"></i>
                        <i class="fa-solid fa-chevron-up" v-else></i>
                    </button>
                </div>
            </div>
            <b-collapse class="sale-list-body" :id="`recurring_order-${order.id}`" v-model="visible">
                <div class="row">
                    <div class="col-md-6 col-12 body-col" v-if="order.invoiceAddress">
                        <div class="body-title">Rechnungsadresse</div>
                        <div class="card-address">
                            <p v-if="order.invoiceAddress.company">{{ order.invoiceAddress.company }}</p>
                            <p v-else>{{ order.invoiceAddress.firstName }} {{ order.invoiceAddress.lastName }}</p>
                            <p>{{ order.invoiceAddress.street }} {{ order.invoiceAddress.number }}</p>
                            <p>{{ order.invoiceAddress.postcode }} {{ order.invoiceAddress.city }}</p>
                            <p>{{ order.invoiceAddress.countryName }}</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 body-col" v-if="order.shippingAddress">
                        <div class="body-title">Lieferadresse</div>
                        <div class="card-address">
                            <p v-if="order.shippingAddress.company">{{ order.shippingAddress.company }}</p>
                            <p v-else>{{ order.shippingAddress.firstName }} {{ order.shippingAddress.lastName }}</p>
                            <p>{{ order.shippingAddress.street }} {{ order.shippingAddress.number }}</p>
                            <p>{{ order.shippingAddress.postcode }} {{ order.shippingAddress.city }}</p>
                            <p>{{ order.shippingAddress.countryName }}</p>
                        </div>
                    </div>
                    <div class="col-12 mt-5">
                        <div class="body-title">Produkte</div>
                        <div class="row order-card-item no-gutters" v-for="item in order.cart.items">
                            <div class="col-8 d-flex align-items-center justify-content-start">
                                <img v-if="item.product.image" class="item-image"
                                     :src="item.product.image"/>
                                <img v-if="!item.product.image" class="item-image"
                                     src="/build/images/product-default-image.png"/>
                                <div class="item-info">
                                    <div class="item-description">
                                        {{ item.product.name }}
                                    </div>
                                    <div class="item-number">
                                        Art. Nr: {{ item.product.sku }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-1 d-none d-md-flex">
                                <div class="item-price">
                                    {{ item.itemNetFormatted }}
                                </div>
                            </div>
                            <div class="col-2 d-flex justify-content-center">
                                <div class="item-quantity">
                                    {{ item.quantity }} Stk.
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <div class="item-total-price">
                                    {{ item.totalItemNetFormatted }}
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters order-card-result">
                            <div class="col-md-8 col-3"></div>
                            <div class="col-md-4 col-9">
                                <div class="total-price d-flex justify-content-between align-items-center">
                                    <p class="total-price__text">Gesamtsumme</p>
                                    <p class="total-price__sum">{{ order.cart.totalNetFormatted }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrapper d-lg-flex d-block align-items-center mt-4">
                            <button class="btn btn-link delete-order-btn pl-3 text-decoration-none mr-auto" @click="deleteRecurringOrder">
                                <i class="fa-solid fa-trash btn-delete__icon"></i>
                                {{ this.$t('site.form.recurring_order.delete') }}
                            </button>
                            <button class="btn btn-custom btn-primary has--icon-arrow-right ml-auto" v-b-modal="`recurring-order-modal-update-${order.id}`">
                                {{ this.$t('site.form.recurring_order.process') }}
                            </button>
                        </div>
                    </div>
                </div>
            </b-collapse>
            <brku-recurring-order-modal
                :carts="carts"
                :locale="locale"
                :api-endpoint="modalApiEndpoint"
                :id="`update-${order.id}`"
                :start-date="order.startDate"
                :end-date="order.endDate"
                :cart-id="order.cart.id"
                :order-id="order.id"
                :order-item="order"
            ></brku-recurring-order-modal>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        apiEndpoint: {
            type: String,
            default: ''
        },
        locale: {
            type: String,
            default: 'de'
        },
        carts: {
            type: Array,
            default: []
        },
        order: {
            type: Object,
            default: {}
        },
        deleteAction: {
            type: String,
            default: ''
        },
        updateAction: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        visible: false
    }),
    computed: {
        modalApiEndpoint() {
            return this.order.id ? this.updateAction : this.deleteAction;
        }
    },
    methods: {
        deleteRecurringOrder() {
            axios.delete(this.deleteAction).then(res => {
                location.reload();
            }).catch(() => {
                this.$toast.clear();
                this.$toast.error(this.$t('site.ui.recurring_order.error'));
            });
        }
    }
}
</script>