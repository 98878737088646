import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueStarRating from 'vue-star-rating';
import VueTelInput from 'vue-tel-input'
import VueScrollTo from 'vue-scrollto';
import VueCookies from 'vue-cookies';
import VueToast from 'vue-toast-notification';
import VueTimepicker from 'vue2-timepicker';
import VueMoment from 'vue-moment';
import moment from 'moment';
import BootstrapVue from 'bootstrap-vue';
import {store} from './store';
import LineChart from "./LineChart";
import Vuetify, {VApp, VTimePicker} from 'vuetify/lib'
import * as VueGoogleMaps from 'vue2-google-maps'

import 'keen-slider/keen-slider.min.css';


// TODO: Add locale 'de'
require('moment/locale/de');

Vue.use(Vuetify, {
    locale: {
        locale: document.documentElement.lang || 'de',
        messages: window.translations
    },
    components: {VApp, VTimePicker},
    theme: {
        themes: {
            light: {
                primary: '#cc9e67',
                secondary: '#866d5d',
                anchor: '#cc9e67',
            },
        },
    }
})

Vue.use(VueMoment, {
    moment
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.GOOGLE_MAPS_API_KEY,
        libraries: 'places'
    }
})

// modules
require('./areabricks');
require('./cart');
require('./category');
require('./checkout');
require('./filter');
require('./filters');
require('./login');
require('./loader');
require('./search');
require('./job-slider');
require('./product');
require('./register');
require('./account');
require('./map');
require('./methods');
require('./newsletter');
require('./inquiry-form');
require('./intern/auth/login');
require('./intern/order');
require('./intern/stock');
require('./wishlist');
require('./slider');

import ExtendedRenderHtml from './ExtendedRenderHtml';
import HeaderIcons from "./HeaderIcons";
import Pagination from './Pagination';
import PieChart from "./PieChart";
import QuantityInput from "./QuantityInput";
import RenderHtml from './RenderHtml';

Vue.component('brku-extended-render-html', ExtendedRenderHtml);
Vue.component('brku-header-icons', HeaderIcons);
Vue.component('brku-pagination', Pagination);
Vue.component('brku-pie-chart', PieChart);
Vue.component('brku-quantity-input', QuantityInput);
Vue.component('brku-render-html', RenderHtml);
Vue.component('brku-star-rating', VueStarRating);
Vue.component('brku-line-chart', LineChart);

Vue.prototype.$http = window.axios;
Vue.prototype.$locale = document.documentElement.lang;

Vue.use(VueI18n);
Vue.use(VueTelInput)
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(VueToast);
Vue.use(VueScrollTo);
Vue.use(VueTimepicker);

Vue.filter("formatDate", function (date) {
    return moment(date).format('DD.MM.YYYY');
});

Vue.filter("formatPrice", function (value) {
    const formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
    });

    return formatter.format(value / 1000);
});

export const i18n = new VueI18n({
    locale: document.documentElement.lang,
    messages: window.translations,
    silentTranslationWarn: process.env.NODE_ENV === 'production'
});

if (document.getElementById('app').className !== 'editmode') {
    const app = new Vue({
        store,
        i18n,
    }).$mount('#app')
}
