<template>
    <div>
        <div v-if="reviews.length > 0">
            <div class="container">
                <div class="keen-slider" ref="slider">
                    <div class="keen-slider__slide" v-for="review in reviews" :key="review.id">
                        <div class="post-rating">
                            <div class="post-rating__header d-flex justify-content-between">
                                <div class="post-rating__header-wrapper">
                                    <div class="rating-time">
                                        {{ review.creationDate | moment("DD.MM.YYYY") }}
                                    </div>
                                    <div class="rating-name">{{ getName(review) }}</div>
                                </div>
                                <div class="rating">
                                    <brku-star-rating active-color="#e7cb5e"
                                                      border-color="#e7cb5e"
                                                      inactive-color="#ffffff"
                                                      :border-width="2"
                                                      :increment="0.5"
                                                      :inline="true"
                                                      :rating="parseFloat(review.rating)"
                                                      :read-only="true"
                                                      :rounded-corners="true"
                                                      :show-rating="false"
                                                      :star-size="19"
                                    ></brku-star-rating>
                                    <p class="rating-average">{{ review.rating }}</p>
                                </div>
                            </div>
                            <p class="post-rating__title">{{ review.title }}</p>
                            <p class="post-rating__text">{{ review.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="totalCount > 3"
                :class="{ arrow: true, 'slider-navigation-arrow arrow-xl is--left': true, 'is--disabled': current === 0 }"
                @click="slider.prev()"
            >
                <i class="fa-solid fa-arrow-left slider-navigation-arrow__icon"></i>
            </div>
            <div
                v-if="totalCount > 3"
                :class="{ arrow: true, 'slider-navigation-arrow arrow-xl is--right': true, 'is--disabled': current === totalCount - 1 }"
                @click="slider.next()"
            >
                <i class="fa-solid fa-arrow-right slider-navigation-arrow__icon"></i>
            </div>
        </div>
        <div class="container" v-else>
            <b-alert show variant="danger">{{ $t('site.ui.product_review.no_entries') }}</b-alert>
        </div>
    </div>
</template>

<script>
import KeenSlider from "keen-slider";

export default {
    name: 'ReviewList',
    props: {
        reviews: Array | Object
    },
    data: () => ({
        current: 0,
        slider: null,
        totalCount: 0
    }),
    created() {
        this.totalCount = this.reviews.length;
    },
    methods: {
        initSlider() {
            if (this.slider) this.slider.destroy();

            this.slider = new KeenSlider(this.$refs.slider, {
                initial: this.current,
                slidesPerView: 1,
                spacing: 75,
                slideChanged: (s) => {
                    this.current = s.details().relativeSlide;
                },
                breakpoints: {
                    "(min-width: 768px)": {
                        slidesPerView: 2
                    },
                    "(min-width: 1200px)": {
                        slidesPerView: 3
                    },
                },
            });
        },
        getName(review) {
            return review.name ? review.name : `${review.customer.firstname} ${review.customer.lastname}`;
        }
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy();
    },
    watch: {
        reviews() {
            setTimeout(() => {
                this.initSlider();
            }, 100);
        }
    }
}
</script>