var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wishlist-product" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-7 col-8 d-flex align-items-center" }, [
        _c("img", {
          staticClass: "product-image",
          attrs: { src: _vm.product.image },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "product-title" }, [
          _vm._v("\n        " + _vm._s(_vm.product.name) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-md-5 col-4 d-flex align-items-center justify-content-end",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-6 col-12 d-flex align-items-center justify-content-end",
              },
              [
                _c("div", { staticClass: "product-price" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.product.price) +
                      "\n          "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-6 col-12 d-flex align-items-center justify-content-end",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "product-delete d-flex",
                    on: { click: _vm.removeFromWishlist },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("site.ui.remove")) +
                        "\n          "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }