var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-condition",
    {
      attrs: {
        condition: _vm.filterCondition,
        "current-filter": _vm.currentFilter,
      },
      on: { search: _vm.onSearch },
    },
    [
      _c(
        "b-form-checkbox-group",
        {
          attrs: { name: _vm.filterCondition.fieldName },
          on: { change: _vm.onChange },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "div",
            { staticClass: "product-filter__options-wrapper" },
            _vm._l(_vm.visibleItems, function (item) {
              return _c(
                "div",
                {
                  key: `${_vm.filterCondition.fieldName}_${item.id}`,
                  staticClass: "product-filter__option-item",
                },
                [
                  _c(
                    "div",
                    { staticClass: "option-item__content" },
                    [
                      _c(
                        "b-form-checkbox",
                        { attrs: { value: parseInt(item.id) } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(`${item.letter} - ${item.filter_name}`) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.hiddenItems.length > 0
            ? _c(
                "b-collapse",
                {
                  staticClass: "product-filter__options-wrapper",
                  attrs: {
                    id: `collapse-options-wrapper-${_vm.filterCondition.fieldName}`,
                  },
                },
                _vm._l(_vm.hiddenItems, function (item) {
                  return _c(
                    "div",
                    {
                      key: `${_vm.filterCondition.fieldName}_${item.id}`,
                      staticClass: "product-filter__option-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "option-item__content" },
                        [
                          _c(
                            "b-form-checkbox",
                            { attrs: { value: parseInt(item.id) } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    `${item.letter} - ${item.filter_name}`
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hiddenItems.length > 0
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: `collapse-options-wrapper-${_vm.filterCondition.fieldName}`,
                      expression:
                        "`collapse-options-wrapper-${filterCondition.fieldName}`",
                    },
                  ],
                  staticClass: "product-filter__option-item",
                  on: {
                    click: function ($event) {
                      _vm.optionsHidden = !_vm.optionsHidden
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "option-item__content justify-content-center",
                    },
                    [
                      !_vm.optionsHidden
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("site.ui.close")))])
                        : _c("span", [
                            _vm._v("+ " + _vm._s(_vm.hiddenItems.length)),
                          ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }