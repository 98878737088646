<script>
    import RenderHtml from "./RenderHtml.vue";
    import AddToCart from "./cart/AddToCart";

    export default {
        components: {
            AddToCart
        },
        ...RenderHtml
    }
</script>