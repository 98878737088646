var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "login-modal" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.login.apply(null, arguments)
            },
          },
        },
        [
          _c("label", { staticClass: "login-title d-md-none d-flex" }, [
            _vm._v(_vm._s(_vm.$t("site.form.login.title"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "login-form" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "input-card",
                attrs: {
                  type: "text",
                  name: "email",
                  required: "required",
                  placeholder: _vm.$t("site.form.login.email"),
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "input-card",
                attrs: {
                  type: "password",
                  name: "_password",
                  required: "required",
                  placeholder: _vm.$t("site.form.login.password"),
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              model: {
                value: _vm.rememberMe,
                callback: function ($$v) {
                  _vm.rememberMe = $$v
                },
                expression: "rememberMe",
              },
            },
            [_vm._v(_vm._s(_vm.$t("site.form.login.remember_me")))]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "btn-custom w-100 my-3",
              attrs: { type: "submit", variant: "primary" },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("site.form.login.button")) + "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "login-item d-flex justify-content-between" },
            [
              _c(
                "a",
                {
                  staticClass: "item-pw-reset",
                  attrs: { href: _vm.resetPasswordUrl },
                },
                [_vm._v(_vm._s(_vm.$t("site.ui.reset_password")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "item-register-reset",
                  attrs: { href: _vm.registerUrl },
                },
                [_vm._v(_vm._s(_vm.$t("site.ui.register")))]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "login-modal__overlay",
      on: { click: _vm.close },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-modal__inner position-relative" }, [
      _c("div", { staticClass: "triangle-up position-absolute" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }