<template>
    <div class="nav-tabs-wrapper position-relative pt-5">
        <div class="container">
            <ul class="nav nav-tabs d-flex justify-content-center">
                <li class="nav-item">
                    <span
                        :class="{ 'nav-link text-break': true, 'active': mode === 'review-list' }"
                        @click="$emit('set-mode', 'review-list')"
                    >
                        <i class="fa-solid fa-star"></i>
                        <span class="nav-link-text">{{ $t('site.ui.product_review.list') }}</span>
                    </span>
                </li>
                <li class="nav-item">
                    <span
                        :class="{ 'nav-link text-break': true, 'active': mode === 'review-form' }"
                        @click="$emit('set-mode', 'review-form')"
                    >
                        <i class="bk-i bk-i-create-rating"></i>
                        <span class="nav-link-text">{{ $t('site.ui.product_review.create') }}</span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabBar',
    props: {
        mode: String
    }
}
</script>