import Vue from 'vue';

require('./review');

import ImageContentSlider from './ImageContentSlider';
import NutrientsChart from './NutrientsChart';
import ProductImageSlider from './ProductImageSlider';
import ProductIngredientSlider from './ProductIngredientSlider';
import ProductionProcessSlider from './ProductionProcessSlider';
import ProductReview from './ProductReview';
import RelatedProductsSlider from './RelatedProductsSlider';

Vue.component('brku-image-content-slider', ImageContentSlider);
Vue.component('brku-product-nutrients-chart', NutrientsChart);
Vue.component('brku-product-image-slider', ProductImageSlider);
Vue.component('brku-product-ingredient-slider', ProductIngredientSlider);
Vue.component('brku-production-process-slider', ProductionProcessSlider);
Vue.component('brku-product-review', ProductReview);
Vue.component('brku-related-products-slider', RelatedProductsSlider);