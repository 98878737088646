<template>
    <div class="product-images d-flex flex-column flex-md-row">
        <div class="product-images__thumbnails order-last order-md-first d-md-block d-flex">
            <div class="keen-slider" ref="thumbnailSlider">
                <img
                    v-for="(thumbnail, index) of thumbnails"
                    :class="{ 'keen-slider__slide image-pagination': true, 'is--active': current === index }"
                    :key="index"
                    :src="thumbnail"
                    @click="slider.moveToSlideRelative(index)"
                />
            </div>
        </div>
        <div class="product-images__slider position-relative order-first order-md-last">
            <div class="product-images__slides-wrapper">
                <div class="keen-slider" ref="slider">
                    <img
                        v-for="(image, index) of images"
                        :class="{ 'keen-slider__slide product-images__slider-image': true, 'is--active': current === index }"
                        :key="index"
                        :src="image"
                    />
                </div>
            </div>
            <div class="slider-navigation-wrapper position-absolute d-md-flex d-none">
                <div
                    :class="{ arrow: true, 'slider-navigation is--prev mr-3': true, 'is--disabled': current === 0 }"
                    @click="slider.prev()">
                    <i class="fa-solid fa-angle-left"></i>
                </div>
                <div
                    :class="{ arrow: true, 'slider-navigation is--next': true, 'is--disabled': current === totalCount - 1 }"
                    @click="slider.next()">
                    <i class="fa-solid fa-angle-right"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KeenSlider from "keen-slider";

export default {
    name: 'ProductImageSlider',
    props: {
        images: Array,
        thumbnails: Array,
        totalCount: Number,
    },
    data: () => ({
        current: 0,
        slider: null,
        thumbnailSlider: null
    }),
    mounted() {
        if (this.totalCount > 0){
            this.slider = new KeenSlider(this.$refs.slider, {
                initial: this.current,
                slideChanged: (s) => {
                    this.current = s.details().relativeSlide;
                    this.onChange();
                }
            });

            this.thumbnailSlider = new KeenSlider(this.$refs.thumbnailSlider, {
                initial: this.current,
                rubberband: false,
                slidesPerView: window.innerWidth < 768 ? 4 : 6,
                spacing: 10,
                vertical: window.innerWidth < 768 ? false : true,
                slideChanged: (s) => {
                    this.current = s.details().relativeSlide;
                    this.slider.moveToSlideRelative(this.current);
                }
            });
        }
    },
    methods: {
        onChange() {
            if (this.thumbnailSlider === null) {
                return;
            }

            this.thumbnailSlider.moveToSlideRelative(this.current);
        }
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy();
        if (this.thumbnailSlider) this.thumbnailSlider.destroy();
    }
}
</script>