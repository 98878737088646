<template>
    <base-condition :condition="filterCondition" :current-filter="currentFilter" @search="onSearch">
        <div class="product-filter__options-wrapper">
            <div
                v-for="item in visibleItems"
                :key="`${filterCondition.fieldName}_${item.id}`"
            >
                <div class="product-filter__option-item">
                    <div class="option-item__content">
                        <b-form-checkbox v-model="selected" :name="filterCondition.fieldName"
                                         :value="parseInt(item.id)">
                            {{ item.filter_name }}
                        </b-form-checkbox>
                    </div>
                    <div class="option-item__count" v-if="item.childCategories.length === 0">
                        ({{ getValueForId(item.id).count }})
                    </div>
                    <div class="product-filter__options-toggle" v-b-toggle="`collapse-category-select-${item.id}`"
                         v-if="item.childCategories.length > 0">
                        <i class="fa-solid fa-angle-down toggle-angle-down"></i>
                    </div>
                </div>
                <div v-if="item.childCategories.length > 0">
                    <b-collapse class="pl-3" :id="`collapse-category-select-${item.id}`">
                        <div class="product-filter__option-item"
                             v-for="childItem in item.childCategories"
                             :key="`${filterCondition.fieldName}_${childItem.id}`"
                        >
                            <div class="option-item__content">
                                <b-form-checkbox v-model="selected" :name="filterCondition.fieldName"
                                                 :value="parseInt(childItem.id)">
                                    {{ childItem.filter_name }}
                                </b-form-checkbox>
                            </div>
                            <div class="option-item__count">
                                ({{ getValueForId(childItem.id).count ? getValueForId(childItem.id).count : 0 }})
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
        <b-collapse
            class="product-filter__options-wrapper"
            :id="`collapse-options-wrapper-${filterCondition.fieldName}`"
            v-if="hiddenItems.length > 0"
        >
            <div
                v-for="item in hiddenItems"
                :key="`${filterCondition.fieldName}_${item.id}`"
            >
                <div class="product-filter__option-item">
                    <div class="option-item__content">
                        <b-form-checkbox v-model="selected" :name="filterCondition.fieldName"
                                         :value="parseInt(item.id)">
                            {{ item.filter_name }}
                        </b-form-checkbox>
                    </div>
                    <div class="option-item__count" v-if="item.childCategories.length === 0">
                        ({{ getValueForId(item.id).count }})
                    </div>
                    <div class="product-filter__options-toggle" v-b-toggle="`collapse-category-select-${item.id}`"
                         v-if="item.childCategories.length > 0">
                        <i class="fa-solid fa-angle-down toggle-angle-down"></i>
                    </div>
                </div>
                <div class="pl-3" v-if="item.childCategories.length > 0">
                    <b-collapse :id="`collapse-category-select-${item.id}`">
                        <div class="product-filter__option-item"
                             v-for="childItem in item.childCategories"
                             :key="`${filterCondition.fieldName}_${childItem.id}`"
                        >
                            <div class="option-item__content">
                                <b-form-checkbox v-model="selected" :name="filterCondition.fieldName"
                                                 :value="parseInt(childItem.id)">
                                    {{ childItem.filter_name }}
                                </b-form-checkbox>
                            </div>
                            <div class="option-item__count">
                                ({{ getValueForId(childItem.id).count ? getValueForId(childItem.id).count : 0 }})
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </b-collapse>
        <div class="product-filter__option-item extend-button"
             v-if="hiddenItems.length > 0"
             @click="optionsHidden = !optionsHidden"
             v-b-toggle="`collapse-options-wrapper-${filterCondition.fieldName}`"
        >
            <div class="option-item__content justify-content-center">
                <span v-if="!optionsHidden">{{ $t('site.ui.close') }}</span>
                <span v-else>+ {{ hiddenItems.length }}</span>
            </div>
        </div>
    </base-condition>
</template>

<script>
import BaseCondition from './BaseCondition';
import {BFormCheckbox, BFormCheckboxGroup} from 'bootstrap-vue';

export default {
    extends: BaseCondition,
    components: {
        BaseCondition,
        BFormCheckbox, BFormCheckboxGroup
    },
    props: {
        condition: {
            type: Object,
            default: null
        },
        currentFilter: {
            type: Object | Array,
            default: null
        }
    },
    data: () => ({
        filterCondition: null,
        selected: null,
        optionsHidden: true
    }),
    created() {
        if (this.condition.currentValues) {
            this.selected = this.condition.currentValues;
        }

        this.setDefaultFilterCondition();
    },
    computed: {
        visibleItems() {
            return this.filterCondition.objects.slice(0, 3);
        },
        hiddenItems() {
            return this.filterCondition.objects.slice(3, this.filterCondition.objects.length);
        }
    },
    methods: {
        getValueForId(id) {
            let values = this.condition.values.filter(object => {
                return parseInt(object.value) === parseInt(id);
            });

            if (values.length > 0) {
                return values[0];
            }

            return {
                name: null
            }
        },
        onChange(values) {
            this.$emit('filter-update', {
                fieldName: this.condition.fieldName,
                values: values
            });
        },
        onSearch(value) {
            this.setDefaultFilterCondition();

            if (this.filterCondition.objects.length > 0) {
                let values = [];

                this.filterCondition.objects = this.filterCondition.objects.filter((obj) => {
                    if (obj.filter_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                        values.push(this.filterCondition.values.find(item => parseInt(item.value) === obj.id));
                        return true;
                    }
                });

                this.filterCondition.values = values;
            }

            if (this.filterCondition.objects.length === 0) {
                this.setDefaultFilterCondition();
            }
        },
        setDefaultFilterCondition() {
            this.filterCondition = {
                ...this.condition
            }
        }
    },
    watch: {
        condition: {
            deep: true,
            handler() {
                this.setDefaultFilterCondition();
            }
        },
        currentFilter: {
            deep: true,
            handler(newValue) {
                this.selected = newValue;
            }
        },
        selected(newValue) {
            this.$emit('filter-update', {
                fieldName: this.condition.fieldName,
                values: newValue
            });
        }
    }
}
</script>