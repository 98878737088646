<template>
  <form
      class="align-items-center justify-content-start d-flex"
      method="post"
      :action="action"
      @submit.prevent="addToCart"
  >
    <brku-quantity-input @quantity-changed="onQuantityChanged" :quantity="quantity"></brku-quantity-input>
    <input type="hidden" :value="quantity"/>
    <input type="hidden" :value="token"/>
    <button class="btn btn-custom btn-primary has--icon-arrow-right" type="submit" ref="button">
      <span :class="{'invisible' : loading}">{{ $t('site.ui.product_item.add_to_cart') }}</span>
      <span class="btn-loader" :class="{'invisible' : !loading}"><i
          class="bk-spin fa-solid fa-circle-notch"></i></span>
    </button>
    <div class="animated-circle" ref="circle" :class="{'is--transforming': animating}">
      {{ quantity }}
    </div>
    <brku-add-to-cart-modal
        v-if="false"
        :products="products"
        :cart-url="cartUrl"></brku-add-to-cart-modal>
  </form>
</template>
<script>
export default {
  props: {
    action: {
      type: String,
      default: ''
    },
    token: {
      type: String,
      default: ''
    },
    cartUrl: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    productId: null,
    products: [],
    showModal: false,
    loading: false,
    quantity: 1,
    animating: false
  }),
  mounted() {
    this.$nextTick(this.setInitialCirclePosition);
  },
  methods: {
    setInitialCirclePosition() {
      const {button, circle} = this.$refs;
      const viewportOffset = button.getBoundingClientRect();

      circle.style.height = button.clientHeight + 'px';
      circle.style.width = button.clientHeight + 'px';
      circle.style.borderRadius = button.clientHeight + 'px';
      circle.style.top = (viewportOffset.bottom - button.clientHeight) + 'px';
      circle.style.left = (viewportOffset.left + button.clientWidth - circle.clientWidth) + 'px';
    },
    setFinalCirclePosition() {
      const {circle} = this.$refs;
      const {top, left, height, width} = this.$store.state.cart.previewPosition;

      circle.style.top = (top - (height / 2)) + 'px';
      circle.style.left = (left + (width / 2)) + 'px';
    },
    addToCart() {
      this.loading = true;
      this.animating = true;
      this.setFinalCirclePosition();

      const promise = new Promise(resolve => setTimeout(() => resolve(), 2000));

      promise.then(() => {
        this.animating = false;
      });

      axios.post(this.action, {
        coreshop_add_to_cart: {
          cartItem: {
            quantity: this.quantity
          },
          _token: this.token
        }
      })
          .then(res => res.data)
          .then(res => {
            this.loading = false;
            if (res.state === 'success') {
              this.productId = res.productId;

              this.$store.commit('changeCart', res.cart);

              this.$toast.clear();
              this.$toast.open({
                message: this.$t('cart.ui.added_successfully'),
                type: 'success'
              });

              this.loadModal();
            }

            if (res.state === 'error') {
              this.$toast.clear();
              this.$toast.open({
                message: res.message[0],
                type: res.state
              });
            }
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
          });
    },
    loadModal() {
      axios.get('/de/shop/cart/add/modal/' + this.productId)
          .then(res => res.data)
          .then(res => {
            this.products = res.products;
            this.showModal = true;
          })
          .catch(err => {
            console.log(err);
          });
    },
    onQuantityChanged(value) {
      this.quantity = value;
    }
  }
}
</script>
<style lang="scss" scoped>
.animated-circle {
  height: 15px;
  width: 15px;
  background: var(--primary);
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 5;
  font-size: 12px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s cubic-bezier(0.72, 0.22, 0.85, 0.4);

  &.is--transforming {
    visibility: visible;
    opacity: 1;
  }
}
</style>