var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-tabs-wrapper position-relative pt-5" }, [
    _c("div", { staticClass: "container" }, [
      _c("ul", { staticClass: "nav nav-tabs d-flex justify-content-center" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "span",
            {
              class: {
                "nav-link text-break": true,
                active: _vm.mode === "review-list",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("set-mode", "review-list")
                },
              },
            },
            [
              _c("i", { staticClass: "fa-solid fa-star" }),
              _vm._v(" "),
              _c("span", { staticClass: "nav-link-text" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.product_review.list"))),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "span",
            {
              class: {
                "nav-link text-break": true,
                active: _vm.mode === "review-form",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("set-mode", "review-form")
                },
              },
            },
            [
              _c("i", { staticClass: "bk-i bk-i-create-rating" }),
              _vm._v(" "),
              _c("span", { staticClass: "nav-link-text" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.product_review.create"))),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }