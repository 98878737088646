let scrollpos = window.scrollY
const navigationElements = document.getElementsByClassName("navbar-transparent");

if(navigationElements.length > 0) {
    const navigation = navigationElements[0];

    window.addEventListener('scroll', function() {
        if (!document.body.classList.contains('show-cart')){
            scrollPosition = window.scrollY;

            if (scrollPosition >= 150) {
                navigation.classList.remove("is--transparent")
                return
            }

            navigation.classList.add("is--transparent")
        }
    })
}

