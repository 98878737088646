var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex align-items-center justify-content-center flex-column",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "login-form mb-4 d-flex align-items-center justify-content-center flex-column",
        },
        [
          _c("div", { staticClass: "login-title text-center" }, [
            _vm._v(_vm._s(_vm.$t("site.form.login.choose_branch"))),
          ]),
          _vm._v(" "),
          _vm._l(_vm.branches, function (branch) {
            return _c(
              "div",
              {
                key: branch.id,
                staticClass: "company-select",
                class: { "is--active": branch.id == _vm.selectedBranch },
                on: {
                  click: function ($event) {
                    return _vm.selectBranch(branch)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(branch.name) + "\n    ")]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-custom btn-primary has--icon-arrow-right mb-2",
        },
        [_vm._v(_vm._s(_vm.$t("site.btn.next")))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }