var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "production-process-slider container pt-5 pb-4" },
    [
      _c(
        "div",
        { ref: "slider", staticClass: "keen-slider" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "slider-navigation-wrapper d-flex justify-content-center lg:mt-5",
        },
        [
          _c(
            "div",
            {
              class: {
                arrow: true,
                "slider-navigation is--prev mr-3": true,
                "is--disabled": _vm.current === 0,
              },
              on: {
                click: function ($event) {
                  return _vm.slider.prev()
                },
              },
            },
            [_c("i", { staticClass: "fa-solid fa-angle-left" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                arrow: true,
                "slider-navigation is--next": true,
                "is--disabled": _vm.current === _vm.totalCount - 1,
              },
              on: {
                click: function ($event) {
                  return _vm.slider.next()
                },
              },
            },
            [_c("i", { staticClass: "fa-solid fa-angle-right" })]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }