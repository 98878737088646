<template>
    <div>
        <div class="filter-body" v-for="condition in Object.keys(conditions)" v-if="conditions[condition].fieldName != 'branches'">
            <a
                :href="currentRoute"
                class="filter-body__checkbox d-flex justify-content-between"
                :class="{'is--active': currentCategory == 15}"
            >
                <div class="checkbox-content">
                    <div class="custom-control custom-checkbox d-flex">
                        <input type="checkbox" class="custom-control-input" :checked="currentCategory == 15">
                        <label class="custom-control-label">
                            {{ $t('intern.filter.show_all') }}
                        </label>
                    </div>
                </div>
                <div class="checkbox-count">

                </div>
            </a>
            <a
                :href="'?parentCategoryIds=' + item.value" v-for="item in orderBy(conditions[condition].values, 'count')"
                :key="item.value"
                v-if="item.value != 15"
                class="filter-body__checkbox d-flex justify-content-between"
                :class="{'is--active': item.value == currentCategory}"
            >
                <div class="checkbox-content">
                    <div class="custom-control custom-checkbox d-flex">
                        <input type="checkbox" class="custom-control-input" :checked="item.value == currentCategory">
                        <label class="custom-control-label">
                            {{ getObjectForId(conditions[condition], item.value).filter_name }}
                        </label>
                    </div>
                </div>
                <div class="checkbox-count">
                    ({{ item.count }})
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductStockCategories',
    props: {
        currentCategory: {
            type: Number,
            default: 0
        },
        conditions: {
            type: Object,
            default: {},
        },
    },
    methods: {
        getObjectForId(condition, id) {
              let objects = condition.objects.filter(object => {
                object.fieldName = condition.fieldName;

                 return parseInt(object.id) === parseInt(id);
             });

             if (objects.length > 0) {
                 return objects[0];
             }

            return {
                filter_name: null
            }
        },
        orderBy(values, key) {
            return _.orderBy(values, key).reverse()
        }
    },
    computed: {
        currentRoute() {
            return window.location.pathname;
        }
    }
}
</script>