var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("brku-current-filter", {
        attrs: {
          conditions: _vm.conditions,
          "current-filter": _vm.currentFilter,
        },
        on: {
          "filter-update": _vm.onUpdateFilter,
          "remove-all": _vm.onRemoveAll,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "product-filter__item has--no-border d-xl-none d-flex justify-content-end",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "close-filter d-flex align-items-center justify-content-center",
              on: { click: _vm.close },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("site.ui.close")) +
                  "\n            "
              ),
              _c("i", { staticClass: "fa-solid fa-xmark icon-close-filter" }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.conditions, function (condition) {
        return _c(_vm.filterTypeToComponentName(condition.type), {
          key: condition.fieldName,
          tag: "component",
          attrs: {
            condition: condition,
            "current-filter": _vm.currentFilter[condition.fieldName],
          },
          on: { "filter-update": _vm.onUpdateFilter },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }