<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    title: {
      type: String,
      default: null,
    },
    labels: {
      type: Array,
      default: [],
    },
    values: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      datacollection: {
        labels: this.labels,
        datasets: [
          {
            label: this.title,
            backgroundColor: "#cc9e67",
            pointBackgroundColor: "#cc9e67",
            borderWidth: 0,
            data: this.values,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  },
};
</script>