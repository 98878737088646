<template>
    <div class="shop d-md-flex d-block">
        <brku-loader-brand v-if="loading"></brku-loader-brand>
        <div class="product-filter" :class="{'is--open' : showFilterModal}">
            <brku-grid-filter
                :conditions="filter.conditions"
                :current-filter="filter.currentFilter"
                @close="showFilterModal = false"
                @change="onUpdateFilter"
                @remove-all="onRemoveAll"
            ></brku-grid-filter>
        </div>
        <div class="product-grid flex-grow-1">
            <brku-current-filter
                v-if="filter.conditions && filter.currentFilter"
                class="has--background"
                :conditions="filter.conditions"
                :current-filter="filter.currentFilter"
                @filter-update="onUpdateFilter"
                @remove-all="onRemoveAll"
            ></brku-current-filter>
            <div class="container-fluid">
                <div v-html="renderedBreadcrumbHtml"></div>
                <div
                    class="d-flex flex-column flex-xl-row justify-content-between align-items-xl-center align-items-start mb-0 mb-xl-5">
                    <div class="shop-result order-last order-xl-first mt-3 mt-xl-0">
                        {{ $t('site.ui.results_label', {range: itemsRange, count: totalCount}) }}
                    </div>
                    <div class="product-filter__outer d-flex order-first order-xl-last">
                        <button @click="showFilterModal = !showFilterModal"
                                class="product-filter__toggle d-xl-none mr-3">
                            <i class="bk-i bk-i-filter"></i> Filter
                        </button>
                        <brku-sort-type-select
                            v-model="controls.sort"
                            :valid-sort-elements="validSortElements"
                            @input="sortList"
                        ></brku-sort-type-select>
                    </div>
                </div>
                <brku-extended-render-html :html="gridHtml" :key="gridHtml"></brku-extended-render-html>
                <brku-pagination
                    :number-of-pages="controls.numberOfPages"
                    :page="controls.page"
                    @paginate="onPaginate"
                ></brku-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import queryString from 'query-string';
import AddToCart from '../cart/AddToCart';
import GridFilter from '../filter/GridFilter';
import ExtendedRenderHtml from "../ExtendedRenderHtml"

export default {
    components: {
        ExtendedRenderHtml,
        AddToCart,
        GridFilter
    },
    name: 'ProductGridWithFilter',
    props: {
        category: Object,
        conditions: [Object, Array],
        currentFilter: [Object, Array],
        page: Number,
        path: String,
        perPage: Number,
        perPageAllowed: Array,
        renderedBreadcrumbHtml: String,
        renderedGridHtml: String,
        sort: String,
        totalItems: Number,
        type: String,
        validSortElements: Array
    },
    data() {
        return {
            loading: false,
            controls: {
                page: null,
                perPage: null,
                sort: null
            },
            filter: {
                conditions: [],
                currentFilter: null
            },
            showFilterModal: false,
            gridHtml: null,
            itemsRange: null,
            totalCount: null
        }
    },
    created() {
        this.controls.numberOfPages = Math.ceil(this.totalItems / this.perPage);
        this.controls.page = parseInt(this.page);
        this.controls.perPage = parseInt(this.perPage);
        this.controls.sort = this.sort;

        this.filter.conditions = this.conditions;
        this.filter.currentFilter = this.currentFilter;

        this.gridHtml = this.renderedGridHtml;
        this.itemsRange = this.setItemsRange();
        this.totalCount = this.totalItems;

        this.baseUrl = this.getUrl(this.getParams(false)) + '&page=';
    },
    methods: {
        onUpdateFilter(payload) {
            if (!payload.values) {
                this.$delete(this.filter.currentFilter, payload.fieldName);
            } else {
                this.filter.currentFilter[payload.fieldName] = payload.values;
            }

            this.controls.page = 1;

            this.loadData();
        },
        onPaginate(page) {
            this.controls.page = page;

            window.scrollTo({top: 0, left: 0, behavior: "smooth"});

            this.loadData();
        },
        onRemoveAll() {
            this.filter.currentFilter = null;

            this.loadData();
        },
        loadData() {
            this.loading = true;
            let params = this.getParams();

            this.persistToUrl(params);

            this.$http.get(this.path, {
                params: params
            })
                .then(res => {
                    this.loading = false;

                    this.gridHtml = res.data.gridHtml;
                    this.totalCount = parseInt(res.data.totalItemCount);

                    this.itemsRange = this.setItemsRange();

                    this.controls.page = parseInt(res.data.page);
                    this.controls.perPage = parseInt(res.data.perPage);
                    this.controls.numberOfPages = Math.ceil(this.totalCount / this.controls.perPage);
                    this.controls.sort = res.data.sort;

                    this.filter.conditions = res.data.conditions;
                    this.filter.currentFilter = res.data.currentFilter;

                    this.persistToUrl(this.getParams());
                })
        },
        sortList() {
            this.controls.page = 1;

            this.loadData();
        },
        persistToUrl(params) {
            window.history.pushState(params, document.title, this.getUrl(params))
        },
        setItemsRange() {
            let range = {
                min: 1,
                max: this.perPage
            };

            if (this.totalCount === 0) {
                return 0;
            }

            if (this.totalCount === 1) {
                return range.min;
            }

            if (this.controls.page > 1) {
                let minValue = (this.controls.page - 1) * this.perPage + 1;

                range.min = minValue;

                if (minValue + this.perPage > this.totalCount) {
                    range.max = this.totalCount;
                } else {
                    range.max = minValue + this.perPage;
                }
            }

            if (this.totalCount < range.max) {
                range.max = this.totalCount;
            }

            return range.min + '-' + range.max;
        },
        getParams(includePage = true) {
            let params = {...this.controls, ...this.filter.currentFilter};

            if (!includePage) {
                delete params.page;
            }

            return params;
        },
        getUrl(params) {
            return location.protocol + '//' + location.host + location.pathname + '?' + queryString.stringify(params, {
                arrayFormat: 'bracket'
            });
        }
    },
    watch: {
        totalCount() {
            this.itemsRange = this.setItemsRange();
        }
    }
}
</script>