<template>
    <div
        class="col-6 d-flex align-items-center justify-content-center has--grey-background flex-column"
    >
        <div v-if="showBranchSelect">
            <branch-select :branches="branches" :selected-branch="selectedBranch" @select-branch="selectBranch"/>
        </div>
        <form
            v-if="!showBranchSelect"
            method="post"
            class="d-flex align-items-center justify-content-center flex-column"
            @submit.prevent="login"
        >
            <b-alert variant="danger" :show="error">{{ $t('site.ui.login.error') }}</b-alert>
            <div class="login-form mb-4">
                <div class="login-title text-center"></div>
                <div class="form-group">
                    <input type="text" v-model="pin" class="form-control input-card"
                           :placeholder="$t('site.form.login.employee_pin')">
                </div>
                <div class="form-group">
                    <input type="password" v-model="password" class="form-control input-card"
                           :placeholder="$t('site.form.login.password')">
                </div>
            </div>
            <button type="submit" class="btn btn-custom btn-primary has--icon-arrow-right mb-2">
                <span v-if="loading">{{ $t('site.ui.loading') }}</span>
                <span v-if="!loading">{{ $t('site.form.login.button') }}</span>
            </button>
        </form>
    </div>
</template>

<script>
import BranchSelect from "./BranchSelect";

export default {
    components: {BranchSelect},
    props: {
        action: {
            type: String,
            default: null
        },
    },
    data: () => ({
        user: {},
        loading: false,
        error: false,
        pin: null,
        password: null,
        branches: [],
        selectedBranch: null,
        showBranchSelect: false,
    }),
    methods: {
        selectBranch(id) {
            this.selectedBranch = id;
            this.$cookies.set('brku_employee_branch', id)
        },
        login() {
            this.loading = true;
            axios.post(this.action, {
                pin: this.pin,
                password: this.password,
            })
                .then(res => {
                    this.loading = false;
                    if (this.user) {
                        this.user = res.data.user;
                        this.error = false;
                        this.showBranchSelect = true;
                        this.branches = this.user.branches;
                        return;
                    }

                    this.error = true;
                })
                .catch(() => {
                    this.loading = false;
                    this.error = true;
                    this.showBranchSelect = false;
                });
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>