import Vue from 'vue';

import ReviewForm from "./ReviewForm";
import ReviewList from "./ReviewList";
import TabBar from './TabBar';
import TabContent from './TabContent';

Vue.component('brku-product-review-review-form', ReviewForm);
Vue.component('brku-product-review-review-list', ReviewList);
Vue.component('brku-product-review-tab-bar', TabBar);
Vue.component('brku-product-review-tab-content', TabContent);