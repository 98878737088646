<template>
  <div>
    <div class="wishlist-modal">
      <div class="wishlist-modal__inner position-relative">
        <div @click="close" class="close-wrapper position-absolute">
          <i class="fa-solid fa-xmark close-icon"></i>
        </div>
        <div class="wishlist-title d-flex justify-content-md-center justify-content-start">{{ $t('site.wishlist.headline') }}</div>
        <wishlist-modal-entry
            v-for="product in products"
            :product="product.product"
            :action-remove="removeAction"
            :key="product.id"
            @set-wishlist="setWishlist"
        ></wishlist-modal-entry>
        <b-alert v-if="products.length == 0 && loading == false" show variant="primary">{{ $t('site.wishlist.empty') }}</b-alert>
          <div v-if="loading" class="d-flex justify-content-center p-3">
              <i class="bk-spin fa-solid fa-circle-notch"></i>
          </div>
      </div>
    </div>
    <div @click="close" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import WishlistModalEntry from "./WishlistModalEntry";
export default {
    name: 'WishlistModal',
    components: {WishlistModalEntry},
    props: {
        summaryAction: {
            type: String,
            default: null
        },
        removeAction: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            loading: false,
            products: []
        }
    },
    mounted() {
        this.fetchProducts()
    },
    methods: {
        fetchProducts() {
            this.loading = true;

            axios.get(this.summaryAction)
                .then(res => res.data)
                .then(res => {
                    this.loading = false;
                    this.products = res.products;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        setWishlist(payload) {
            this.products = payload;
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>