var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "incoming-order__card" }, [
        _c(
          "div",
          { staticClass: "card-header d-flex align-items-center is--red" },
          [
            _c("p", { staticClass: "card-header__title" }, [
              _vm._v("Offene Bestellung"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-header__count" }, [
              _vm._v("(" + _vm._s(_vm.scopeNewOrders.length) + ")"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          _vm._l(_vm.scopeNewOrders, function (order) {
            return _c("brku-order-item-card", {
              key: order.id,
              attrs: {
                "show-order-number": true,
                "show-pickup-date": true,
                order: order,
                "order-state-action": _vm.orderStateAction,
              },
              on: { "set-data": _vm.setData },
            })
          }),
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "incoming-order__card" }, [
        _c(
          "div",
          { staticClass: "card-header d-flex align-items-center is--orange" },
          [
            _c("p", { staticClass: "card-header__title" }, [
              _vm._v("Zur Abholung bereit"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-header__count" }, [
              _vm._v("(" + _vm._s(_vm.scopeReadyOrders.length) + ")"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          _vm._l(_vm.scopeReadyOrders, function (order) {
            return _c("brku-order-item-card", {
              key: order.id,
              attrs: {
                "show-order-number": true,
                "show-pickup-date": true,
                order: order,
                "order-state-action": _vm.orderStateAction,
              },
              on: { "set-data": _vm.setData },
            })
          }),
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "incoming-order__card" }, [
        _c(
          "div",
          { staticClass: "card-header d-flex align-items-center is--green" },
          [
            _c("p", { staticClass: "card-header__title" }, [
              _vm._v("Abgeschlossen"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-header__count" }, [
              _vm._v("(" + _vm._s(_vm.scopeReadyOrders.length) + ")"),
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fa-solid fa-check card-header__icon" }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          _vm._l(_vm.scopeCompletedOrders, function (order) {
            return _c("brku-order-item-card", {
              key: order.id,
              attrs: {
                "show-product-amount": true,
                order: order,
                "order-state-action": _vm.orderStateAction,
              },
              on: { "set-data": _vm.setData },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }