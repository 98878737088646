<template>
    <div class="tab-content mt-5">
        <div
            :class="{ 'tab-pane': true, 'active': mode === 'review-list' }"
            id="product-review-list"
        >
            <brku-product-review-review-list :reviews="reviews"/>
        </div>
        <div
            :class="{ 'tab-pane': true, 'active': mode === 'review-form' }"
        >
            <brku-product-review-review-form :action="action" @set-mode="$emit('set-mode', $event)" :user="user"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabContent',
    props: {
        action: String,
        mode: String,
        reviews: Array | Object,
        user: {
            type: Object,
            default: null
        }
    }
}
</script>