var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClass }, [
    _c("div", { staticClass: "form-group" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.scopeValue,
              expression: "scopeValue",
            },
          ],
          staticClass: "form-control select-card",
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.scopeValue = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
            ],
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c(
            "option",
            { key: option.value, domProps: { value: option.id } },
            [_vm._v(_vm._s(option.name))]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }