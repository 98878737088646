<template>
  <div>
    <div class="wishlist-modal">
      <div class="wishlist-modal__inner position-relative">
        <div @click="close" class="close-wrapper position-absolute">
          <i class="fa-solid fa-xmark close-icon"></i>
        </div>
        <div class="wishlist-title d-flex justify-content-md-center justify-content-start">{{ 'Willst du noch was mitnehmen' }}</div>
        <div v-for="product in products">
          <img :src="product.image"/>
          <span>{{ product.name }}</span>
          <span>{{ product.price }}</span>
          <a href="#">In den Brotkorb</a>
        </div>
        <b-alert v-if="products.length == 0" show variant="primary">{{ $t('site.wishlist.empty') }}</b-alert>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
    props: {
        products: {
            type: Array,
            default: []
        },
        cartUrl: {
            type: String,
            default: ''
        }
    }
}
</script>