<template>
    <div class="cart-item">
        <div class="row">
            <div class="col-md-7 col-12 d-flex align-items-center">
                <b-img-lazy
                    v-if="item.product.cartImage"
                    class="item-image"
                    :src="item.product.cartImage"/>
                <b-img-lazy v-else
                            class="item-image"
                            :src="defaultImage"/>
                <div class="item-info">
                    <p class="item-meta">
                        {{ $t('site.ui.sku_short') }} {{ product.sku }}
                    </p>
                    <p class="item-title">
                        {{ product.name }}
                    </p>
                    <p class="item-availability">
                        <span class="circle circle-green"></span> {{ $t('site.ui.cart.available') }}
                    </p>
                </div>
            </div>
            <div class="col-md-5 col-12 d-flex align-items-center justify-content-between">
                <div class="item-quantity-select">
                    <brku-quantity-input
                        @quantity-changed="onQuantityChanged(item, $event)"
                        :quantity="item.quantity"
                    ></brku-quantity-input>
                    <a href="#" class="item-remove" @click.prevent="onRemove(item)">
                        <i class="fa-solid fa-xmark mr-1"></i> {{ $t('site.ui.remove') }}
                    </a>
                </div>

                <div class="item-price-info">
                    <div class="item-price">
                        {{ item.itemGrossFormatted }} x {{ item.quantity }}
                    </div>
                    <div class="item-total-price">
                        {{ item.totalItemGrossFormatted }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'

export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    computed: {
        item() {
            return this.value;
        },
        product() {
            return this.item.product;
        },
        defaultImage() {
            return require('../../../images/product-default-image.png');
        }
    },
    methods: {
        onRemove(item) {
            this.$emit('remove', item);
        },
        onQuantityChanged(item, quantity) {
            this.doQuantityChange(this, item, quantity)
        },
        doQuantityChange: _.debounce((vm, item, quantity) => {
            vm.$emit('quantity-changed', {
                item,
                quantity
            })
        })
    }
}
</script>
<style lang="scss">
@import "../../../scss/common/variables";
@import "../../../scss/common/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.cart-item {
    border-bottom: 1px dashed #C0C0C0;
    padding: 24px 0;

    .item-image {
        width: 120px;
        height: 120px;
        object-position: center;
        object-fit: cover;
        margin-right: 40px;

        @include media-breakpoint-down(md) {
            height: 75px;
            width: 75px;
            margin-right: 10px;
        }
    }

    .item-info {
        .item-meta {
            font-size: 14px;
            line-height: 16px;
            color: #A2A2A2;
            margin: 6px 0px;

            @include media-breakpoint-down(md) {
                font-size: 12px;
                line-height: 14px;
            }
        }

        .item-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            margin: 6px 0px;

            @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .item-availability {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            justify-content: flex-start;


            .circle {
                display: block;
                margin-right: 8px;
                width: 8px;
                height: 8px;
                border-radius: 8px;

                &-green {
                    background: #A8D02C;
                }
            }
        }
    }

    .item-quantity-select {
        display: flex;
        flex-direction: column;
        align-items: center;

        .quantity-input {
            margin: 0;
        }

        .item-remove {
            text-decoration: none;
            margin-top: 5px;
            font-weight: 600;
            font-size: 14px;
            color: var(--dark);

            @include media-breakpoint-down(md) {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .item-price-info {
        text-align: right;

        .item-price {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 10px;
        }

        .item-total-price {
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
        }
    }
}
</style>