<template>
    <form method="post" @submit.prevent="resetPassword" class="password-reset-form">
        <div class="password-reset-title text-center">{{ $t('coreshop.ui.password_update') }}</div>
        <div class="form-group d-flex justify-content-center">
            <input type="password" v-model="password" required="required"
                   :placeholder="$t('coreshop.form.customer.password')" class="form-control input-card">
        </div>
        <div class="form-group d-flex justify-content-center">
            <input type="password" v-model="password_repeat" required="required"
                   :placeholder="$t('coreshop.form.customer.password_repeat')" class="form-control input-card">
        </div>
        <input type="hidden" id="_token" name="_token" value="token"/>
        <b-button type="submit" variant="primary" class="btn-custom has--icon-arrow-right mb-2 mx-auto">
            <span v-if="!loading">{{ $t('coreshop.ui.reset') }}</span>
            <span v-if="loading">{{ $t('coreshop.ui.loading') }}</span>
        </b-button>
    </form>
</template>

<script>
export default {
    props: {
        action: {
            type: String,
            default: null
        },
        token: {
            type: String,
            default: null
        }
    },
    data: () => ({
        password: null,
        password_repeat: null,
        loading: false
    }),
    methods: {
        resetPassword() {
            this.loading = true;
            axios.post(this.action, {
                password: {
                    'first': this.password,
                    'second': this.password_repeat
                },
                _token: this.token
            })
                .then(res => res.data)
                .then(res => {
                    this.loading = false;
                    this.password = null;
                    this.password_repeat = null;

                    this.$toast.clear();
                    this.$toast.open({
                        message: res.message,
                        type: 'success'
                    });

                    setTimeout(() => {
                        window.location.href = res.redirectUrl;
                    }, 1000);
                })
                .catch(err => {
                    this.loading = false;

                    this.$toast.clear();
                    this.$toast.open({
                        message: err.response.data.message,
                        type: 'error'
                    });
                });
        }
    }
}
</script>