var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "row pt-md-0 pt-3 position-relative z-5",
      attrs: { method: "post" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _vm.formSubmitted
        ? _c("div", { staticClass: "inquiry-overlay" }, [
            _c(
              "svg",
              {
                staticClass: "inquiry-overlay__icon",
                attrs: {
                  fill: "none",
                  stroke: "currentColor",
                  viewBox: "0 0 24 24",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("strong", { staticClass: "inquiry-overlay__title" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.thanks_for_your_inquiry"))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-12" }, [
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has--input": _vm.firstName != null },
          },
          [
            _c("label", { staticClass: "label-card" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.form.firstname")) + "*"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.firstName,
                  expression: "firstName",
                },
              ],
              staticClass: "form-control input-card",
              attrs: {
                type: "text",
                placeholder: `${_vm.$t("site.ui.form.firstname")}*`,
                required: "",
              },
              domProps: { value: _vm.firstName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.firstName = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-12" }, [
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has--input": _vm.lastName != null },
          },
          [
            _c("label", { staticClass: "label-card" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.form.lastname")) + "*"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lastName,
                  expression: "lastName",
                },
              ],
              staticClass: "form-control input-card",
              attrs: {
                type: "text",
                placeholder: `${_vm.$t("site.ui.form.lastname")}*`,
                required: "",
              },
              domProps: { value: _vm.lastName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.lastName = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-12" }, [
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has--input": _vm.email != null },
          },
          [
            _c("label", { staticClass: "label-card" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.form.email")) + "*"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "form-control input-card",
              attrs: {
                type: "email",
                placeholder: `${_vm.$t("site.ui.form.email")}*`,
                required: "",
              },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-12" }, [
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has--input": _vm.phoneNumber != null },
          },
          [
            _c("label", { staticClass: "label-card" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.form.phone"))),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.phoneNumber,
                  expression: "phoneNumber",
                },
              ],
              staticClass: "form-control input-card",
              attrs: {
                type: "text",
                placeholder: _vm.$t("site.ui.form.phone"),
              },
              domProps: { value: _vm.phoneNumber },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.phoneNumber = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has--input": _vm.message != null },
          },
          [
            _c("label", { staticClass: "label-card" }, [
              _vm._v(_vm._s(_vm.$t("site.ui.form.your_message")) + "*"),
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message,
                  expression: "message",
                },
              ],
              staticClass: "form-control textarea input-card",
              attrs: {
                rows: "3",
                placeholder: `${_vm.$t("site.ui.form.your_message")}*`,
                required: "",
              },
              domProps: { value: _vm.message },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.message = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c("p", { staticClass: "google-recaptcha__text" }, [
          _vm._v(_vm._s(_vm.$t("site.ui.form.google_recaptcha.text")) + " "),
          _c("a", { attrs: { href: "https://policies.google.com/privacy" } }, [
            _vm._v(
              _vm._s(_vm.$t("site.ui.form.google_recaptcha.privacy_policy"))
            ),
          ]),
          _vm._v(" and "),
          _c("a", { attrs: { href: "https://policies.google.com/terms" } }, [
            _vm._v(
              _vm._s(_vm.$t("site.ui.form.google_recaptcha.terms_of_service"))
            ),
          ]),
          _vm._v(
            " " + _vm._s(_vm.$t("site.ui.form.google_recaptcha.apply")) + "."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "button",
          {
            staticClass:
              "btn btn-custom btn-primary has--icon-arrow-right mb-2",
          },
          [
            !_vm.loading
              ? _c("span", [_vm._v(_vm._s(_vm.$t("site.ui.form.submit")))])
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c("span", [_vm._v(_vm._s(_vm.$t("site.ui.loading")))])
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }