var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-images d-flex flex-column flex-md-row" },
    [
      _c(
        "div",
        {
          staticClass:
            "product-images__thumbnails order-last order-md-first d-md-block d-flex",
        },
        [
          _c(
            "div",
            { ref: "thumbnailSlider", staticClass: "keen-slider" },
            _vm._l(_vm.thumbnails, function (thumbnail, index) {
              return _c("img", {
                key: index,
                class: {
                  "keen-slider__slide image-pagination": true,
                  "is--active": _vm.current === index,
                },
                attrs: { src: thumbnail },
                on: {
                  click: function ($event) {
                    return _vm.slider.moveToSlideRelative(index)
                  },
                },
              })
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "product-images__slider position-relative order-first order-md-last",
        },
        [
          _c("div", { staticClass: "product-images__slides-wrapper" }, [
            _c(
              "div",
              { ref: "slider", staticClass: "keen-slider" },
              _vm._l(_vm.images, function (image, index) {
                return _c("img", {
                  key: index,
                  class: {
                    "keen-slider__slide product-images__slider-image": true,
                    "is--active": _vm.current === index,
                  },
                  attrs: { src: image },
                })
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "slider-navigation-wrapper position-absolute d-md-flex d-none",
            },
            [
              _c(
                "div",
                {
                  class: {
                    arrow: true,
                    "slider-navigation is--prev mr-3": true,
                    "is--disabled": _vm.current === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.slider.prev()
                    },
                  },
                },
                [_c("i", { staticClass: "fa-solid fa-angle-left" })]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    arrow: true,
                    "slider-navigation is--next": true,
                    "is--disabled": _vm.current === _vm.totalCount - 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.slider.next()
                    },
                  },
                },
                [_c("i", { staticClass: "fa-solid fa-angle-right" })]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }