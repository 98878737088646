<template>
    <div class="payment-provider-select">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio v-model="paymentProvider" name="coreshop[paymentProvider]"
                                  class="provider-item"
                                  v-for="provider in paymentProviders" :value="provider.id">
                        <b-img-lazy :src="provider.logoSrc" class="provider-logo"></b-img-lazy>
                        <span class="provider-name">{{ provider.translations[lang].title }}</span>
                    </b-form-radio>
                </b-form-group>
                <div class="d-flex justify-content-center my-2 my-md-4">
                    <b-button type="submit" variant="primary" class="btn-custom" :disabled="paymentProvider == null">
                        {{ $t('site.ui.checkout.continue') }}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selected: {
            type: String
        },
        paymentProviders: {
            type: Array
        }
    },
    data: () => ({
        paymentProvider: null,
    }),
    created() {
        this.paymentProvider = this.selected;
    },
    computed: {
        lang() {
            return document.documentElement.lang;
        }
    },
    methods: {}
}
</script>

<style lang="scss">
.payment-provider-select {
    .provider-item {
        padding: 20px 40px;
        border: 1px solid #ccc;
        margin-bottom: 10px;

        .custom-control-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .provider-logo {
                height: 20px;
                width: auto;
                margin-right: 15px;
            }

            .provider-name {
                display: block;
                font-weight: bold;
                text-align: right;
            }
        }
    }
}
</style>