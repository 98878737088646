var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-md-6 col-12 mt-3 mb-3" }, [
    _c("div", { staticClass: "bg-white p-3 shadow-sm account-address-card" }, [
      _vm.address.id == _vm.shippingAddress.id
        ? _c("p", { staticClass: "font-weight-bold mb-2" }, [
            _vm._v(_vm._s(_vm.$t("site.ui.default_address"))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-end" },
        [
          _c("div", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.address.firstname) +
                " " +
                _vm._s(_vm.address.lastname)
            ),
            _c("br"),
            _vm._v(
              "\n                " +
                _vm._s(_vm.address.street) +
                " " +
                _vm._s(_vm.address.number)
            ),
            _c("br"),
            _vm._v(
              "\n                " +
                _vm._s(_vm.address.postcode) +
                " " +
                _vm._s(_vm.address.city)
            ),
            _c("br"),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "span",
              { staticClass: "text-primary", on: { click: _vm.deleteAddress } },
              [_c("i", { staticClass: "fa-solid fa-trash" })]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("small", { staticClass: "mt-3 d-block" }, [
        _c(
          "span",
          {
            staticClass: "account-address-card__btn d-block mb-1",
            on: { click: _vm.setShippingAddress },
          },
          [_vm._v(_vm._s(_vm.$t("Als Standard-Lieferadresse verwenden")))]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "account-address-card__btn d-block",
            on: { click: _vm.setInvoiceAddress },
          },
          [_vm._v(_vm._s(_vm.$t("Als Standard-Rechnungsadresse verwenden")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }