var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-content mt-5" }, [
    _c(
      "div",
      {
        class: { "tab-pane": true, active: _vm.mode === "review-list" },
        attrs: { id: "product-review-list" },
      },
      [
        _c("brku-product-review-review-list", {
          attrs: { reviews: _vm.reviews },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: { "tab-pane": true, active: _vm.mode === "review-form" } },
      [
        _c("brku-product-review-review-form", {
          attrs: { action: _vm.action, user: _vm.user },
          on: {
            "set-mode": function ($event) {
              return _vm.$emit("set-mode", $event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }