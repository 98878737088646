var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "gmap-info-window",
        {
          attrs: { position: _vm.coordinates, opened: _vm.infoWinOpen },
          on: {
            closeclick: function ($event) {
              _vm.infoWinOpen = false
            },
          },
        },
        [
          _c("div", { staticClass: "card map-badge__card d-md-flex d-none" }, [
            _c("a", { attrs: { href: _vm.branch.url } }, [
              _c("img", {
                staticClass: "card-img-top",
                attrs: {
                  src: _vm.branch.image,
                  alt: _vm.branch.name,
                  title: _vm.branch.name,
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body position-relative" }, [
              _c("span", { staticClass: "map-card__title" }, [
                _vm._v(_vm._s(_vm.branch.name)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "map-card__sub-title" }, [
                _vm._v(
                  _vm._s(_vm.branch.street) +
                    " " +
                    _vm._s(_vm.branch.number) +
                    ", " +
                    _vm._s(_vm.branch.postalCode) +
                    "\n          " +
                    _vm._s(_vm.branch.city)
                ),
              ]),
              _vm._v(" "),
              _vm.branch.openingTimes.isOpen
                ? _c("div", { staticClass: "map-card__opening is--open" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("site.ui.openingTimes.open")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.branch.openingTimes.isOpen
                ? _c("div", { staticClass: "map-card__opening is--closed" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("site.ui.openingTimes.closed")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.branch.openingTimes.today !== undefined
                ? _c("div", { staticClass: "map-card__open-time" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "site.ui.weekday." +
                              _vm.branch.openingTimes.today.weekDay
                          )
                        ) + ":"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.branch.openingTimes.today.afternoonTimeStart &&
                    _vm.branch.openingTimes.today.morningTimeEnd
                      ? _c("span", [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.branch.openingTimes.today.morningTimeStart
                              ) +
                              " - " +
                              _vm._s(
                                _vm.branch.openingTimes.today.morningTimeEnd
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.branch.openingTimes.today.afternoonTimeStart
                              ) +
                              " - " +
                              _vm._s(
                                _vm.branch.openingTimes.today.afternoonTimeEnd
                              ) +
                              "\n                      "
                          ),
                        ])
                      : !_vm.branch.openingTimes.today.afternoonTimeStart &&
                        !_vm.branch.openingTimes.today.afternoonTimeEnd
                      ? _c("span", [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.branch.openingTimes.today.morningTimeStart
                              ) +
                              " - " +
                              _vm._s(
                                _vm.branch.openingTimes.today.morningTimeEnd
                              ) +
                              "\n                      "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.branch.openingTimes.today.morningTimeStart
                              ) +
                              " - " +
                              _vm._s(
                                _vm.branch.openingTimes.today.afternoonTimeEnd
                              ) +
                              "\n                      "
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("gmap-marker", {
        attrs: { position: _vm.coordinates, clickable: true },
        on: {
          click: function ($event) {
            _vm.infoWinOpen = !_vm.infoWinOpen
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }