var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "password-reset-form",
      attrs: { method: "post" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.resetPassword.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "password-reset-title text-center" }, [
        _vm._v(_vm._s(_vm.$t("coreshop.ui.password_update"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group d-flex justify-content-center" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass: "form-control input-card",
          attrs: {
            type: "password",
            required: "required",
            placeholder: _vm.$t("coreshop.form.customer.password"),
          },
          domProps: { value: _vm.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group d-flex justify-content-center" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password_repeat,
              expression: "password_repeat",
            },
          ],
          staticClass: "form-control input-card",
          attrs: {
            type: "password",
            required: "required",
            placeholder: _vm.$t("coreshop.form.customer.password_repeat"),
          },
          domProps: { value: _vm.password_repeat },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password_repeat = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", id: "_token", name: "_token", value: "token" },
      }),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "btn-custom has--icon-arrow-right mb-2 mx-auto",
          attrs: { type: "submit", variant: "primary" },
        },
        [
          !_vm.loading
            ? _c("span", [_vm._v(_vm._s(_vm.$t("coreshop.ui.reset")))])
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("span", [_vm._v(_vm._s(_vm.$t("coreshop.ui.loading")))])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }