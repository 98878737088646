var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quantity-input input-group" }, [
    _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-custom btn-outline-secondary",
          attrs: { type: "button", disabled: _vm.decrementDisabled },
          on: { click: _vm.onDecrement },
        },
        [_c("i", { staticClass: "fa-solid fa-minus" })]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "number",
          required: "required",
          min: "0",
          step: "1",
          autocomplete: "off",
        },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.onInput($event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-custom btn-outline-secondary",
          attrs: { type: "button", disabled: _vm.incrementDisabled },
          on: { click: _vm.onIncrement },
        },
        [_c("i", { staticClass: "fa-solid fa-plus" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }