export const date = {
    getDate: (date = null, days = 0, month = 0) => {
        let newDate = new Date();

        if (date) {
            newDate = new Date(date);
        }

        if (days !== 0) {
            newDate.setDate(newDate.getDate() + (days));
        }

        if (month !== 0) {
            newDate.setMonth(newDate.getMonth() + (month));
        }

        return newDate;
    },
    getTime: (date, hour = 0) => {
        let currDate = new Date();

        currDate.setHours(currDate.getHours() + 1);

        date.setHours(0, 0, 0, 0);

        if (date > currDate || currDate.getHours() >= 15){
            return '09:00';
        }

        return ('0' + currDate.getHours()).slice(-2) + ':' + ('0' + currDate.getMinutes()).slice(-2);
    }
}