var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accordion", attrs: { role: "tablist" } },
    [
      _vm._l(_vm.carriers, function (carrier) {
        return _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c(
              "b-card-header",
              {
                attrs: {
                  role: "tab",
                  "header-class": [
                    _vm.isActive(carrier.identifier) ? "active" : "",
                    _vm.isAvailable(carrier.identifier)
                      ? ""
                      : "is--not-available",
                  ],
                },
                on: {
                  click: function ($event) {
                    return _vm.toggleCard(carrier.identifier)
                  },
                },
              },
              [
                _c("div", { staticClass: "left d-flex" }, [
                  _c("i", {
                    class: [
                      "bk-i ",
                      _vm.getIconName(carrier.identifier),
                      "order-type-icon",
                    ],
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "order-type-label" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(_vm.getOrderTypeLabel(carrier.identifier))
                        ) +
                        "\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.isAvailable(carrier.identifier)
                          ? _vm.$t("site.ui.shared.choose")
                          : _vm.$t("site.ui.carrier.not_available")
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              {
                attrs: {
                  id: carrier.identifier,
                  visible: _vm.isActive(carrier.identifier),
                  accordion: "my-accordion",
                  role: "tabpanel",
                },
              },
              [
                _c(
                  "b-card-body",
                  [
                    _vm.isAvailable(carrier.identifier)
                      ? _c("b-card-text", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "site.ui.order_type." +
                                  carrier.identifier +
                                  ".description"
                              )
                            ),
                          },
                        })
                      : _c("b-card-text", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "site.ui.checkout.contact_carrier_not_available"
                              )
                            ),
                          },
                        }),
                    _vm._v(" "),
                    _vm.isAvailable(carrier.identifier)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center my-2 my-md-4",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-custom",
                                attrs: { type: "submit", variant: "primary" },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t("site.ui.checkout.continue")
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: "coreshop[orderType]", type: "hidden" },
        domProps: { value: _vm.selectedOrderType },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: "coreshop[carrier]", type: "hidden" },
        domProps: { value: _vm.selectedCarrier },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }