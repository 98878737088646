<template>
    <div class="job-slider">
        <div ref="slider" class="job-slider__wrapper keen-slider">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import KeenSlider from 'keen-slider';

export default {
    mounted() {
        this.slider = new KeenSlider(this.$refs.slider, {
            slidesPerView: 1,
            spacing: 30,
            breakpoints: {
                "(min-width: 768px)": {
                    slidesPerView: 2
                },
                "(min-width: 992px)": {
                    slidesPerView: 3
                },
                "(min-width: 1200px)": {
                    slidesPerView: 3
                },
            },
        });
    },
    data() {
        return {
            slider: null
        }
    },
    methods: {
        prev() {
            this.slider.prev();
        },
        next() {
            this.slider.next();
        }
    }
}
</script>