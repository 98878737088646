<template>
    <base-condition :condition="condition" :current-filter="currentFilter" :show-search="false">
        <b-form-checkbox-group class="product-filter__options-wrapper" v-model="selected" :name="condition.fieldName" @change="onChange">
            <div class="product-filter__option-item" v-for="item in condition.values"
                 :key="condition.fieldName + item.value" v-if="item.value">
                <div class="option-item__content">
                    <b-form-checkbox :value="parseInt(item.value)">
                        {{ `${item.value} ${getLabel(item.value)}` }}
                        <div class="rating-stars">
                            <brku-star-rating active-color="#e7cb5e"
                                              border-color="#e7cb5e"
                                              inactive-color="#ffffff"
                                              :border-width="2"
                                              :increment="0.5"
                                              :inline="true"
                                              :rating="parseFloat(item.value)"
                                              :read-only="true"
                                              :rounded-corners="true"
                                              :show-rating="false"
                                              :star-size="15"
                            ></brku-star-rating>
                        </div>
                    </b-form-checkbox>
                </div>
                <div class="option-item__count">({{ item.count ? item.count : 0 }})</div>
            </div>
        </b-form-checkbox-group>
    </base-condition>
</template>

<script>
import BaseCondition from './BaseCondition';
import {BFormCheckbox, BFormCheckboxGroup} from 'bootstrap-vue';

export default {
    extends: BaseCondition,
    components: {
        BaseCondition,
        BFormCheckbox, BFormCheckboxGroup
    },
    data: () => ({
        selected: []
    }),
    created() {
        if (this.condition.currentValues) {
            this.selected = this.condition.currentValues.map(val => parseFloat(val));
        }
    },
    methods: {
        getLabel(value) {
            if (value === 1) {
                return this.$t('site.ui.rating.label.singular');
            }

            return this.$t('site.ui.rating.label.plural');
        },
        onChange(values) {
            this.$emit('filter-update', {
                fieldName: this.condition.fieldName,
                values: values
            });
        }
    },
    watch: {
        currentFilter: {
            deep: true,
            handler(newValue) {
                this.selected = newValue;
            }
        }
    }
}
</script>