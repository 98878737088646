<template>
    <b-modal
        :id="`recurring-order-modal-${id}`"
        dialog-class="recurring-order-modal"
        ok-only
        :title="$t('site.ui.account.recurring_order.modal_title')"
        @ok="save"
        :ok-disabled="okDisabled"
    >
        <template #modal-header-close>
            <i class="fa-solid fa-xmark close-icon" aria-hidden="true"></i>
        </template>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group datepicker">
                    <label>{{ $t('site.form.recurring_order.start_date') }}</label>
                    <b-form-datepicker
                        id="startDate"
                        :locale="locale"
                        v-bind="labels[locale] || {}"
                        v-model="order.startDate"
                        :date-format-options="{ weekday: 'short', day: '2-digit', month: 'long', year: 'numeric' }"
                        required
                    ></b-form-datepicker>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group datepicker">
                    <label>{{ $t('site.form.recurring_order.end_date') }}</label>
                    <b-form-datepicker
                        id="endDate"
                        :locale="locale"
                        v-bind="labels[locale] || {}"
                        v-model="order.endDate"
                        :date-format-options="{ weekday: 'short', day: '2-digit', month: 'long', year: 'numeric' }"
                        required
                    ></b-form-datepicker>
                </div>
            </div>
            <div class="col-12 mb-2 weekdays">
                <label>{{ $t('site.form.recurring_order.weekdays') }}</label>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col">
                                <b-form-checkbox-group v-model="order.weekdays" multiple>
                                    <b-form-checkbox v-for="(day, i) in weekdays" :value="i + 1" size="lg">
                                        {{ day }}
                                    </b-form-checkbox>
                                </b-form-checkbox-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group">
                    <label for="cart">{{ $t('site.form.recurring_order.cart') }}</label>
                    <b-form-select
                        class="form-control input-card"
                        id="cart"
                        v-model="order.cart"
                        :options="carts"
                        value-field="id"
                        text-field="customIdentifier"
                        required
                    ></b-form-select>
                </div>
            </div>
        </div>
        <template #modal-footer="{ ok }">
            <button type="button" class="btn btn-custom btn-primary m-0" v-if="!okDisabled" @click="ok()">OK</button>
        </template>
    </b-modal>
</template>
<script>
export default {
    props: {
        apiEndpoint: {
            type: String,
            default: ''
        },
        locale: {
            type: String,
            default: 'de'
        },
        carts: {
            type: Array,
            default: []
        },
        startDate: {
            type: String,
            default: null
        },
        endDate: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        cartId: {
            type: Number,
            default: null,
        },
        orderId: {
            type: Number,
            default: null,
        },
        orderItem: {
            type: Object,
            default: null
        }
    },
    computed: {
        weekdays() {
            return [
                'MO',
                'DI',
                'MI',
                'DO',
                'FR',
                'SA',
                'SO',
            ]
        },
        intervals() {
            return [
                {
                    value: 'daily',
                    text: this.$t('site.form.recurring_order.interval.daily')
                },
                {
                    value: 'weekly',
                    text: this.$t('site.form.recurring_order.interval.weekly')
                }
            ]
        },
        okDisabled() {
            return (
                this.order.cart == null || this.order.endDate == null || this.order.startDate == null || this.order.weekdays.length === 0
            );
        }
    },
    data: () => ({
        order: {
            startDate: null,
            endDate: null,
            interval: 'daily',
            cart: null,
            weekdays: []
        },
        labels: {
            de: {
                labelPrevDecade: 'Vorheriges Jahrzehnt',
                labelPrevYear: 'Vorheriges Jahr',
                labelPrevMonth: 'Vorheriger Monat',
                labelCurrentMonth: 'Aktueller Monat',
                labelNextMonth: 'Nächster Monat',
                labelNextYear: 'Nächstes Jahr',
                labelNextDecade: 'Nächstes Jahrzehnt',
                labelToday: 'Heute',
                labelSelected: 'Ausgewähltes Datum',
                labelNoDateSelected: 'Kein Datum gewählt',
                labelCalendar: 'Kalender',
                labelNav: 'Kalendernavigation',
                labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
            },
        }
    }),
    mounted() {
        if (this.startDate) {
            this.order.startDate = this.startDate;
        }

        if (this.endDate) {
            this.order.endDate = this.endDate;
        }

        if (this.orderItem) {
            this.order = Object.assign({}, this.orderItem);
            this.order.cart = this.orderItem.cart ? this.orderItem.cart.id : null;
            this.order.weekdays = this.orderItem.weekdays;
        }
    },
    methods: {
        save(bvModalEvt) {
            bvModalEvt.preventDefault();

            axios.post(this.apiEndpoint, {
                recurring_order: this.order,
                order: this.orderId
            })
                .then(res => {
                    location.reload();
                })
                .catch(err => {
                    console.log(err);
                    this.$toast.clear();
                    this.$toast.error(this.$t('site.toast.error'));
                });
        },
        onChange() {
            this.setInterval();
        }
    }
}
</script>