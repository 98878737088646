var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GmapMap",
    {
      ref: "map",
      staticStyle: { width: "100%", height: "100%" },
      attrs: {
        zoom: _vm.zoom,
        center: _vm.center,
        clickable: true,
        draggable: true,
      },
    },
    _vm._l(_vm.branches, function (branch) {
      return _c("map-marker", { key: branch.id, attrs: { branch: branch } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }