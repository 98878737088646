var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectedFilter.length > 0
    ? _c("div", { staticClass: "product-filter__item current-filters" }, [
        _c("div", { staticClass: "product-filter__header" }, [
          _c("p", { staticClass: "product-filter__title" }, [
            _vm._v(_vm._s(_vm.$t("site.ui.active_filters"))),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "product-filter__clear",
              on: { click: _vm.onRemoveAll },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("site.ui.remove_all")) +
                  "\n        "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "product-filter__options-wrapper no-collapse d-flex flex-wrap",
          },
          _vm._l(_vm.selectedFilter, function (item) {
            return _c(
              "p",
              {
                key: item.id,
                staticClass: "product-filter__current-item",
                on: {
                  click: function ($event) {
                    return _vm.onRemove(item)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(item.filter_name) + "\n            "
                ),
                _c("i", { staticClass: "fa-solid fa-trash" }),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }