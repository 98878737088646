<template>
    <div>
        <brku-current-filter
            :conditions="conditions"
            :current-filter="currentFilter"
            @filter-update="onUpdateFilter"
            @remove-all="onRemoveAll"
        ></brku-current-filter>
        <div class="product-filter__item has--no-border d-xl-none d-flex justify-content-end">
            <div @click="close" class="close-filter d-flex align-items-center justify-content-center">
                {{ $t('site.ui.close') }}
                <i class="fa-solid fa-xmark icon-close-filter"></i>
            </div>
        </div>
        <component
            v-for="condition in conditions"
            :condition="condition"
            :current-filter="currentFilter[condition.fieldName]"
            :is="filterTypeToComponentName(condition.type)"
            :key="condition.fieldName"
            @filter-update="onUpdateFilter"
        ></component>
    </div>
</template>

<script>
import AllergenMultiselect from './condition/AllergenMultiselect';
import BranchMultiselect from './condition/BranchMultiselect';
import RatingMultiselect from './condition/RatingMultiselect';
import RelationalMultiselect from './condition/RelationalMultiselect';
import CategorySelect from './condition/CategorySelect';
import FilterSelect from './condition/FilterSelect';

export default {
    components: {
        AllergenMultiselect,
        BranchMultiselect,
        RatingMultiselect,
        RelationalMultiselect,
        CategorySelect,
        FilterSelect
    },
    props: {
        conditions: [Object, Array],
        currentFilter: [Object, Array]
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        close() {
          this.$emit('close')
        },
        filterTypeToComponentName(type) {
            if (type === 'select') {
                return 'filter-select';
            }

            return type.replace('_', '-').replace('_', '-');
        },
        onUpdateFilter(payload) {
            this.$emit('change', payload);
        },
        onRemoveAll() {
            this.$emit('remove-all');
        }
    }
}
</script>