<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 m-auto">
                <div class="row">
                    <div class="col-12">
                        <div class="stars-rating">
                            <div class="stars-rating__title">{{ $t('site.ui.product_review.total') }}</div>
                            <div class="stars-rating__stars">
                                <brku-star-rating active-color="#e7cb5e"
                                                  border-color="#e7cb5e"
                                                  inactive-color="#ffffff"
                                                  :border-width="3"
                                                  :increment="0.5"
                                                  :inline="true"
                                                  :rating="totalRating"
                                                  :read-only="true"
                                                  :rounded-corners="true"
                                                  :show-rating="false"
                                                  :star-size="41"
                                ></brku-star-rating>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="stars-rating">
                            <div class="stars-rating__title">{{ $t('site.ui.product_review.taste') }}</div>
                            <div class="stars-rating__stars">
                                <brku-star-rating active-color="#bcbcbc"
                                                  border-color="#bcbcbc"
                                                  inactive-color="#ffffff"
                                                  :border-width="3"
                                                  :increment="0.5"
                                                  :inline="true"
                                                  :rounded-corners="true"
                                                  :show-rating="false"
                                                  :star-size="28"
                                                  v-model="taste"
                                ></brku-star-rating>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="stars-rating">
                            <div class="stars-rating__title">{{ $t('site.ui.product_review.freshness') }}</div>
                            <div class="stars-rating__stars">
                                <brku-star-rating active-color="#bcbcbc"
                                                  border-color="#bcbcbc"
                                                  inactive-color="#ffffff"
                                                  :border-width="3"
                                                  :increment="0.5"
                                                  :inline="true"
                                                  :rounded-corners="true"
                                                  :show-rating="false"
                                                  :star-size="28"
                                                  v-model="freshness"
                                ></brku-star-rating>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="stars-rating">
                            <div class="stars-rating__title">{{ $t('site.ui.product_review.tolerability') }}</div>
                            <div class="stars-rating__stars">
                                <brku-star-rating active-color="#bcbcbc"
                                                  border-color="#bcbcbc"
                                                  inactive-color="#ffffff"
                                                  :border-width="3"
                                                  :increment="0.5"
                                                  :inline="true"
                                                  :rounded-corners="true"
                                                  :show-rating="false"
                                                  :star-size="28"
                                                  v-model="tolerability"
                                ></brku-star-rating>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 mt-3 mx-auto">
                <form
                    class="rating-form"
                    name="product_review"
                    method="post"
                    :action="action"
                    @submit.prevent="addReview"
                >
                    <div class="form-group" v-if="!user">
                        <input class="rating-form__input form-control" type="text" id="product_review_name"
                               required="required" :placeholder="$t('site.ui.product_review.name.placeholder')" v-model="name">
                    </div>
                    <div class="form-group">
                        <input class="rating-form__input form-control" type="text" id="product_review_title"
                               required="required" :placeholder="$t('site.ui.product_review.title.placeholder')" v-model="title">
                    </div>
                    <div class="form-group">
                        <textarea class="rating-form__input form-control is--textarea" id="product_review_review" rows="6"
                                  :placeholder="$t('site.ui.product_review.text.placeholder')" v-model="review"></textarea>
                    </div>
                    <p class="google-recaptcha__text">{{ $t('site.ui.form.google_recaptcha.text') }} <a href="https://policies.google.com/privacy">{{ $t('site.ui.form.google_recaptcha.privacy_policy') }}</a> and <a href="https://policies.google.com/terms">{{ $t('site.ui.form.google_recaptcha.terms_of_service') }}</a> {{ $t('site.ui.form.google_recaptcha.apply') }}.</p>
                    <button type="submit" class="btn btn-custom btn-primary">{{
                            $t('site.ui.product_review.button')
                        }}
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {BAlert} from 'bootstrap-vue'
import {VueReCaptcha} from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, {
    siteKey: '6LfiiCoaAAAAABusxEbQ2VS4FbJJUY98Rs_0efQ2',
    loaderOptions: {
        autoHideBadge: true
    }
});

export default {
    components: {
        BAlert
    },
    name: 'ReviewForm',
    props: {
        action: String,
        user: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        name: null,
        title: null,
        review: null,
        taste: 0,
        freshness: 0,
        tolerability: 0,
        totalRating: 0
    }),
    computed: {
        isCustomer() {
            return this.$store.state.checkout.content.cart ? this.$store.state.checkout.content.cart.done : false;
        }
    },
    methods: {
        addReview() {
            this.$recaptcha('login').then(token => {
                this.$http.post(this.action, {
                    product_review: {
                        name: this.name,
                        title: this.title,
                        review: this.review,
                        total_rating: this.totalRating,
                        taste_rating: this.taste,
                        freshness_rating: this.freshness,
                        tolerability_rating: this.tolerability
                    },
                    googleRecaptchaToken: token
                })
                    .then(res => res.data)
                    .then(result => {
                        if (typeof result.state === 'undefined' || typeof result.text === 'undefined') {
                            return;
                        }

                        this.$toast.clear();
                        this.$toast.open(this.$t(result.text));

                        this.reset();

                        location.reload();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            });
        },
        setTotalRating() {
            let total = 0

            total += this.taste;
            total += this.freshness;
            total += this.tolerability;

            this.totalRating = this.roundValue(total / 3);
        },
        roundValue: (value, step = 0.5) => {
            let inverter = 1.0 / step;

            return Math.round(value * inverter) / inverter;
        },
        reset() {
            this.name = null;
            this.title = null;
            this.review = null;
            this.taste = 0;
            this.freshness = 0;
            this.tolerability = 0;
        }
    },
    watch: {
        taste() {
            this.setTotalRating();
        },
        freshness() {
            this.setTotalRating();
        },
        tolerability() {
            this.setTotalRating();
        }
    }
}
</script>