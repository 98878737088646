var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card-body__item mb-3", on: { click: _vm.toggleModal } },
        [
          _vm.showPickupDate
            ? _c("p", { staticClass: "item-time" }, [
                _vm._v(
                  _vm._s(_vm._f("formatDate")(_vm.order.pickUpDate)) +
                    " | " +
                    _vm._s(_vm.order.pickUpTime) +
                    " Uhr"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-name-wrapper d-flex justify-content-between" },
            [
              _c("p", { staticClass: "item-name" }, [
                _vm._v(
                  _vm._s(_vm.order.customer.firstname) +
                    " " +
                    _vm._s(_vm.order.customer.lastname)
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "item-price" }, [
                _vm._v(_vm._s(_vm._f("formatPrice")(_vm.order.baseTotalGross))),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.showOrderNumber
            ? _c("p", { staticClass: "item-order-number" }, [
                _vm._v(
                  "\n            Best.-Nr: " +
                    _vm._s(_vm.order.orderNumber) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showProductAmount
            ? _c("p", { staticClass: "item-order-product" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.items.length) +
                    " Produkte\n        "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showOrderDetailModal
        ? _c("brku-order-modal", {
            attrs: {
              order: _vm.order,
              "order-state-action": _vm.orderStateAction,
            },
            on: {
              "set-data": _vm.setData,
              close: function ($event) {
                _vm.showOrderDetailModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showOrderDetailModal
        ? _c("div", { staticClass: "modal-backdrop fade show" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }