var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClass }, [
    _c(
      "div",
      { staticClass: "form-group", class: { "has--input": _vm.value != null } },
      [
        _c("label", { staticClass: "label-card" }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _vm.type === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scopeValue,
                  expression: "scopeValue",
                },
              ],
              staticClass: "form-control input-card",
              attrs: {
                required: _vm.required,
                placeholder: _vm.label,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.scopeValue)
                  ? _vm._i(_vm.scopeValue, null) > -1
                  : _vm.scopeValue,
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event.target.value)
                },
                change: function ($event) {
                  var $$a = _vm.scopeValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.scopeValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.scopeValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.scopeValue = $$c
                  }
                },
              },
            })
          : _vm.type === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scopeValue,
                  expression: "scopeValue",
                },
              ],
              staticClass: "form-control input-card",
              attrs: {
                required: _vm.required,
                placeholder: _vm.label,
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.scopeValue, null) },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event.target.value)
                },
                change: function ($event) {
                  _vm.scopeValue = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scopeValue,
                  expression: "scopeValue",
                },
              ],
              staticClass: "form-control input-card",
              attrs: {
                required: _vm.required,
                placeholder: _vm.label,
                type: _vm.type,
              },
              domProps: { value: _vm.scopeValue },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.scopeValue = $event.target.value
                  },
                  function ($event) {
                    return _vm.$emit("input", $event.target.value)
                  },
                ],
              },
            }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }