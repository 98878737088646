<template>
    <GmapMap
        ref="map"
        :zoom="zoom"
        :center="center"
        style="width: 100%; height: 100%"
        :clickable="true"
        :draggable="true"
    >
       <map-marker
            v-for="branch in branches"
            :branch="branch"
            :key="branch.id"
        />
    </GmapMap>
</template>

<script>
import MapMarker from "./Marker";

export default {
    components: {
      MapMarker
    },
    data() {
        return {
            zoom: 13,
            center: {
              lat: 47.48750172196571,
              lng: 12.531109346357393
            },
            currentZoom: 5,
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5
            },
            showMap: true
        };
    },
    props: {
        branches: {
            type: Array,
            default: []
        }
    },
    methods: {
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        fitBounds() {
            if(this.branches.length > 0) {
              return;
                this.$refs.map.mapObject.fitBounds(this.branches.map(m => {
                    return [
                        m.latitude,
                        m.longitude
                    ]
                }));
            }
        }
    },
    watch: {
        branches() {
            this.fitBounds();
        }
    },
    mounted() {
        if (this.branches) {
            this.fitBounds();
        }
    }
}
</script>