<template>
    <div class="production-process-slider container pt-5 pb-4">
        <div class="keen-slider" ref="slider">
            <slot></slot>
        </div>
        <div class="slider-navigation-wrapper d-flex justify-content-center lg:mt-5">
            <div
                :class="{ arrow: true, 'slider-navigation is--prev mr-3': true, 'is--disabled': current === 0 }"
                @click="slider.prev()">
                <i class="fa-solid fa-angle-left"></i>
            </div>
            <div
                :class="{ arrow: true, 'slider-navigation is--next': true, 'is--disabled': current === totalCount - 1 }"
                @click="slider.next()">
                <i class="fa-solid fa-angle-right"></i>
            </div>
        </div>
    </div>
</template>

<script>
import KeenSlider from 'keen-slider';

export default {
    props: {
        totalCount: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        current: 1,
        slider: null
    }),
    mounted() {
        this.setActiveClass(this.$refs.slider.children);

        this.slider = new KeenSlider(this.$refs.slider, {
            centered: true,
            initial: this.current,
            slidesPerView: 1,
            spacing: 120,
            slideChanged: (s) => {
                this.current = s.details().relativeSlide;
                this.setActiveClass();
            },
            breakpoints: {
                "(min-width: 768px)": {
                    slidesPerView: 2
                },
                "(min-width: 992px)": {
                    slidesPerView: 3
                },
                "(min-width: 1200px)": {
                    slidesPerView: 4
                },
            },
        });
    },
    methods: {
        setActiveClass() {
            let slides = this.$refs.slider.children;
            let counter = 0;

            for (let slide of slides) {
                slide.classList.remove('is--active');

                if (counter === this.current) {
                    slide.classList.add('is--active');
                }

                counter++;
            }
        }
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy();
    }
}
</script>