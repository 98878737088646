var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "col-6 d-flex align-items-center justify-content-center has--grey-background flex-column",
    },
    [
      _vm.showBranchSelect
        ? _c(
            "div",
            [
              _c("branch-select", {
                attrs: {
                  branches: _vm.branches,
                  "selected-branch": _vm.selectedBranch,
                },
                on: { "select-branch": _vm.selectBranch },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showBranchSelect
        ? _c(
            "form",
            {
              staticClass:
                "d-flex align-items-center justify-content-center flex-column",
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.login.apply(null, arguments)
                },
              },
            },
            [
              _c("b-alert", { attrs: { variant: "danger", show: _vm.error } }, [
                _vm._v(_vm._s(_vm.$t("site.ui.login.error"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "login-form mb-4" }, [
                _c("div", { staticClass: "login-title text-center" }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pin,
                        expression: "pin",
                      },
                    ],
                    staticClass: "form-control input-card",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("site.form.login.employee_pin"),
                    },
                    domProps: { value: _vm.pin },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.pin = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "form-control input-card",
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t("site.form.login.password"),
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-custom btn-primary has--icon-arrow-right mb-2",
                  attrs: { type: "submit" },
                },
                [
                  _vm.loading
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("site.ui.loading")))])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("site.form.login.button"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }