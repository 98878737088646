document.querySelectorAll('.timeline-nav-button--next').forEach(item => {
  item.addEventListener('click', event => {
    const timelineElements = document.querySelectorAll('.timeline__item');
    const activeTimeline = document.querySelectorAll('.timeline__item.is--active')[0];
    const timelineElementArray = Array.from(timelineElements);
    const activeIndex = timelineElementArray.indexOf(activeTimeline);

    timelineElementArray[activeIndex].classList.remove('is--active');

    setTimeout(() => {
      timelineElementArray[activeIndex + 1].classList.add('is--active');
    }, 200);
  })
})

document.querySelectorAll('.timeline-nav-button--prev').forEach(item => {
  item.addEventListener('click', event => {
    const timelineElements = document.querySelectorAll('.timeline__item');
    const activeTimeline = document.querySelectorAll('.timeline__item.is--active')[0];
    const timelineElementArray = Array.from(timelineElements);
    const activeIndex = timelineElementArray.indexOf(activeTimeline);

    timelineElementArray[activeIndex].classList.remove('is--active');

    setTimeout(() => {
      timelineElementArray[activeIndex - 1].classList.add('is--active');
    }, 200);
  })
})