var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.condition.objects.length > 0
    ? _c(
        "div",
        {
          staticClass: "product-filter__item",
          class: `condition-${_vm.condition.type}`,
        },
        [
          !_vm.hideLabel
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: `collapse-${_vm.condition.fieldName}`,
                      expression: "`collapse-${condition.fieldName}`",
                    },
                  ],
                  staticClass: "product-filter__header",
                },
                [
                  _c("p", { staticClass: "product-filter__title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t(_vm.condition.label)) +
                        " "
                    ),
                    _vm.condition.objects
                      ? _c("strong", [
                          _vm._v(_vm._s(`(${_vm.condition.objects.length})`)),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "is--visible": _vm.contentVisible,
                        "product-filter__toggle": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa-solid fa-angle-down toggle-angle-down",
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              staticClass: "product-filter__options",
              attrs: { id: `collapse-${_vm.condition.fieldName}`, visible: "" },
              model: {
                value: _vm.contentVisible,
                callback: function ($$v) {
                  _vm.contentVisible = $$v
                },
                expression: "contentVisible",
              },
            },
            [
              _vm.showSearch
                ? _c("div", { staticClass: "product-filter__options-search" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search,
                          expression: "search",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "search",
                        placeholder: _vm.$t(
                          "site.ui.placeholder." + _vm.condition.fieldName
                        ),
                      },
                      domProps: { value: _vm.search },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.search = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }