<template>
    <base-condition :condition="filterCondition" :current-filter="currentFilter" @search="onSearch">
        <b-form-checkbox-group v-model="selected" :name="filterCondition.fieldName" @change="onChange">
            <div class="product-filter__options-wrapper">
                <div class="product-filter__option-item"
                     v-for="item in visibleItems"
                     :key="`${filterCondition.fieldName}_${item.id}`"
                >
                    <div class="option-item__content">
                        <b-form-checkbox :value="parseInt(item.id)">
                            {{ item.filter_name }}
                        </b-form-checkbox>
                    </div>
                    <div class="option-item__count">
                        ({{ getValueForId(item.id).count ? getValueForId(item.id).count : 0 }})
                    </div>
                </div>
            </div>
            <b-collapse
                class="product-filter__options-wrapper"
                :id="`collapse-options-wrapper-${filterCondition.fieldName}`"
                v-if="hiddenItems.length > 0"
            >
                <div
                    class="product-filter__option-item"
                    v-for="item in hiddenItems"
                    :key="`${filterCondition.fieldName}_${item.id}`"
                >
                    <div class="option-item__content">
                        <b-form-checkbox :value="parseInt(item.id)">
                            {{ item.filter_name }}
                        </b-form-checkbox>
                    </div>
                    <div class="option-item__count">
                        ({{ getValueForId(item.id).count ? getValueForId(item.id).count : 0 }})
                    </div>
                </div>
            </b-collapse>
            <div class="product-filter__option-item"
                 v-if="hiddenItems.length > 0"
                 @click="optionsHidden = !optionsHidden"
                 v-b-toggle="`collapse-options-wrapper-${filterCondition.fieldName}`"
            >
                <div class="option-item__content justify-content-center">
                    <span v-if="!optionsHidden">{{ $t('site.ui.close') }}</span>
                    <span v-else>+ {{ hiddenItems.length }}</span>
                </div>
            </div>
        </b-form-checkbox-group>
    </base-condition>
</template>

<script>
import BaseCondition from './BaseCondition';
import {BFormCheckbox, BFormCheckboxGroup} from 'bootstrap-vue';

export default {
    extends: BaseCondition,
    components: {
        BaseCondition,
        BFormCheckbox, BFormCheckboxGroup
    },
    data: () => ({
        filterCondition: null,
        selected: [],
        optionsHidden: true
    }),
    created() {
        if (this.condition.currentValues) {
            this.selected = this.condition.currentValues.map(val => parseInt(val));
        }

        this.setDefaultFilterCondition();
    },
    computed: {
        visibleItems() {
            return this.filterCondition.objects.slice(0, 3);
        },
        hiddenItems() {
            return this.filterCondition.objects.slice(3, this.filterCondition.objects.length);
        }
    },
    methods: {
        getValueForId(id) {
            let values = this.condition.values.filter(object => {
                return parseInt(object.value) === parseInt(id);
            });

            if (values.length > 0) {
                return values[0];
            }

            return {
                name: null
            }
        },
        onChange(values) {
            this.$emit('filter-update', {
                fieldName: this.condition.fieldName,
                values: values
            });
        },
        onSearch(value) {
            this.setDefaultFilterCondition();

            if (this.filterCondition.objects.length > 0) {
                this.filterCondition.objects = this.filterCondition.objects.filter((item) => {
                    return item.filter_name.toLowerCase().indexOf(value.toLowerCase()) > -1;
                });
            }

            if (this.filterCondition.objects.length === 0) {
                this.setDefaultFilterCondition();
            }
        },
        setDefaultFilterCondition() {
            this.filterCondition = {
                ...this.condition
            }
        }
    },
    watch: {
        condition: {
            deep: true,
            handler() {
                this.setDefaultFilterCondition();
            }
        },
        currentFilter: {
            deep: true,
            handler(newValue) {
                this.selected = newValue;
            }
        }
    }
}
</script>