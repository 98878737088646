var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: `recurring-order-modal-${_vm.id}`,
        "dialog-class": "recurring-order-modal",
        "ok-only": "",
        title: _vm.$t("site.ui.account.recurring_order.modal_title"),
        "ok-disabled": _vm.okDisabled,
      },
      on: { ok: _vm.save },
      scopedSlots: _vm._u([
        {
          key: "modal-header-close",
          fn: function () {
            return [
              _c("i", {
                staticClass: "fa-solid fa-xmark close-icon",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function ({ ok }) {
            return [
              !_vm.okDisabled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-custom btn-primary m-0",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v("OK")]
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c(
            "div",
            { staticClass: "form-group datepicker" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("site.form.recurring_order.start_date"))),
              ]),
              _vm._v(" "),
              _c(
                "b-form-datepicker",
                _vm._b(
                  {
                    attrs: {
                      id: "startDate",
                      locale: _vm.locale,
                      "date-format-options": {
                        weekday: "short",
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      },
                      required: "",
                    },
                    model: {
                      value: _vm.order.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.order, "startDate", $$v)
                      },
                      expression: "order.startDate",
                    },
                  },
                  "b-form-datepicker",
                  _vm.labels[_vm.locale] || {},
                  false
                )
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6" }, [
          _c(
            "div",
            { staticClass: "form-group datepicker" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("site.form.recurring_order.end_date"))),
              ]),
              _vm._v(" "),
              _c(
                "b-form-datepicker",
                _vm._b(
                  {
                    attrs: {
                      id: "endDate",
                      locale: _vm.locale,
                      "date-format-options": {
                        weekday: "short",
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      },
                      required: "",
                    },
                    model: {
                      value: _vm.order.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.order, "endDate", $$v)
                      },
                      expression: "order.endDate",
                    },
                  },
                  "b-form-datepicker",
                  _vm.labels[_vm.locale] || {},
                  false
                )
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 mb-2 weekdays" }, [
          _c("label", [
            _vm._v(_vm._s(_vm.$t("site.form.recurring_order.weekdays"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "b-form-checkbox-group",
                      {
                        attrs: { multiple: "" },
                        model: {
                          value: _vm.order.weekdays,
                          callback: function ($$v) {
                            _vm.$set(_vm.order, "weekdays", $$v)
                          },
                          expression: "order.weekdays",
                        },
                      },
                      _vm._l(_vm.weekdays, function (day, i) {
                        return _c(
                          "b-form-checkbox",
                          { attrs: { value: i + 1, size: "lg" } },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(day) +
                                "\n                                "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "cart" } }, [
                _vm._v(_vm._s(_vm.$t("site.form.recurring_order.cart"))),
              ]),
              _vm._v(" "),
              _c("b-form-select", {
                staticClass: "form-control input-card",
                attrs: {
                  id: "cart",
                  options: _vm.carts,
                  "value-field": "id",
                  "text-field": "customIdentifier",
                  required: "",
                },
                model: {
                  value: _vm.order.cart,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "cart", $$v)
                  },
                  expression: "order.cart",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }