<template>
    <brku-pie-chart :data="chartData" :options="chartOptions"></brku-pie-chart>
</template>

<script>
import PieChart from '../PieChart';

export default {
    components: {
        PieChart
    },
    name: 'NutrientsChart',
    props: {
        data: Object
    },
    data() {
        return {
            chartOptions: {
                cutoutPercentage: 90,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let labels = data.labels;
                            let values = data.datasets[tooltipItem.datasetIndex].values;

                            return labels[tooltipItem.index] + ': ' + values[tooltipItem.index] + ' g';
                        }
                    }
                }
            },
            chartData: {
                labels: [this.$t('site.ui.product_detail.carbohydrates'), this.$t('site.ui.product_detail.fats'), this.$t('site.ui.product_detail.proteins')],
                datasets: [
                    {
                        backgroundColor: ["#cc9e67", "#866d5d", "#c5c5c5"],
                        data: [this.data['carbohydrates']['percents'], this.data['fats']['percents'], this.data['proteins']['percents']],
                        values: [this.data['carbohydrates']['value'], this.data['fats']['value'], this.data['proteins']['value']],
                    }
                ]
            }
        };
    }
}
</script>