<template>
    <div :class="wrapperClass">
        <div class="form-group">
            <select v-model="scopeValue" class="form-control select-card" @change="$emit('input', $event.target.value)">
                <option v-for="option in options" :key="option.value" :value="option.id">{{option.name}}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountInput',
    props: {
        options: {
            type: Array,
            default: []
        },
        wrapperClass: {
            type: String,
            default: "col-md-6 col-12"
        },
        label: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            scopeValue: this.value
        }
    },
    watch: {
        'value': function (val) {
            this.scopeValue = val;
        }
    }
}
</script>