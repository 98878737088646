var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "local-delivery" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "process-step__sub-title" }, [
            _vm._v(
              _vm._s(_vm.$t("site.ui.checkout.local_delivery.delivery_date"))
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "datepicker d-flex" },
            [
              _c(
                "button",
                {
                  staticClass: "datepicker__arrow is--left d-none d-md-block",
                  attrs: { type: "button" },
                  on: { click: _vm.prevDay },
                },
                [_c("i", { staticClass: "fa-solid fa-angle-left" })]
              ),
              _vm._v(" "),
              _c("b-form-datepicker", {
                attrs: {
                  min: _vm.minDate,
                  "date-format-options": {
                    weekday: "short",
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  },
                },
                model: {
                  value: _vm.selectedDate,
                  callback: function ($$v) {
                    _vm.selectedDate = $$v
                  },
                  expression: "selectedDate",
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "datepicker__arrow is--right d-none d-md-block",
                  attrs: { type: "button" },
                  on: { click: _vm.nextDay },
                },
                [_c("i", { staticClass: "fa-solid fa-angle-right" })]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "process-step__sub-title" }, [
            _vm._v(
              _vm._s(_vm.$t("site.ui.checkout.local_delivery.shipping_address"))
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "address",
            domProps: { innerHTML: _vm._s(_vm.shippingAddress) },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "process-step__sub-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm.$t("site.ui.checkout.local_delivery.shipping_costs")
                ) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _vm.cart.shippingGross > 0
            ? _c("p", [_vm._v(_vm._s(_vm.cart.shippingGrossFormatted))])
            : _c("p", [_vm._v(_vm._s(_vm.$t("site.ui.shared.free_shipping")))]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center my-2 my-md-4" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-custom",
            attrs: { type: "submit", variant: "primary", disabled: !_vm.valid },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("site.ui.checkout.continue")) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("input", {
      attrs: { name: "coreshop[deliveryDate]", type: "hidden" },
      domProps: { value: _vm.formattedDate },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }