<template>
    <div>
        <div class="ingredients-slider__images">
            <div class="keen-slider" ref="imageSlider">
                <div :class="{ 'keen-slider__slide d-flex justify-content-center': true, 'is--active': current === index }" v-for="(image, index) of images" :key="index">
                    <img
                        :class="{ 'ingredients-slider__image': true, 'is--active': current === index }"
                        :src="image"
                    />
                </div>
            </div>
        </div>
        <div class="ingredients-slider__content mt-3 mt-sm-5">
            <div class="keen-slider" ref="slider">
                <div v-for="ingredient in ingredients" :key="ingredient.id" class="keen-slider__slide">
                    <div class="ingredients-slider__title d-flex justify-content-center">
                        {{ ingredient.name }}
                    </div>
                    <div class="ingredients-slider__text d-flex justify-content-center"
                         v-html="ingredient.description"></div>
                </div>
            </div>
        </div>
        <div class="
        slider-navigation-wrapper d-flex justify-content-center mt-3 mt-sm-5">
            <div
                :class="{ arrow: true, 'slider-navigation is--prev mr-3': true, 'is--disabled': current === 0 }"
                @click="slider.prev()">
                <i class="fa-solid fa-angle-left"></i>
            </div>
            <div
                :class="{ arrow: true, 'slider-navigation is--next': true, 'is--disabled': current === totalCount - 1 }"
                @click="slider.next()">
                <i class="fa-solid fa-angle-right"></i>
            </div>
        </div>
    </div>
</template>

<script>
import KeenSlider from 'keen-slider';

export default {
    name: 'ProductIngredientSlider',
    props: {
        images: Array,
        ingredients: Object | Array,
        totalCount: Number,
    },
    data: () => ({
        current: 0,
        slider: null,
        imageSlider: null
    }),
    mounted() {
        this.imageSlider = new KeenSlider(this.$refs.imageSlider, {
            centered: true,
            controls: false,
            initial: this.current,
            slidesPerView: 3,
            loop: false,
            slideChanged: (s) => {
                this.current = s.details().relativeSlide;
            }
        });

        this.slider = new KeenSlider(this.$refs.slider, {
            initial: this.current,
            loop: true,
            slideChanged: (s) => {
                this.current = s.details().relativeSlide;
                this.imageSlider.moveToSlide(this.current);
            }
        });
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy();
        if (this.imageSlider) this.imageSlider.destroy();
    }
}
</script>