var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sale-list-item mb-2" }, [
    _c(
      "div",
      { staticClass: "sale-list-item__title" },
      [
        _c("div", { staticClass: "row align-items-center no-gutters" }, [
          _c("div", { staticClass: "col-lg-9 col-7 d-lg-flex d-block" }, [
            _c("div", { staticClass: "col-lg-2 col-12" }, [
              _c("div", { staticClass: "item-date pl-lg-3" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm._f("moment")(_vm.cart.creationDate, "DD.MM.Y")) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-7 col-12" }, [
              _c("div", { staticClass: "item-number" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.cart.customIdentifier) +
                    "\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-2 col-3" }, [
            _c("div", { staticClass: "item-price" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.cart.totalNetFormatted) +
                  "\n                "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-1 col-2" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: `breadbasket-${_vm.cart.id}`,
                    expression: "`breadbasket-${cart.id}`",
                  },
                ],
                class: [
                  "border-0 ml-auto item-btn",
                  {
                    "has--angle-down": !_vm.visible,
                    "has--angle-up": _vm.visible,
                  },
                ],
              },
              [
                _vm.visible
                  ? _c("i", { staticClass: "fa-solid fa-chevron-down" })
                  : _c("i", { staticClass: "fa-solid fa-chevron-up" }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "b-collapse",
          {
            staticClass: "sale-list-body",
            attrs: { id: `breadbasket-${_vm.cart.id}` },
            model: {
              value: _vm.visible,
              callback: function ($$v) {
                _vm.visible = $$v
              },
              expression: "visible",
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("div", { staticClass: "body-title" }, [
                    _vm._v("Produkte"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cart.items, function (item, index) {
                    return _c(
                      "div",
                      { staticClass: "row order-card-item no-gutters" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-8 d-flex align-items-center justify-content-start",
                          },
                          [
                            item.product.image
                              ? _c("b-img-lazy", {
                                  staticClass: "item-image",
                                  attrs: { src: item.product.image },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !item.product.image
                              ? _c("b-img-lazy", {
                                  staticClass: "item-image",
                                  attrs: {
                                    src: "/build/images/product-default-image.png",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-info" }, [
                              _c("div", { staticClass: "item-description" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(item.product.name) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item-number" }, [
                                !_vm.updateMode
                                  ? _c("span", [
                                      _vm._v(
                                        "Art. Nr: " + _vm._s(item.product.sku)
                                      ),
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "product-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeCartItem(item.id)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("site.ui.remove")))]
                                    ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-1 d-none d-md-flex" }, [
                          _c("div", { staticClass: "item-price" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.itemNetFormatted) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-2 d-flex justify-content-center",
                          },
                          [
                            !_vm.updateMode
                              ? _c("div", { staticClass: "item-quantity" }, [
                                  _vm._v(_vm._s(item.quantity) + " Stk."),
                                ])
                              : _c(
                                  "div",
                                  [
                                    _c("brku-quantity-input", {
                                      attrs: { quantity: item.quantity },
                                      on: {
                                        "quantity-changed": function ($event) {
                                          return _vm.onQuantityChanged(
                                            $event,
                                            index,
                                            item.id
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-1 d-flex justify-content-end" },
                          [
                            _c("div", { staticClass: "item-total-price" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.totalItemNetFormatted) +
                                  "\n                            "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.updateMode && !_vm.quickOrderMode
                    ? _c("div", { staticClass: "row order-card-item" }, [
                        _c(
                          "div",
                          { staticClass: "col-8 d-flex align-items-center" },
                          [
                            _c(
                              "p",
                              { staticClass: "mb-0 mr-2 font-weight-bold" },
                              [_vm._v("Produkt hinzufügen")]
                            ),
                            _vm._v(" "),
                            _c("vue-select", {
                              staticClass: "flex-grow-1",
                              attrs: { options: _vm.products, label: "name" },
                              on: { search: _vm.fetchProductOptions },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (option) {
                                      return [
                                        _c("b-img-lazy", {
                                          staticStyle: {
                                            height: "30px",
                                            width: "30px",
                                            "object-fit": "cover",
                                          },
                                          attrs: { src: option.cartImage },
                                        }),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(option.name) +
                                            " (" +
                                            _vm._s(option.sku) +
                                            ")\n                                "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1255675126
                              ),
                              model: {
                                value: _vm.selectedProduct,
                                callback: function ($$v) {
                                  _vm.selectedProduct = $$v
                                },
                                expression: "selectedProduct",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-dark btn-sm",
                              attrs: { disabled: !_vm.selectedProduct },
                              on: { click: _vm.addProduct },
                            },
                            [
                              _vm._v(
                                "\n                                Hinzufügen\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row no-gutters order-card-result" },
                    [
                      _c("div", { staticClass: "col-md-8 col-3" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-4 col-9" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "total-price d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("p", { staticClass: "total-price__text" }, [
                              _vm._v("Gesamtsumme (ohne Versand)"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "total-price__sum" }, [
                              _vm._v(_vm._s(_vm.cart.subtotalNetFormatted)),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn-wrapper d-lg-flex d-block align-items-center mt-4",
                    },
                    [
                      _vm.quickOrderMode || _vm.updateMode
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-custom btn-outline-primary mr-auto",
                              on: {
                                click: function ($event) {
                                  _vm.updateMode = false
                                  _vm.quickOrderMode = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Zurück\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.quickOrderMode
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-link delete-order-btn pl-3 text-decoration-none",
                              on: { click: _vm.deleteBreadbasket },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-trash btn-delete__icon",
                              }),
                              _vm._v(
                                "\n                            Brotkorb löschen\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.updateMode && !_vm.quickOrderMode
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-custom btn-primary has--icon-arrow-right ml-auto",
                              on: {
                                click: function ($event) {
                                  _vm.updateMode = !_vm.updateMode
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Brotkorb bearbeiten\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.quickOrderMode
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-custom btn-primary has--icon-arrow-right ml-auto",
                              on: {
                                click: function ($event) {
                                  _vm.updateMode = true
                                  _vm.quickOrderMode = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Schnelle Bestellung\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.quickOrderMode
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-custom btn-primary has--icon-arrow-right ml-auto",
                              on: { click: _vm.quickOrderCheckout },
                            },
                            [
                              _vm._v(
                                "\n                            Schnellbestellung zur Kasse\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }