<template>
    <form method="post" @submit.prevent="submitForm" class="row pt-md-0 pt-3 position-relative z-5">
        <div v-if="formSubmitted" class="inquiry-overlay">
            <svg class="inquiry-overlay__icon" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <strong class="inquiry-overlay__title">{{ $t('site.ui.thanks_for_your_inquiry') }}</strong>
        </div>
        <div class="col-md-6 col-12">
            <div class="form-group" :class="{'has--input' : firstName != null}">
                <label class="label-card">{{ $t('site.ui.form.firstname') }}*</label>
                <input type="text" class="form-control input-card" :placeholder="`${$t('site.ui.form.firstname')}*`"
                       required v-model="firstName">
            </div>
        </div>
        <div class="col-md-6 col-12">
            <div class="form-group" :class="{'has--input' : lastName != null}">
                <label class="label-card">{{ $t('site.ui.form.lastname') }}*</label>
                <input type="text" class="form-control input-card" :placeholder="`${$t('site.ui.form.lastname')}*`"
                       required v-model="lastName">
            </div>
        </div>
        <div class="col-md-6 col-12">
            <div class="form-group" :class="{'has--input' : email != null}">
                <label class="label-card">{{ $t('site.ui.form.email') }}*</label>
                <input type="email" class="form-control input-card" :placeholder="`${$t('site.ui.form.email')}*`"
                       required v-model="email">
            </div>
        </div>
        <div class="col-md-6 col-12">
            <div class="form-group" :class="{'has--input' : phoneNumber != null}">
                <label class="label-card">{{ $t('site.ui.form.phone') }}</label>
                <input type="text" class="form-control input-card" :placeholder="$t('site.ui.form.phone')"
                       v-model="phoneNumber">
            </div>
        </div>
        <div class="col-12">
            <div class="form-group" :class="{'has--input' : message != null}">
                <label class="label-card">{{ $t('site.ui.form.your_message') }}*</label>
                <textarea class="form-control textarea input-card" rows="3"
                          :placeholder="`${$t('site.ui.form.your_message')}*`" required v-model="message"></textarea>
            </div>
        </div>
        <div class="col-12">
            <p class="google-recaptcha__text">{{ $t('site.ui.form.google_recaptcha.text') }} <a href="https://policies.google.com/privacy">{{ $t('site.ui.form.google_recaptcha.privacy_policy') }}</a> and <a href="https://policies.google.com/terms">{{ $t('site.ui.form.google_recaptcha.terms_of_service') }}</a> {{ $t('site.ui.form.google_recaptcha.apply') }}.</p>
        </div>
        <div class="col-12">
            <button class="btn btn-custom btn-primary has--icon-arrow-right mb-2">
                <span v-if="!loading">{{ $t('site.ui.form.submit') }}</span>
                <span v-if="loading">{{ $t('site.ui.loading') }}</span>
            </button>
        </div>
    </form>
</template>

<script>
import Vue from 'vue';
import {VueReCaptcha} from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, {
    siteKey: '6LfiiCoaAAAAABusxEbQ2VS4FbJJUY98Rs_0efQ2',
    loaderOptions: {
        autoHideBadge: true
    }
});

export default {
    name: 'InquiryForm',
    props: {
        action: {
            type: String,
            default: null
        },
        customerNumber: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            message: null,
            acceptance: true,
            formSubmitted: false,
            errors: []
        }
    },
    methods: {
        reset() {
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.phoneNumber = null;
            this.message = null;
            this.acceptance = true;
        },
        submitForm() {
            this.loading = true;

            this.$recaptcha('login').then(token => {
                axios.post(this.action, {
                    propertyId: this.properties,
                    googleRecaptchaToken: token,
                    inquiry: {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        phoneNumber: this.phoneNumber,
                        email: this.email,
                        message: this.message,
                        acceptance: this.acceptance,
                        customerNumber: this.customerNumber
                    }
                })
                    .then(res => res.data)
                    .then(res => {
                        this.formSubmitted = true;
                        this.reset();
                        this.loading = false;
                    })
                    .catch(err => {
                        this.loading = false;
                        this.formSubmitted = false;

                        this.$toast.clear();
                        this.$toast.error(this.$t('site.toast.error'));
                    });
            });
        },
    }
}
</script>