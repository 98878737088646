<template>
    <form @submit.prevent="submitForm" class="newsletter-wrapper d-block d-md-flex w-100 align-items-center"
          method="post">
        <label class="newsletter__label is--bg-dark" v-if="isFooter">{{ $t('newsletter.label') }}</label>
        <div class="d-flex flex-grow-0 flex-md-grow-1">
            <input type="email" :class="['form-control has--box-shadow mb-0', {'is--bg-dark': isFooter}]"
                   :placeholder="$t('newsletter.email')" v-model="email" required/>
            <button type="submit"
                    class="btn btn-custom btn-outline-primary d-flex align-items-center justify-content-center newsletter__button">
                <i class="fa-solid fa-arrow-right"></i>
            </button>
        </div>
    </form>
</template>

<script>
export default {
    name: 'NewsletterForm',
    data() {
        return {
            email: '',
        };
    },
    props: {
        action: {
            type: String,
            default: ''
        },
        isFooter: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        submitForm() {
            axios.post(this.action, {
                email: this.email
            })
                .then(res => res.data)
                .then(res => {
                    this.$toast.clear();
                    this.$toast.open(this.$t('newsletter.success'));

                    this.email = '';
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(this.$t('newsletter.error'));
                });
        }
    }
}
</script>