<script>
import {Doughnut, mixins} from 'vue-chartjs';

export default {
    extends: Doughnut,
    name: 'PieChart',
    props: {
        options: Object,
        data: Object
    },
    mounted() {
        this.renderChart(this.data, this.options);
    }
}
</script>