var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "cartPreview",
      staticClass:
        "navbar-cart d-flex justify-content-center align-items-center",
      on: { click: _vm.toggleState },
    },
    [
      _c("p", { staticClass: "position-relative" }, [
        _c("i", { staticClass: "fa-solid fa-cart-shopping navbar-cart__icon" }),
        _vm._v(" "),
        _vm.$store.state.cart.content
          ? _c(
              "span",
              {
                staticClass:
                  "navbar-cart__icon-badge d-flex align-items-center justify-content-center",
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$store.state.cart.content.items.length) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "navbar-cart__text d-none d-lg-block" }, [
        _vm._v(_vm._s(_vm.$t("site.ui.cart.preview.label"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }