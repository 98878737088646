<template>
    <div class="col-md-6 col-12 mt-3 mb-3">
        <div class="bg-white p-3 shadow-sm account-address-card">
            <p class="font-weight-bold mb-2" v-if="address.id == shippingAddress.id">{{ $t('site.ui.default_address') }}</p>
            <div class="d-flex justify-content-between align-items-end">
                <div>
                    {{address.firstname }} {{address.lastname }}<br/>
                    {{ address.street }} {{address.number}}<br/>
                    {{ address.postcode }} {{ address.city }}<br/>
                </div>
                <div>
                    <span class="text-primary" @click="deleteAddress"><i class="fa-solid fa-trash"></i></span>
                </div>
            </div>
            <small class="mt-3 d-block">
                <span @click="setShippingAddress" class="account-address-card__btn d-block mb-1" >{{ $t('Als Standard-Lieferadresse verwenden') }}</span>
                <span @click="setInvoiceAddress" class="account-address-card__btn d-block" >{{ $t('Als Standard-Rechnungsadresse verwenden') }}</span>
            </small>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'AddressItem',
    props: {
        address: {
            type: Object,
            default: () => {}
        },
        shippingAddress: {
            type: Object,
            default: () => {}
        },
        invoiceAddress: {
            type: Object,
            default: () => {}
        },
        deleteAddressAction: {
            type: String,
            default: null
        },
        updateInvoiceAddressAction: {
            type: String,
            default: null
        },
        updateShippingAddressAction: {
            type: String,
            default: null
        }
    },
    methods: {
        deleteAddress() {
            axios.post(this.deleteAddressAction, {
                id: this.address.id
            }).then(res => {
                this.$toast.clear();
                this.$toast.open(this.$t('site.toast.deleted_successfully'));

                this.$emit('set-addresses', res.data.addresses)
                this.$emit('set-address-data', {
                    invoiceAddress: res.data.invoiceAddress,
                    shippingAddress: res.data.shippingAddress,
                })
            }).catch(() => {
                this.$toast.clear();
                this.$toast.error(this.$t('site.toast.error'));
            });
        },
        setShippingAddress() {
            axios.post(this.updateShippingAddressAction, {
                id: this.address.id
            }).then(res => {
                this.$toast.clear();
                this.$toast.open(this.$t('site.toast.saved_successfully'));

                this.$emit('set-address-data', {
                    invoiceAddress: res.data.invoiceAddress,
                    shippingAddress: res.data.shippingAddress,
                })
            }).catch(() => {
                this.$toast.clear();
                this.$toast.error(this.$t('site.toast.error'));
            });
        },
        setInvoiceAddress() {
            axios.post(this.updateInvoiceAddressAction, {
                id: this.address.id
            })
            .then(res => {
                this.$toast.clear();
                this.$toast.open(this.$t('site.toast.saved_successfully'));

                this.$emit('set-address-data', {
                    invoiceAddress: res.data.invoiceAddress,
                    shippingAddress: res.data.shippingAddress,
                })
            }).catch(() => {
                this.$toast.clear();
                this.$toast.error(this.$t('site.toast.error'));
            });
        }
    }
}
</script>