var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "align-items-center justify-content-start d-flex",
      attrs: { method: "post", action: _vm.action },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.addToCart.apply(null, arguments)
        },
      },
    },
    [
      _c("brku-quantity-input", {
        attrs: { quantity: _vm.quantity },
        on: { "quantity-changed": _vm.onQuantityChanged },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden" },
        domProps: { value: _vm.quantity },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden" },
        domProps: { value: _vm.token },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          ref: "button",
          staticClass: "btn btn-custom btn-primary has--icon-arrow-right",
          attrs: { type: "submit" },
        },
        [
          _c("span", { class: { invisible: _vm.loading } }, [
            _vm._v(_vm._s(_vm.$t("site.ui.product_item.add_to_cart"))),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "btn-loader", class: { invisible: !_vm.loading } },
            [_c("i", { staticClass: "bk-spin fa-solid fa-circle-notch" })]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "circle",
          staticClass: "animated-circle",
          class: { "is--transforming": _vm.animating },
        },
        [_vm._v("\n    " + _vm._s(_vm.quantity) + "\n  ")]
      ),
      _vm._v(" "),
      false
        ? _c("brku-add-to-cart-modal", {
            attrs: { products: _vm.products, "cart-url": _vm.cartUrl },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }