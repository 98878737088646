<template>
  <div class="summary-step">
    <div class="row">
      <div class="col col-12 col-md-6 mb-4 mb-md-0" v-if="hasBranch && carrierIdentifier === 'pick_up'">
        <div class="checkbox-card">
          <div class="checkbox-card__wrapper">
            <a class="edit-btn is--first" href="#" v-scroll-to="'#branch-step'">
              <i class="fa-solid fa-pen-to-square edit-btn-icon"></i>
            </a>
            <div class="checkbox-card__title">{{ $t('cart.ui.branch') }}</div>
            <div class="checkbox-card__body">{{ branchName }}</div>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6"
           v-if="pickUpDate && carrierIdentifier === 'pick_up' || deliveryDate && carrierIdentifier === 'delivery'">
        <div class="checkbox-card">
          <div class="checkbox-card__wrapper">
            <a class="edit-btn"
               href="#"
               v-scroll-to="`#${carrierIdentifier}-step`"
            >
              <i class="fa-solid fa-pen-to-square edit-btn-icon"></i>
            </a>
            <div class="checkbox-card__title">
              <p v-if="carrierIdentifier === 'pick_up'">{{ $t('cart.ui.pick_up') }}</p>
              <p v-else>{{ $t('cart.ui.delivery') }}</p>
            </div>
            <div class="checkbox-card__body">
              <p v-if="carrierIdentifier === 'pick_up'">
                {{ pickUpDate | moment("dddd, MMMM Do YYYY") }} {{ pickUpTime }} {{
                  $t('cart.ui.time')
                }}
              </p>
              <p v-else>
                {{ deliveryDate | moment("dddd, MMMM Do YYYY") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6" v-if="hasCustomer">
        <div class="checkbox-card">
          <div class="checkbox-card__wrapper">
            <div class="checkbox-card__title">{{ $t('cart.ui.contact.title') }}</div>
            <div class="checkbox-card__body">
              <p class="m-0" v-if="firstName && lastName">{{ `${firstName} ${lastName}` }}</p>
              <p class="m-0" v-if="street && number">{{ `${street} ${number}` }}</p>
              <p class="m-0" v-if="postcode && city">{{ `${postcode} ${city}` }}</p>
              <p class="m-0" v-if="country">{{ country }}</p>
              <p class="m-0" v-if="phoneNumber">{{ $t('cart.ui.phone.short') }}: {{ phoneNumber }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6" v-if="paymentProvider">
        <div class="checkbox-card">
          <div class="checkbox-card__wrapper">
            <a class="edit-btn" href="#">
              <i class="fa-solid fa-pen-to-square edit-btn-icon"></i>
            </a>
            <div class="checkbox-card__title">{{ $t('cart.ui.payment.title') }}</div>
            <div class="checkbox-card__body">
              <b-img-lazy v-if="paymentProvider.logoSrc" :src="paymentProvider.logoSrc"></b-img-lazy>
              <div class="mt-3"><strong>{{ paymentProvider.title }}</strong></div>
              <div v-if="paymentProvider.description">{{ paymentProvider.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <span class="process-step__sub-title d-block">{{ $t('site.ui.form.your_message') }}</span>
      <textarea
          class="form-control textarea input-card" rows="3"
          :placeholder="$t('cart.ui.message')"
          name="coreshop[comment]"
          maxlength="78"
      ></textarea>
    </div>
    <div class="custom-control custom-checkbox d-flex mb-3 mt-3">
      <input class="custom-control-input" id="newsletterActive" type="checkbox" name="newsletterActive"
             value="1"/>
      <label class="custom-control-label" for="newsletterActive">{{
          $t('site.form.customer.newsletter_active')
        }}</label>
    </div>
    <div class="custom-control custom-checkbox d-flex mb-3 mt-3">
      <input class="custom-control-input" id="coreshop_acceptTerms" type="checkbox" name="coreshop[acceptTerms]"
             required value="1"/>
      <label class="custom-control-label" for="coreshop_acceptTerms">{{ $t('cart.ui.accept_terms') }}</label>
    </div>
    <div class=" d-flex justify-content-center">
      <b-button type="submit" variant="success" class="btn btn-custom has--icon-arrow-right" :value="checkoutUrl"
                name="coreshop[checkout_finisher]">
        {{ $t('cart.ui.button.checkout') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    paymentProvider: {
      type: Object
    },
    checkoutUrl: {
      type: String,
      default: ''
    }
  },
  data: () => ({}),
  mounted() {

  },
  computed: {
    ...mapGetters([
      'cart'
    ]),
    branchName() {
      return this.cart.branch?.name;
    },
    orderType() {
      return this.cart.branch?.orderType;
    },
    firstName() {
      return this.$store.state.cart.content?.customer?.firstname;
    },
    lastName() {
      return this.$store.state.cart.content?.customer?.lastname;
    },
    street() {
      return this.$store.state.cart.content?.customer?.defaultAddress?.street;
    },
    number() {
      return this.$store.state.cart.content?.customer?.defaultAddress?.number;
    },
    postcode() {
      return this.$store.state.cart.content?.customer?.defaultAddress?.postcode;
    },
    city() {
      return this.$store.state.cart.content?.customer?.defaultAddress?.city;
    },
    country() {
      return this.$store.state.cart.content?.customer?.defaultAddress?.countryName;
    },
    phoneNumber() {
      return this.cart?.shippingAddress?.phoneNumber;
    },
    hasBranch() {
      return this.$store.state.cart.content?.branch !== undefined;
    },
    hasCustomer() {
      return this.$store.state.cart.content?.customer !== undefined;
    },
    pickUpDate() {
      return this.$store.state.cart.content?.pickUpDate;
    },
    pickUpTime() {
      return this.$store.state.cart.content?.pickUpTime;
    },
    deliveryDate() {
      return this.$store.state.cart.content?.deliveryDate;
    },
    carrierIdentifier() {
      return this.$store.state.cart.content?.carrier?.identifier;
    }
  }
}
</script>
