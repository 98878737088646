import Vue from 'vue';

import AccountInput from './components/Input';
import AccountSelect from './components/Select';
import Form from './customer-personal-data/Form';
import BreadBasket from './breadbasket/BreadBasket';
import RecurringOrder from './recurring-orders/RecurringOrder';
import RecurringOrderModal from './recurring-orders/RecurringOrderModal';

Vue.component('brku-customer-personal-data', Form);
Vue.component('brku-account-input', AccountInput);
Vue.component('brku-account-select', AccountSelect);
Vue.component('brku-breadbasket', BreadBasket);
Vue.component('brku-recurring-order-modal', RecurringOrderModal);
Vue.component('brku-recurring-order', RecurringOrder);
