<template>
    <div>
        <brku-product-review-tab-bar :mode="mode" @set-mode="setMode"/>
        <brku-product-review-tab-content :mode="mode" :reviews="reviews" :action="action" @set-mode="setMode" :user="user"/>
    </div>
</template>

<script>
export default {
    name: 'ProductReview',
    props: {
        action: String,
        listAction: String,
        reviewsCount: Number,
        user: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        mode: 'review-list',
        reviews: []
    }),
    created() {
        if (this.reviewsCount === 0){
            this.setMode('review-form');
        }

        this.loadData();
    },
    methods: {
        loadData() {
            axios.get(this.listAction)
                .then(res => res.data)
                .then(res => {
                    this.reviews = res.reviews;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getHeaderHeight() {
            const navbar = document.querySelector('#navbar');

            if (navbar !== null && navbar instanceof HTMLElement) {
                return -navbar.offsetHeight - 20;
            }

            return 0;
        },
        setMode(mode) {
            this.mode = mode;

            if (mode === 'review-list') {
                this.loadData();
            }
        }
    }
}
</script>