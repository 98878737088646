var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-icon-wrapper" },
    [
      _c(
        "span",
        { staticClass: "navbar-icon", on: { click: _vm.toggleSearch } },
        [_c("i", { staticClass: "fa-solid fa-magnifying-glass" })]
      ),
      _vm._v(" "),
      _vm.showSearch
        ? _c("brku-search", {
            attrs: { "search-action": _vm.searchAction },
            on: { close: _vm.toggleSearch },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "navbar-icon",
          on: {
            click: function ($event) {
              _vm.showWishlist = !_vm.showWishlist
            },
          },
        },
        [
          _c("i", { staticClass: "fa-solid fa-clipboard-list" }),
          _vm._v(" "),
          _vm.$store.state.wishlist.counter > 0
            ? _c(
                "span",
                {
                  staticClass:
                    "navbar-icon__badge d-flex align-items-center justify-content-center",
                },
                [_vm._v(_vm._s(_vm.$store.state.wishlist.counter))]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showWishlist
        ? _c("brku-wishlist-modal-overview", {
            attrs: {
              "summary-action": _vm.wishlistSummaryAction,
              "remove-action": _vm.wishlistRemoveAction,
            },
            on: {
              close: function ($event) {
                _vm.showWishlist = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile
        ? _c("div", [
            _vm.isLoggedIn
              ? _c(
                  "a",
                  {
                    staticClass: "navbar-avatar",
                    attrs: { href: _vm.accountAction },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.shortName) + "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isLoggedIn
              ? _c(
                  "span",
                  {
                    staticClass: "navbar-icon",
                    on: {
                      click: function ($event) {
                        return _vm.showLoginModal(
                          !_vm.$store.state.login.showLogin
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa-solid fa-user" })]
                )
              : _vm._e(),
          ])
        : _c("div", [
            _c(
              "a",
              {
                staticClass: "navbar-icon",
                attrs: {
                  href: _vm.isLoggedIn
                    ? _vm.accountAction
                    : _vm.loginCheckAction,
                },
              },
              [
                _vm.isLoggedIn
                  ? _c("span", [_vm._v(_vm._s(_vm.fullName))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("coreshop.ui.login")))]),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }