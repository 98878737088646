<template>
    <div class="related-products-slider position-relative" v-if="totalCount >= 4">
        <div class="container">
            <div class="special-heading mb-5">
                <h4 class="special-heading__title">{{ $t('site.ui.product_detail.related_products') }}</h4>
                <p class="special-heading__sub-title">{{ $t('site.ui.product_detail.freshly_made_with_regional_ingredients') }}</p>
            </div>
            <div class="keen-slider xl:mt-5" ref="slider">
                <slot></slot>
            </div>
        </div>
        <div
            :class="{ arrow: true, 'slider-navigation-arrow arrow-xl is--left': true, 'is--disabled': current === 0 }"
            @click="slider.prev()">
            <i class="fa-solid fa-arrow-left slider-navigation-arrow__icon"></i>
        </div>
        <div
            :class="{ arrow: true, 'slider-navigation-arrow arrow-xl is--right': true, 'is--disabled': current === totalCount - 1 }"
            @click="slider.next()">
            <i class="fa-solid fa-arrow-right slider-navigation-arrow__icon"></i>
        </div>
    </div>
</template>

<script>
import KeenSlider from 'keen-slider';

export default {
    props: {
        totalCount: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        current: 0,
        slider: null
    }),
    mounted() {
        this.slider = new KeenSlider(this.$refs.slider, {
            initial: this.current,
            slidesPerView: 1,
            spacing: 60,
            breakpoints: {
                "(min-width: 992px)": {
                    slidesPerView: 2
                },
                "(min-width: 1200px)": {
                    slidesPerView: 3
                },
            },
            slideChanged: (s) => {
                this.current = s.details().relativeSlide;
            }
        });
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy();
    }
}
</script>