var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "ingredients-slider__images" }, [
      _c(
        "div",
        { ref: "imageSlider", staticClass: "keen-slider" },
        _vm._l(_vm.images, function (image, index) {
          return _c(
            "div",
            {
              key: index,
              class: {
                "keen-slider__slide d-flex justify-content-center": true,
                "is--active": _vm.current === index,
              },
            },
            [
              _c("img", {
                class: {
                  "ingredients-slider__image": true,
                  "is--active": _vm.current === index,
                },
                attrs: { src: image },
              }),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ingredients-slider__content mt-3 mt-sm-5" }, [
      _c(
        "div",
        { ref: "slider", staticClass: "keen-slider" },
        _vm._l(_vm.ingredients, function (ingredient) {
          return _c(
            "div",
            { key: ingredient.id, staticClass: "keen-slider__slide" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ingredients-slider__title d-flex justify-content-center",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(ingredient.name) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "ingredients-slider__text d-flex justify-content-center",
                domProps: { innerHTML: _vm._s(ingredient.description) },
              }),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "slider-navigation-wrapper d-flex justify-content-center mt-3 mt-sm-5",
      },
      [
        _c(
          "div",
          {
            class: {
              arrow: true,
              "slider-navigation is--prev mr-3": true,
              "is--disabled": _vm.current === 0,
            },
            on: {
              click: function ($event) {
                return _vm.slider.prev()
              },
            },
          },
          [_c("i", { staticClass: "fa-solid fa-angle-left" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              arrow: true,
              "slider-navigation is--next": true,
              "is--disabled": _vm.current === _vm.totalCount - 1,
            },
            on: {
              click: function ($event) {
                return _vm.slider.next()
              },
            },
          },
          [_c("i", { staticClass: "fa-solid fa-angle-right" })]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }