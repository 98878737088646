<template>
    <div class="product-filter__item"
         :class="`condition-${condition.type}`"
         v-if="condition.objects.length > 0"
    >
        <div class="product-filter__header" v-b-toggle="`collapse-${condition.fieldName}`" v-if="!hideLabel">
            <p class="product-filter__title">
                {{ $t(condition.label) }} <strong v-if="condition.objects">{{
                    `(${condition.objects.length})`
                }}</strong>
            </p>
            <div :class="{'is--visible': contentVisible, 'product-filter__toggle': true}">
                <i class="fa-solid fa-angle-down toggle-angle-down"></i>
            </div>
        </div>
        <b-collapse class="product-filter__options" :id="`collapse-${condition.fieldName}`" visible
                    v-model="contentVisible">
            <div class="product-filter__options-search" v-if="showSearch">
                <input
                    v-model="search"
                    type="text"
                    name="search"
                    :placeholder="$t('site.ui.placeholder.' + condition.fieldName)"
                />
            </div>
            <slot/>
        </b-collapse>
    </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';

export default {
    components: {
        SlideUpDown
    },
    props: {
        condition: {
            type: Object,
            default: null
        },
        currentFilter: {
            type: Object | Array,
            default: null
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
        showSearch: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        isOpen: false,
        search: '',
        contentVisible: true
    }),
    watch: {
        search: function (newValue) {
            this.$emit('search', newValue);
        }
    }
}
</script>