var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sale-list-item mb-2" }, [
    _c(
      "div",
      { staticClass: "sale-list-item__title" },
      [
        _c("div", { staticClass: "row align-items-center no-gutters" }, [
          _c("div", { staticClass: "col-lg-9 col-7 d-lg-flex d-block" }, [
            _c("div", { staticClass: "col-lg-5 col-12" }, [
              _c("div", { staticClass: "item-order pl-lg-3" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.order.cart.customIdentifier) +
                    " "
                ),
                _c("span", { staticClass: "item-order-number" }, [
                  _vm._v("(" + _vm._s(_vm.order.id) + ")"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-4 col-12" }, [
              _c("div", { staticClass: "item-period item-period-time" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm._f("moment")(_vm.order.startDate, "DD.MM.Y")) +
                    " - " +
                    _vm._s(_vm._f("moment")(_vm.order.endDate, "DD.MM.Y")) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3 col-12" }, [
              _c("div", { staticClass: "item-customer-number item-user" }, [
                _c("span", { staticClass: "d-lg-none" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        this.$t(
                          "site.ui.account.table_header.customer_number"
                        ) + ":"
                      ) +
                      "\n                        "
                  ),
                ]),
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.order.cart.customer.customerNumber) +
                    "\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-2 col-3" }, [
            _c("div", { staticClass: "item-price" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.order.cart.totalNetFormatted) +
                  "\n                "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-1 col-2" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: `recurring_order-${_vm.order.id}`,
                    expression: "`recurring_order-${order.id}`",
                  },
                ],
                class: [
                  "border-0 ml-auto item-btn",
                  {
                    "has--angle-down": !_vm.visible,
                    "has--angle-up": _vm.visible,
                  },
                ],
              },
              [
                _vm.visible
                  ? _c("i", { staticClass: "fa-solid fa-chevron-down" })
                  : _c("i", { staticClass: "fa-solid fa-chevron-up" }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "b-collapse",
          {
            staticClass: "sale-list-body",
            attrs: { id: `recurring_order-${_vm.order.id}` },
            model: {
              value: _vm.visible,
              callback: function ($$v) {
                _vm.visible = $$v
              },
              expression: "visible",
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _vm.order.invoiceAddress
                ? _c("div", { staticClass: "col-md-6 col-12 body-col" }, [
                    _c("div", { staticClass: "body-title" }, [
                      _vm._v("Rechnungsadresse"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-address" }, [
                      _vm.order.invoiceAddress.company
                        ? _c("p", [
                            _vm._v(_vm._s(_vm.order.invoiceAddress.company)),
                          ])
                        : _c("p", [
                            _vm._v(
                              _vm._s(_vm.order.invoiceAddress.firstName) +
                                " " +
                                _vm._s(_vm.order.invoiceAddress.lastName)
                            ),
                          ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.order.invoiceAddress.street) +
                            " " +
                            _vm._s(_vm.order.invoiceAddress.number)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.order.invoiceAddress.postcode) +
                            " " +
                            _vm._s(_vm.order.invoiceAddress.city)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.order.invoiceAddress.countryName)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.order.shippingAddress
                ? _c("div", { staticClass: "col-md-6 col-12 body-col" }, [
                    _c("div", { staticClass: "body-title" }, [
                      _vm._v("Lieferadresse"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-address" }, [
                      _vm.order.shippingAddress.company
                        ? _c("p", [
                            _vm._v(_vm._s(_vm.order.shippingAddress.company)),
                          ])
                        : _c("p", [
                            _vm._v(
                              _vm._s(_vm.order.shippingAddress.firstName) +
                                " " +
                                _vm._s(_vm.order.shippingAddress.lastName)
                            ),
                          ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.order.shippingAddress.street) +
                            " " +
                            _vm._s(_vm.order.shippingAddress.number)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.order.shippingAddress.postcode) +
                            " " +
                            _vm._s(_vm.order.shippingAddress.city)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.order.shippingAddress.countryName)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 mt-5" },
                [
                  _c("div", { staticClass: "body-title" }, [
                    _vm._v("Produkte"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.order.cart.items, function (item) {
                    return _c(
                      "div",
                      { staticClass: "row order-card-item no-gutters" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-8 d-flex align-items-center justify-content-start",
                          },
                          [
                            item.product.image
                              ? _c("img", {
                                  staticClass: "item-image",
                                  attrs: { src: item.product.image },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !item.product.image
                              ? _c("img", {
                                  staticClass: "item-image",
                                  attrs: {
                                    src: "/build/images/product-default-image.png",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-info" }, [
                              _c("div", { staticClass: "item-description" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(item.product.name) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item-number" }, [
                                _vm._v(
                                  "\n                                    Art. Nr: " +
                                    _vm._s(item.product.sku) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-1 d-none d-md-flex" }, [
                          _c("div", { staticClass: "item-price" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.itemNetFormatted) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-2 d-flex justify-content-center",
                          },
                          [
                            _c("div", { staticClass: "item-quantity" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.quantity) +
                                  " Stk.\n                            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-1 d-flex justify-content-end" },
                          [
                            _c("div", { staticClass: "item-total-price" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.totalItemNetFormatted) +
                                  "\n                            "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row no-gutters order-card-result" },
                    [
                      _c("div", { staticClass: "col-md-8 col-3" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-4 col-9" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "total-price d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("p", { staticClass: "total-price__text" }, [
                              _vm._v("Gesamtsumme"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "total-price__sum" }, [
                              _vm._v(_vm._s(_vm.order.cart.totalNetFormatted)),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn-wrapper d-lg-flex d-block align-items-center mt-4",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-link delete-order-btn pl-3 text-decoration-none mr-auto",
                          on: { click: _vm.deleteRecurringOrder },
                        },
                        [
                          _c("i", {
                            staticClass: "fa-solid fa-trash btn-delete__icon",
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                this.$t("site.form.recurring_order.delete")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: `recurring-order-modal-update-${_vm.order.id}`,
                              expression:
                                "`recurring-order-modal-update-${order.id}`",
                            },
                          ],
                          staticClass:
                            "btn btn-custom btn-primary has--icon-arrow-right ml-auto",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                this.$t("site.form.recurring_order.process")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("brku-recurring-order-modal", {
          attrs: {
            carts: _vm.carts,
            locale: _vm.locale,
            "api-endpoint": _vm.modalApiEndpoint,
            id: `update-${_vm.order.id}`,
            "start-date": _vm.order.startDate,
            "end-date": _vm.order.endDate,
            "cart-id": _vm.order.cart.id,
            "order-id": _vm.order.id,
            "order-item": _vm.order,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }