var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "summary-step" }, [
    _c("div", { staticClass: "row" }, [
      _vm.hasBranch && _vm.carrierIdentifier === "pick_up"
        ? _c("div", { staticClass: "col col-12 col-md-6 mb-4 mb-md-0" }, [
            _c("div", { staticClass: "checkbox-card" }, [
              _c("div", { staticClass: "checkbox-card__wrapper" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: "#branch-step",
                        expression: "'#branch-step'",
                      },
                    ],
                    staticClass: "edit-btn is--first",
                    attrs: { href: "#" },
                  },
                  [
                    _c("i", {
                      staticClass: "fa-solid fa-pen-to-square edit-btn-icon",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-card__title" }, [
                  _vm._v(_vm._s(_vm.$t("cart.ui.branch"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-card__body" }, [
                  _vm._v(_vm._s(_vm.branchName)),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.pickUpDate && _vm.carrierIdentifier === "pick_up") ||
      (_vm.deliveryDate && _vm.carrierIdentifier === "delivery")
        ? _c("div", { staticClass: "col col-12 col-md-6" }, [
            _c("div", { staticClass: "checkbox-card" }, [
              _c("div", { staticClass: "checkbox-card__wrapper" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: `#${_vm.carrierIdentifier}-step`,
                        expression: "`#${carrierIdentifier}-step`",
                      },
                    ],
                    staticClass: "edit-btn",
                    attrs: { href: "#" },
                  },
                  [
                    _c("i", {
                      staticClass: "fa-solid fa-pen-to-square edit-btn-icon",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-card__title" }, [
                  _vm.carrierIdentifier === "pick_up"
                    ? _c("p", [_vm._v(_vm._s(_vm.$t("cart.ui.pick_up")))])
                    : _c("p", [_vm._v(_vm._s(_vm.$t("cart.ui.delivery")))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-card__body" }, [
                  _vm.carrierIdentifier === "pick_up"
                    ? _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.pickUpDate,
                                "dddd, MMMM Do YYYY"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.pickUpTime) +
                            " " +
                            _vm._s(_vm.$t("cart.ui.time")) +
                            "\n            "
                        ),
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.deliveryDate,
                                "dddd, MMMM Do YYYY"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCustomer
        ? _c("div", { staticClass: "col col-12 col-md-6" }, [
            _c("div", { staticClass: "checkbox-card" }, [
              _c("div", { staticClass: "checkbox-card__wrapper" }, [
                _c("div", { staticClass: "checkbox-card__title" }, [
                  _vm._v(_vm._s(_vm.$t("cart.ui.contact.title"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-card__body" }, [
                  _vm.firstName && _vm.lastName
                    ? _c("p", { staticClass: "m-0" }, [
                        _vm._v(_vm._s(`${_vm.firstName} ${_vm.lastName}`)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.street && _vm.number
                    ? _c("p", { staticClass: "m-0" }, [
                        _vm._v(_vm._s(`${_vm.street} ${_vm.number}`)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.postcode && _vm.city
                    ? _c("p", { staticClass: "m-0" }, [
                        _vm._v(_vm._s(`${_vm.postcode} ${_vm.city}`)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.country
                    ? _c("p", { staticClass: "m-0" }, [
                        _vm._v(_vm._s(_vm.country)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.phoneNumber
                    ? _c("p", { staticClass: "m-0" }, [
                        _vm._v(
                          _vm._s(_vm.$t("cart.ui.phone.short")) +
                            ": " +
                            _vm._s(_vm.phoneNumber)
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.paymentProvider
        ? _c("div", { staticClass: "col col-12 col-md-6" }, [
            _c("div", { staticClass: "checkbox-card" }, [
              _c("div", { staticClass: "checkbox-card__wrapper" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-card__title" }, [
                  _vm._v(_vm._s(_vm.$t("cart.ui.payment.title"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox-card__body" },
                  [
                    _vm.paymentProvider.logoSrc
                      ? _c("b-img-lazy", {
                          attrs: { src: _vm.paymentProvider.logoSrc },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-3" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.paymentProvider.title))]),
                    ]),
                    _vm._v(" "),
                    _vm.paymentProvider.description
                      ? _c("div", [
                          _vm._v(_vm._s(_vm.paymentProvider.description)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("span", { staticClass: "process-step__sub-title d-block" }, [
        _vm._v(_vm._s(_vm.$t("site.ui.form.your_message"))),
      ]),
      _vm._v(" "),
      _c("textarea", {
        staticClass: "form-control textarea input-card",
        attrs: {
          rows: "3",
          placeholder: _vm.$t("cart.ui.message"),
          name: "coreshop[comment]",
          maxlength: "78",
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "custom-control custom-checkbox d-flex mb-3 mt-3" },
      [
        _c("input", {
          staticClass: "custom-control-input",
          attrs: {
            id: "newsletterActive",
            type: "checkbox",
            name: "newsletterActive",
            value: "1",
          },
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "custom-control-label",
            attrs: { for: "newsletterActive" },
          },
          [_vm._v(_vm._s(_vm.$t("site.form.customer.newsletter_active")))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "custom-control custom-checkbox d-flex mb-3 mt-3" },
      [
        _c("input", {
          staticClass: "custom-control-input",
          attrs: {
            id: "coreshop_acceptTerms",
            type: "checkbox",
            name: "coreshop[acceptTerms]",
            required: "",
            value: "1",
          },
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "custom-control-label",
            attrs: { for: "coreshop_acceptTerms" },
          },
          [_vm._v(_vm._s(_vm.$t("cart.ui.accept_terms")))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn btn-custom has--icon-arrow-right",
            attrs: {
              type: "submit",
              variant: "success",
              value: _vm.checkoutUrl,
              name: "coreshop[checkout_finisher]",
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("cart.ui.button.checkout")) + "\n    "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "edit-btn", attrs: { href: "#" } }, [
      _c("i", { staticClass: "fa-solid fa-pen-to-square edit-btn-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }