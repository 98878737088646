<template>
    <a class="login-wrapper d-flex flex-column justify-content-center align-items-center" :href="url">
        <i class="bk-i bk-i-login-mobile login-icon"></i>
        <p class="login-text">{{ $t('site.ui.mobile_nav.login') }}</p>
    </a>
</template>

<script>
export default {
    props: {
        url: {
            type: String
        }
    },
}
</script>