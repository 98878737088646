<template>
    <div class="input-group sort-by-btn">
        <div class="input-group-prepend sort-by-label">
           <!-- <label class="input-group-text sort-by-text" for="sort">{{ `${$t('site.ui.shared.sort.label')}:` }}</label>-->
           <label class="input-group-text sort-by-text" for="sort">Sortieren nach</label>
        </div>
        <select class="custom-select sort-by-select" v-model="selected" @change="onChange" id="sort">
            <option
              v-for="option in sortOptions"
              :value="option.value"
              :key="option.value"
            >
              {{ option.text }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "SortTypeSelect",
    props: {
        value: String,
        validSortElements: Array
    },
    data() {
        return {
            selected: null
        }
    },
    created() {
        this.selected = this.value;
    },
    computed: {
        sortOptions() {
            let options = [];

            this.validSortElements.forEach(sortElement => {
                options.push({
                    text: this.$t('site.ui.shared.sort_type_' + sortElement),
                    value: sortElement
                });
            });

            return options;
        }
    },
    methods: {
        onChange(e) {
            this.$emit('input', this.selected);
        }
    }
}
</script>