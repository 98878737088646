var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "notice-wrapper ml-md-3 ml-0",
          on: { click: _vm.addToWishlist },
        },
        [
          !_vm.loading
            ? _c("i", {
                staticClass: "fa-solid fa-clipboard-list notice-heart",
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("i", { staticClass: "bk-spin fa-solid fa-circle-notch" })
            : _vm._e(),
          _vm._v("\n        " + _vm._s(_vm.$t("site.ui.notice")) + "\n    "),
        ]
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c("brku-wishlist-modal", {
            attrs: {
              products: _vm.products,
              "action-remove": _vm.actionRemove,
            },
            on: {
              close: function ($event) {
                _vm.showModal = false
              },
              "set-wishlist": _vm.setWishlist,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }