import Vue from 'vue';
// refactored
import OrderTypeStep from './OrderTypeStep';
import LocalDeliveryStep from './LocalDeliveryStep';
import LocalPickUpStep from './LocalPickUpStep';
import PaymentStep from './PaymentStep';
import SummaryStep from './SummaryStep';
import ScheduledLocalDeliveryStep from './ScheduledLocalDeliveryStep';

Vue.component('brku-order-type-step', OrderTypeStep);
Vue.component('brku-local-delivery-step', LocalDeliveryStep);
Vue.component('brku-scheduled-local-delivery-step', ScheduledLocalDeliveryStep);
Vue.component('brku-payment-step', PaymentStep);
Vue.component('brku-local-pick-up-step', LocalPickUpStep);
Vue.component('brku-summary-step', SummaryStep);
